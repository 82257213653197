import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

export default function Calendar({ date, onChangeDate, minDate = new Date() }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        openTo="day"
        value={date}
        views={['day']}
        showToolbar={false}
        minDate={minDate}
        disablePast={true}
        renderInput={() => null}
        onChange={onChangeDate}
        displayStaticWrapperAs="desktop"
        className="bg-transparent custom-calendar"
        onError={(e) =>
          console.error('StaticDatePicker', e?.message)
        }
        maxDate={
          new Date(new Date().setMonth(new Date().getMonth() + 12))
        }
      />
    </LocalizationProvider>
  );
}
