import { useRef, useState } from 'react';
import { Skeleton } from '@mui/lab';
import CollectionsIcon from '@mui/icons-material/Collections';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';

export default function Hero({
  title = null,
  subtitle = null,
  label = null,
  size = 'large',
  cta = null,
  type = 'image',
  className = '',
  showBorder = true,
  sticker = null,
  titleSize = 'medium',
  video = null,
  posterVideo = null,
  galleryButton = false,
  ranking = null,
  image = '/img/placeholder.jpg',
  onShowStories = null,
}) {
  const ref = useRef(null);
  const videoRef = useRef(null);
  const [loader, setLoader] = useState(true);

  const handleGalleryButtonClick = () => {
    try {
      const galleryBtn: HTMLElement = document?.querySelector(
        '.lg-react-element a',
      );
      galleryBtn?.click();
    } catch (error) {
      console.error('handleGalleryButtonClick', error);
    }
  };

  const onLoadedData = () => {
    try {
      setLoader(false);
    } catch (error) {
      console.error('Hero:onLoadedData', error);
    }
  };

  return (
    <section
      ref={ref}
      className={classNames(
        className,
        'relative group',
        'select-none',
        'md:mt-0 md:px-6',
        'h-[20rem] w-full',
        'flex flex-col justify-center',
        {
          'border-b-4 border-primary': showBorder,
          'md:h-[26rem]': size === 'medium',
          'md:h-[30rem]': size === 'large',
        },
      )}
    >
      <div
        className={classNames(
          'text-center',
          'container z-10',
          'mx-auto space-y-6 md:px-0',
          'flex flex-col items-center justify-center',
        )}
      >
        <div
          className={classNames(
            'flex flex-col items-center justify-center',
            'space-y-1 xl:space-y-2.5',
          )}
        >
          {label && (
            <span
              className={classNames(
                'flex',
                'text-sm font-medium text-white',
                'font-condensed uppercase tracking-airy-light',
              )}
            >
              {label}
            </span>
          )}
          <h1
            className={classNames(
              'xl:tracking-widest',
              'max-w-xs md:max-w-xl xl:max-w-none',
              'uppercase text-white',
              'font-title font-extrabold',
              {
                'text-xl md:text-2xl': titleSize === 'small',
                'text-xl md:text-3xl': titleSize === 'medium',
                'text-xl md:text-4xl': titleSize === 'large',
              },
            )}
          >
            {title}
          </h1>

          {subtitle && (
            <p
              className={classNames(
                'flex !leading-6',
                'text-white',
                'md:max-w-lg xl:max-w-full',
                'text-xs md:text-base xl:text-lg',
              )}
            >
              {subtitle}
            </p>
          )}
        </div>

        {cta && (
          <div
            className={classNames(
              'text-center',
              'z-10 w-full max-w-sm',
              'flex items-center justify-center',
            )}
          >
            {cta}
          </div>
        )}
      </div>

      {galleryButton && (
        <button
          onClick={handleGalleryButtonClick}
          className={classNames(
            'space-x-2 z-20 text-sm',
            'flex items-center justify-center',
            'absolute bottom-7 md:bottom-4 left-3',
            'text-gray-700 bg-white px-5 py-1 md:py-1.5',
            'rounded-sm opacity-90',
          )}
        >
          <CollectionsIcon fontSize="small" />
          <span>Gallery</span>
        </button>
      )}

      {onShowStories && typeof onShowStories === 'function' && (
        <button
          onClick={onShowStories}
          className={classNames(
            'space-x-2 z-20 text-sm',
            'flex items-center justify-center',
            'absolute bottom-7 md:bottom-4 left-32',
            'text-gray-700 bg-white px-5 py-1 md:py-1.5',
            'rounded-sm opacity-90',
          )}
        >
          <WebStoriesIcon fontSize="small" />
          <span>Stories</span>
        </button>
      )}

      {ranking && ranking}

      {sticker && (
        <span
          className={classNames(
            'z-[21] flex',
            'text-xs uppercase xl:text-sm',
            'bg-primary text-white',
            'rounded-sm p-2 px-6 md:px-10',
            'absolute -bottom-4 right-4 lg:right-28',
          )}
        >
          {sticker}
        </span>
      )}

      {loader && (
        <Skeleton
          animation="wave"
          height={'100%'}
          width={'100%'}
          variant="rectangular"
          className={classNames(
            'w-full h-[20rem]',
            'absolute bottom-0 left-0',
            'bg-black/50 dark:bg-inherit',
            {
              'md:h-[25rem]': size === 'medium',
              'md:h-[30rem]': size === 'large',
            },
          )}
        />
      )}

      {type === 'image' && image && (
        <LazyLoadImage
          src={image}
          afterLoad={onLoadedData}
          threshold={0}
          effect="opacity"
          alt="image for hero component"
          className={classNames(
            'h-full w-full',
            'z-0 brightness-50',
            'object-cover object-center',
            'absolute bottom-0 left-0 right-0 top-0',
          )}
        />
      )}

      {type === 'video' && video && (
        <video
          loop
          muted
          playsInline={true}
          autoPlay={true}
          ref={videoRef}
          preload="auto"
          onLoadedData={onLoadedData}
          poster={posterVideo}
          className={classNames(
            'h-full w-full',
            'pointer-events-none z-0 object-cover',
            'absolute bottom-0 left-0 right-0 top-0',
            'brightness-75 saturate-100',
            'transition-all duration-500 ease-in-out',
          )}
        >
          <source src={video} type="video/mp4" />
        </video>
      )}
    </section>
  );
}
