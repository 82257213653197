import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from 'next/link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import classNames from 'classnames';

export default function Communications({ list }) {


  return (
    <div className="w-full text-gray-700 dark:text-white">
      <List className="p-0">
        {list.map(
          ({ text, title, button, severity = 'info' }, i) => {
            const severityTrimmed = severity
              ? severity?.trim()
              : 'info';

            return (
              <ListItem
                key={i}
                className="max-w-sm pl-1 md:max-w-none"
              >
                <div className="mb-1 space-y-1" key={i}>
                  <div className="flex">
                    <div className="h-full">
                      <InfoOutlinedIcon
                        fontSize="small"
                        className={classNames({
                          'fill-sky-500':
                            severityTrimmed === 'info',
                          'fill-amber-500':
                            severityTrimmed === 'warning',
                          'fill-red-500':
                            severityTrimmed === 'error',
                          'fill-green-500':
                            severityTrimmed === 'success',
                        })}
                      />
                    </div>

                    <div className="flex flex-col ml-4">
                      {title && (
                        <span
                          className={classNames(
                            'my-0.5',
                            'font-title',
                            'text-sm',
                          
                            'font-semibold uppercase',
                          )}
                        >
                          {title}
                        </span>
                      )}

                      {text && (
                        <p className="w-full max-w-md text-sm leading-relaxed md:text-base">
                          {text}
                        </p>
                      )}

                      {button && (
                        <Link href={button?.hfref} passHref>
                          <a className="w-full pb-4 mt-4">
                            <Button
                              variant="outlined"
                              fullWidth
                              size="small"
                              className="text-white bg-primary"
                            >
                              {button?.label}
                            </Button>
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </ListItem>
            );
          },
        )}
      </List>
    </div>
  );
}
