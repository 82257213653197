import { Collapsible, Masonry } from '@components';
import take from 'lodash/take';
import EmailIcon from '@mui/icons-material/Email';
import MapIcon from '@mui/icons-material/Map';
import PhoneIcon from '@mui/icons-material/Phone';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'next-i18next';

export default function MomentoStores({ stores }) {
  const { t } = useTranslation('common');

  if (stores?.length > 0) {
    return (
      <Collapsible
        className="space-y-4 md:space-y-10"
        anchor="momento"
        hide={stores?.length < 4}
      >
        {stores.map(({ attributes }, i) => {
          const {
            name,
            city,
            slug,
            hero,
            email,
            address,
            phone,
            description,
            gallery,
            map,
            menu,
            hours,
          } = attributes;

          return (
            <div className="flex flex-col">
              <p>{t('generic.labels.tasting_at')}</p>
              <Masonry images={take(gallery?.data, 5)} />
              <a
                className="text-base font-semibold md:text-lg underline-offset-4 hover:underline"
                target="_blank"
                href={`https://momentofood.it/${slug}`}
              >
                {name}
              </a>
              <address className="my-1">{address}</address>

              <p className="flex text-sm md:text-base">
                {description}
              </p>

              <ul className="flex flex-col my-8 space-y-6 font-medium">
                <li className="space-x-6">
                  <a
                    href={map}
                    target="_blank"
                    className="space-x-4"
                  >
                    <MapIcon />
                    <span className="hover:underline underline-offset-4">
                      {t('generic.labels.see_on_map')}
                    </span>
                  </a>
                </li>
                <li className="space-x-6">
                  <a
                    href={`mailto:${email}`}
                    target="_blank"
                    className="space-x-4"
                  >
                    <EmailIcon />
                    <span className="hover:underline underline-offset-4">
                      {email}
                    </span>
                  </a>
                </li>
                <li className="space-x-6">
                  <a
                    href={`tel:${phone}`}
                    target="_blank"
                    className="space-x-4"
                  >
                    <PhoneIcon />
                    <span className="hover:underline underline-offset-4">
                      {phone}
                    </span>
                  </a>
                </li>
                {menu?.cta?.file?.data?.attributes?.url && (
                  <li className="space-x-6">
                    <a
                      href={menu?.cta?.file?.data?.attributes?.url}
                      target="_blank"
                      download="momento-menu"
                      className="space-x-4"
                    >
                      <MenuBookIcon />
                      <span className="hover:underline underline-offset-4">
                        {t('generic.labels.see_menu')}
                      </span>
                    </a>
                  </li>
                )}

                {hours?.list?.length > 0 && (
                  <li className="flex items-start justify-start space-x-4">
                    <AccessTimeIcon />
                    {hours?.list?.map(
                      ({ day, from, to, id }, i) => {
                        return (
                          <div key={id}>
                            <div className="">{day}</div>

                            <div className="flex flex-col mt-2 space-y-1">
                              <span className="text-base italic font-light">
                                {from}
                              </span>
                              {/*  <span>-</span> */}
                              <span className="text-base italic font-light">
                                {to}
                              </span>
                            </div>
                          </div>
                        );
                      },
                    )}
                  </li>
                )}
              </ul>
            </div>
          );
        })}
      </Collapsible>
    );
  }

  return null;
}
