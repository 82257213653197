import { Brick } from '@components';
import LightGallery from 'lightgallery/react';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import truncate from 'lodash/truncate';
import WebStoriesIcon from '@mui/icons-material/WebStories';

export default function Masonry({ images, onShowStories = null }) {
  if (images?.length === 0) return null;

  return (
    images?.length > 0 && (
      <div className="relative">
        {onShowStories && typeof onShowStories === 'function' && (
          <button
            onClick={() => onShowStories(true)}
            className={classNames(
              'space-x-2 z-20 text-sm',
              'flex items-center justify-center',
              'absolute top-4 right-4',
              'px-5 py-1.5 rounded-sm opacity-90',
              'text-gray-700 dark:text-white',
              'dark:bg-zinc-700 bg-slate-100',
              'drop-shadow-md',
              'hidden lg:flex'
            )}
          >
            <WebStoriesIcon fontSize="" />
            <span>Stories</span>
          </button>
        )}

        <LightGallery
          speed={500}
          onBeforeOpen={(e) => console.log('onBeforeOpen', e)}
          onAfterAppendSubHtml={(e) =>
            console.log('onAfterAppendSubHtml', e)
          }
          onSlideItemLoad={(e) => console.log('onSlideItemLoad', e)}
          onBeforeNextSlide={(e) =>
            console.log('onBeforeNextSlide', e)
          }
          elementClassNames="container grid grid-cols-2 gap-4 mx-auto my-8 md:grid-cols-4"
        >
          {images.map(({ attributes }, i) => {
            const { formats, url, alternativeText, name, caption } =
              attributes;
            const { thumbnail, small, medium, large } = formats;

            return (
              <Tooltip
                key={attributes?.url}
                title={
                  caption !== name
                    ? truncate(caption, {
                        length: 50,
                        separator: /,.:? +/,
                      })
                    : null
                }
              >
                <a
                  data-src={attributes?.url}
                  href={attributes?.url}
                  key={attributes?.url}
                  alt={alternativeText}
                  //subHtml={alternativeText}
                  className={classNames(
                    'relative overflow-clip rounded',
                    {
                      'h-48': i != images?.length - 1,
                      'col-span-2 row-span-2 h-96 md:col-start-3 md:row-start-1 md:h-full':
                        i == images?.length - 1,
                    },
                  )}
                >
                  <Brick
                    src={small?.url}
                    alt={caption !== name ? caption : null}
                    height={
                      i == images?.length - 1
                        ? 'h-96 md:h-full'
                        : 'h-48'
                    }
                    className={classNames(
                      'absolute bottom-0 left-0 right-0 top-0',
                      'aspect-square h-full w-full rounded object-cover',
                    )}
                  />
                </a>
              </Tooltip>
            );
          })}
        </LightGallery>
      </div>
    )
  );
}
