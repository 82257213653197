import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  timer: null,
  end: null,
  products: [],
  reconciliation: false,
  showCountDown: true,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartReducer: (state, { payload }) => {
      state.products = [...(state?.products || []), ...payload];
    },
    removeCartItemReducer: (state, { payload }) => {
      state.products = [...state.products.filter((p) => p.id !== payload)];
    },
    setCartTimerReducer(state, { payload }) {
      state.timer = payload;
    },
    setCartDateEndReducer(state, { payload }) {
      state.end = payload;
    },
    deleteCartReducer(state) {
      state.products = initialState.products;
      state.timer = initialState.timer;
      state.end = initialState.end;
    },
    removeCartItemsReducer(state, { payload }) {
      state.products = [...state.products.filter((p) => ![...(payload?.ids || [])].includes(p.id))];
    },
    setShowCountDownReducer(state, { payload }) {
      state.showCountDown = payload;
    },
    setReconciliationReducer(state, { payload }) {
      state.reconciliation = payload;
    },
  },
});

export const {
  setCartReducer,
  setCartTimerReducer,
  deleteCartReducer,
  setCartDateEndReducer,
  setShowCountDownReducer,
  removeCartItemReducer,
  removeCartItemsReducer,
  setReconciliationReducer,
} = cartSlice.actions;

export const cartSelector = createSelector(
  ({ cart }) => cart,
  (cart) => cart,
);

export default cartSlice.reducer;
