/* eslint-disable import/no-anonymous-default-export */
const list = {
  audio: ['audioguida', 'audioguide'],
  info: ['punto informazioni'],
  disable: [
    'accesso diversamente abili',
    'access for the disabled',
    'visitatori con disabilità',
    'visitors with disabilities',
  ],
  machine: ['distributore automatico', 'vending machine'],
  caffe: ['caffetteria', 'coffee shop'],
  visit: ['visite guidate', 'guided visits'],
  bookshop: ['bookshop'],
  restaurant: ['ristorante', 'restaurant'],
  animals: [
    'animali ammessi',
    'pets allowed',
    //'animali ammessi con trasportino',
    //'pets allowed with carrier',
  ],
  family: [
    'adatto per famiglie',
    'family friendly',
    'famiglie',
    'families',
  ],
  school: [
    'attività didattiche',
    'educational',
    'scolaresche',
    'school groups',
  ],
  official: ['biglietteria ufficiale', 'official ticket office'],
  groups: ['gruppi', 'groups'],
  single: ['singoli visitatori', 'individual visitors'],
  sub: ['visita subacquea', 'visita in immersione'],
  petTherapy: [
    'animali per pet therapy certificata',
    'pet therapy',
  ],
  dogCarrier: ['animali ammessi con trasportino'],
  dogGuide: ['cani guida ammessi'],
  dogLeash: ['animali ammessi con guinzaglio e museruola'],
};

export default list;
