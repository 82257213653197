import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { parseFormData } from "@utils";

const baseUrl = process.env.NEXT_PUBLIC_ADITUS + "/users/travel-agency";

export const AgencyService = createApi({
  reducerPath: "Agency",
  tagTypes: ["Agency"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;

      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
      return headers;
    },
  }),
  onQueryStarted: () => {},
  endpoints: (builder) => ({
    // STORE NEW AGENCY
    registerAgency: builder.mutation({
      invalidatesTags: ["Agency"],
      query: (data) => {
        if (data) {
          return {
            url: baseUrl,
            method: "POST",
            body: parseFormData(data),
          };
        }
      },
      transformResponse: (res) => res,
    }),
  }),
});

export const { useRegisterAgencyMutation } = AgencyService;

export default AgencyService;
