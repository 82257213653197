import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classNames from 'classnames';

export default function Basic({ content }) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={classNames(
        'strapi-editorial',
        'text-gray-700 dark:text-white',
        'text-base md:text-lg 2xl:text-xl',
        'py-4',
      )}
    >
      {content}
    </ReactMarkdown>
  );
}
