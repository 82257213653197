import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.NEXT_PUBLIC_ADITUS + '/services/';

export const ServicesService = createApi({
  reducerPath: 'Services',
  tagTypes: ['Services'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;
      if (token) {
        headers.set('Accept', 'application/json');
        headers.set('Authorization', `Bearer ${token}`);
        return headers;
      }

      return headers;
    },
  }),
  onQueryStarted: () => {
    console.log('onQueryStarted SERVICES');
  },
  endpoints: (builder) => ({
    getScheduleServiceBySlug: builder.query({
      query: (slug) => `${slug}/schedule`,
      transformResponse: (res) => {
        return res?.data || [];
      },
    }),
    getSlotsServiceBySlugAndDate: builder.query({
      query: ({ slug, date }) => `${slug}/slots/${date}`,
      transformResponse: (res) => {
        return res?.data || [];
      },
    }),
    getServiceTickets: builder.query({
      // keepUnusedDataFor: 0,
      query: ({ slug, locale = 'it' }) => {
        return `${slug}/tickets?lang=${locale}`;
      },
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
    getServiceTicketsByDateAndHour: builder.query({
      query: ({ slug, date, hour, locale = 'it' }) =>
        `${slug}/tickets?hour_service=${hour}&date_service=${date}&lang=${locale}`,
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),

    requestBooking: builder.mutation({
      query: ({ slug, data }) => {
        if (slug && data) {
          return {
            url: `${slug}/request-info/email`,
            method: 'POST',
            body: data,
          };
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetScheduleServiceBySlugQuery,
  useGetSlotsServiceBySlugAndDateQuery,
  useGetServiceTicketsQuery,
  useLazyGetServiceTicketsByDateAndHourQuery,
  useRequestBookingMutation,
} = ServicesService;

export default ServicesService;
