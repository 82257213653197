import Divider from '@mui/material/Divider';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import remarkGfm from 'remark-gfm';

export default function Header({
  label,
  title,
  children,
  description,
  small = false,
  onClick = undefined,
}) {
  return (
    <div
      className={classNames('mb-8 w-full', {
        'space-y-4': children !== null,
      })}
    >
      <Divider
        textAlign="center"
        className={classNames(
          'mx-auto flex max-w-3xl items-center justify-center',
        )}
      >
        <div className="flex flex-col space-y-1">
          {label && (
            <span className="text-sm font-medium tracking-widest text-gray-600 uppercase font-condensed dark:text-primary">
              {label}
            </span>
          )}

          {title && (
            <h2
              onClick={onClick || null}
              className={classNames(
                'max-w-sm xl:max-w-3xl',
                'text-lg md:text-xl xl:text-2xl',
                'text-primary dark:text-white',
                'text-center font-title uppercase tracking-wider',
                {
                  'md:text-2x font-extrabold': !small,
                  'cursor-pointer underline-offset-4 hover:underline':
                    onClick !== undefined,
                },
              )}
            >
              {title}
            </h2>
          )}
        </div>
      </Divider>

      {description && (
        <div className="flex justify-center w-full text-center">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className={classNames(
              'max-w-xl 2xl:max-w-4xl',
              'leading-snug py-4',
              'text-center text-lg 3xl:text-xl',
              'text-gray-700 dark:text-white',
              'strapi-header-editorial',
            )}
          >
            {description}
          </ReactMarkdown>
        </div>
      )}

      {children}
    </div>
  );
}
