import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.NEXT_PUBLIC_ADITUS + "/stripe";

export const PaymentService = createApi({
  reducerPath: "Payment",
  tagTypes: ["Payment", "Cart"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    validatePayment: builder.mutation({
      keepUnusedDataFor: 0,
      query: ({ amount, email, payment_method_id, payment_intent_id }) => {
        if (amount && email && payment_method_id) {
          return {
            url: baseUrl + `/validate-payment`,
            method: "POST",
            body: { amount, email, payment_method_id },
          };
        }

        if (payment_intent_id) {
          return {
            url: baseUrl + `/validate-payment`,
            method: "POST",
            body: { payment_intent_id },
          };
        }
      },
      providesTags: ["Cart"],
      transformResponse: (res, meta, arg) => {
        return res;
      },
    }),
  }),
});

export const { useValidatePaymentMutation } = PaymentService;

export default PaymentService;
