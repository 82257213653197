/* eslint-disable react/no-unescaped-entities */

import { CustomTextInput, HandleFormResponse } from "@components";
import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import classNames from "classnames";
import every from "lodash/every";
import { useResetPasswordMutation } from "@services";
import { validPassword } from "@utils";

export default function ResetPasswordForm({ className, token, onSuccess, onError }) {
  const [loader, setLoader] = useState(false);
  const [formValues, setFormValues] = useState({
    password: "",
    password_confirmation: "",
  });

  const [allowSubmit, setAllowSubmit] = useState(false);

  const [resetPasswordMutation, { data, isSuccess, isError, isLoading }] = useResetPasswordMutation();

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      onSuccess && onSuccess();
      setLoader(true);
      await resetPasswordMutation({
        ...formValues,
        token,
      });
    } catch (error) {
      console.error(error);
      setLoader(false);
      onError && onError();
    }
  };

  const onClickLogin = () => document.querySelector("#btn-login")?.click();

  useEffect(() => {
    setAllowSubmit(every(formValues, "valid") && token !== null);
  }, [formValues]);

  return (
    <>
      <HandleFormResponse data={data} isSuccess={isSuccess} isError={isError} successMessage="Ora è possibile effettuare l'accesso." />

      {/* SHOW FORM */}
      {token && !isSuccess && !data && (
        <form className={["space-y-6", className].join(" ")} onSubmit={onSubmit}>
          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            className="my-1"
            type="password"
            name="password"
            label="New Password"
            variant="outlined"
            regex={validPassword}
            autoComplete="new-password"
            helperText="The password must contain at least 8 characters, a capital letter, a number and a special character"
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                password: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            className="my-1"
            type="password"
            name="password"
            label="Confirm Password"
            autoComplete="new-password"
            variant="outlined"
            regex={validPassword}
            helperText="Password confirmation and Password must match"
            customCondition={(x, y) => x === y}
            toCompare={formValues?.password}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                password_confirmation: value,
              })
            }
          />

          {loader ? (
            <LoadingButton loading className="h-10 w-full rounded-none bg-primary text-white transition delay-150 ease-in-out" />
          ) : (
            <Button
              type="submit"
              color="primary"
              onClick={onSubmit}
              disabled={!allowSubmit}
              className={classNames("h-10 w-full rounded-none bg-primary text-white transition delay-150 ease-in-out", {
                "cursor-not-allowed opacity-50": !allowSubmit,
              })}
            >
              Reset
            </Button>
          )}
        </form>
      )}
    </>
  );
}
