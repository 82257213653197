import { getCleanedPaths } from '@utils';
import Abstract from './Sections/Abstract/Abstract';
import CompactCardContainer from './Sections/Container/CompactCardContainer';
import { useStrapiGetImgFormats } from '@hooks';
import CityLink from './Sections/Links/City';
import ProductLink from './Sections/Links/Product';
import Calendar from './Sections/Calendar/Calendar';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default function TemporaryCompactCard({ product, loading }) {
  const { slug, city, address, hero, abstract, type } = product;


  console.log(new Date(product?.end) >= new Date() ? dayjs(product?.end).fromNow(): '');

  const { small } = useStrapiGetImgFormats({
    img: hero?.media?.data?.attributes,
  });

  //Utils
  const { cityPath, prodPath } = getCleanedPaths({
    city: city?.data?.attributes?.slug,
    slug,
    typeSegment: '/mostre-eventi/',
  });

  return (
    <CompactCardContainer
      img={small?.url}
      loading={loading}
      prodPath={prodPath}
      type={type?.data?.attributes?.name}
    >
      <CityLink
        href={cityPath}
        name={product?.city?.data?.attributes?.name}
      />

      <div className="space-y-2">
        <ProductLink href={prodPath} name={hero?.title} />
        <span className="hidden text-sm md:flex">{address}</span>
      </div>

      <Abstract text={abstract} />

      {new Date(product?.end) >= new Date()
        ? dayjs(product?.end).fromNow()
        : ''}

      <div className="absolute text-sm bottom-5">
        <Calendar {...product} />
      </div>
    </CompactCardContainer>
  );
}
