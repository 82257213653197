import AssistanceDialog from 'components/Dialogs/Assistance/AssistanceDialog';
import IconButton from '@mui/material/IconButton';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

export default function AssistanceIcon() {
  const [showDialog, setShowDialog] = useState(false);
  const onOpenAssistanceDialog = () => setShowDialog(true);

  // i18s
  const { t } = useTranslation('common');

  return (
    <>
      <Tooltip title={t('navbar.assistance')}>
        <IconButton
          color="inherit"
          onClick={onOpenAssistanceDialog}
        >
          <SupportAgentIcon className="fill-gray-700 dark:fill-white" />
        </IconButton>
      </Tooltip>

      <AssistanceDialog
        open={showDialog}
        setShowDialog={setShowDialog}
      />
    </>
  );
}
