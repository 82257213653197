import classNames from 'classnames';
import Link from 'next/link';

export default function CityLink({ href, name }) {
  if (href?.length > 0 && name?.length > 0) {
    return (
      <Link passHref href={href}>
        <a
          className={classNames(
            'text-sm font-medium tracking-widest',
            'underline-offset-4 hover:underline',
            'text-secondary dark:text-primary',
            'z-50 cursor-pointer',
          )}
        >
          {name}
        </a>
      </Link>
    );
  }

  return null;
}
