import classNames from 'classnames';

import Stories from 'react-insta-stories';
import Backdrop from '@mui/material/Backdrop';
import { toggleBodyOverflow, toggleFullScreen } from '@utils';
import { useEffect, useState } from 'react';
import { useMediaQueries } from '@hooks';
import PauseIcon from '@mui/icons-material/Pause';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useIsFullScreen } from '@hooks';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomStories({
  stories = [],
  config,
  show,
  onHide,
  onShow,
  name,
}) {
  const { isMobile } = useMediaQueries();
  const [isPaused, setIsPaused] = useState(false);
  const isFullScreen = useIsFullScreen();

  useEffect(() => {
    toggleBodyOverflow();
    //toggleFullScreen();
    return () => {
      toggleBodyOverflow();
      //toggleFullScreen();
    };
  }, []);

  const handleStoryProgress = () => {
    if (isPaused) setIsPaused(false);
  };

  const handleTogglePause = () => {
    setIsPaused(!isPaused);
  };

  if (!stories?.length === 0) return null;

  return (
    <div
      className={classNames(
        'fixed top-0 bottom-0 left-0 z-[997]',
        'w-screen h-screen overflow-hidden',
        'flex justify-center items-center',
        'fill-white text-white',
      )}
    >
      {/* CLOSE */}
      <IconButton
        color="inherit"
        onClick={onHide}
        className={classNames(
          'z-[999]',
          'lg:flex hidden',
          'fixed right-12 top-10',
        )}
      >
        <CloseIcon />
      </IconButton>

      <Stories
        {...config}
        loop={true}
        isPaused={isPaused}
        width={isMobile ? '100vw' : 360}
        height={isMobile ? '100vh' : 640}
        stories={stories.map((s) => {
          return {
            url: s?.media?.data?.[0]?.attributes?.url,
            duration: s?.duration * 1000,
            type: s?.type,
            header: {
              heading: s?.heading,
              subheading: s?.subheading,
              profileImage: '/profile.jpg',
            },
          };
        })}
        defaultInterval={5000}
        keyboardNavigation={true}
        onNext={handleStoryProgress}
        onPrevious={handleStoryProgress}
        storyStyles={{
          //zIndex: 999,
          objectFit: 'cover',
          width: isMobile ? '100vw' : 'auto',
          height: isMobile ? '100vh' : 'auto',
          maxHeight: '100%',
          margin: 'auto',
          minWidth: '135%',
          objectPosition: 'center center',
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)',
          filter: 'saturate(0.8)',
        }}
        {...{
          progressStyles: {
            backgroundColor: 'orange',
          },
        }}
        storyContainerStyles={{
          zIndex: 999,
          borderRadius: isMobile ? 0 : 10,
          width: isMobile ? '100vw' : 'auto',
          height: isMobile ? '100vh' : 'auto',
        }}
      />

      <div
        className={classNames(
          'fixed bottom-0 left-0 w-full',
          'z-[999] flex backdrop-blur-md',
          'h-12 text-center bg-black/50',
          'flex justify-center items-center',
          'lg:hidden'
        )}
      >
        <button className='w-full h-full text-base' onClick={onHide}>
          Close
        </button>
      </div>

      <div
        className={classNames(
          'lg:flex hidden',
          'fixed z-[999]',
          'right-12 bottom-10',
          'space-x-2',
          'fill-white text-white',
        )}
      >
        <IconButton color="inherit" onClick={handleTogglePause}>
          {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
        </IconButton>

        <IconButton onClick={toggleFullScreen} color="inherit">
          {isFullScreen ? (
            <CloseFullscreenIcon className="fill-white" />
          ) : (
            <FullscreenIcon className="fill-white" />
          )}
        </IconButton>
      </div>

      <Backdrop
        open={true}
        onClick={onHide}
        className={classNames(
          'fixed z-[998]',
          'w-screen h-screen',
          'backdrop-blur-3xl',
        )}
      />
    </div>
  );
}
