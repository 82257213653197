import TimelineItem, {
  timelineItemClasses,
} from '@mui/lab/TimelineItem';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import InstagramIcon from '@mui/icons-material/Instagram';
import classNames from 'classnames';

export default function MustSee({ list }) {
  return (
    <Timeline
      className="p-0 m-0"
      sx={{
        minHeight: 50,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {list?.map(({ name, hashtag }, i) => (
        <TimelineItem className="h-16 p-0 m-0 w-fit" key={i}>
          <TimelineSeparator>
            <TimelineDot
              variant="outlined"
              color="grey"
              className="my-0 bg-transparent border-none"
            >
              <InstagramIcon
                fontSize="small"
                className="opacity-50 fill-gray-600 dark:fill-white"
              />
            </TimelineDot>

            {i < list.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent className="flex flex-col w-auto py-0">
            <span className="text-sm font-semibold uppercase font-title">
              {name}
            </span>

            {hashtag && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.instagram.com/explore/tags/${hashtag}/`}
                className={classNames(
                  'w-auto',
                  'italic font-light underline underline-offset-2',
                  'hover:text-primary hover:underline lg:no-underline',
                  'duration-200 ease-in-out transform-all ',
                )}
              >
                #{hashtag}
              </a>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
