import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classNames from 'classnames';

export default function Ranking({ ranking }) {
  return (
    <ul className="relative flex flex-col">
      {ranking?.map(({ title, content, img }, i) => {
        return (
          <li
            key={i}
            className="flex flex-col mb-20 space-y-8 text-gray-700 last:mb-2 dark:text-white"
          >
            <div className="relative z-0 flex items-center w-full">
              <div className="flex items-center">
                <div className="">
                  <span className="text-lg font-medium">
                    {i + 1}
                  </span>
                  <span className="mr-3 text-base dark:text-primary">
                    .
                  </span>
                </div>
                <h5 className="w-full text-lg font-extrabold text-gray-700 uppercase font-title dark:text-white md:text-2xl">
                  {title}
                </h5>
              </div>
            </div>

            {img?.data?.attributes?.url && (
              <LazyLoadImage
                alt={
                  img?.data?.attributes?.caption ||
                  img?.data?.attributes?.alternativeText
                }
                effect="opacity"
                className="relative object-cover object-center w-full rounded-md saturate-75 h-60 md:aspect-video"
                src={img?.data?.attributes?.url}
              />
            )}
            <div className="flex flex-col">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className={classNames(
                  'strapi-editorial',
                  'px-2',
                  'text-gray-700 dark:text-white',
                  'text-base md:text-lg 2xl:text-xl',
                )}
              >
                {content}
              </ReactMarkdown>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
