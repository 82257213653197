import Chip from '@mui/material/Chip';
import ScheduleIcon from '@mui/icons-material/Schedule';
import calendar from 'dayjs/plugin/calendar';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';


dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(calendar);
dayjs.extend(duration);

export default function SuggestAccess({
  onOpenAddToCartDrawer,
  setForcingDate,
}) {
  const today = dayjs();
  const limit = dayjs().hour(19).minute(0).second(0);
  const { t } = useTranslation('common');

  const dates = [
    {
      label: t('generic.labels.today'),
      date: new Date(),
      threshold: today.isSameOrBefore(limit),
    },
    {
      label: dayjs(today).add(1, 'day').format('dddd'),
      date: dayjs(today).add(1, 'day'),
      threshold: true,
    },
    {
      label: dayjs(today).add(2, 'day').format('dddd'),
      date: dayjs(today).add(2, 'day'),
      threshold: true,
    },
    {
      label: dayjs(today).add(3, 'day').format('dddd'),
      date: dayjs(today).add(3, 'day'),
      threshold: true,
    },
  ];

  return (
    <div
      className={classNames(
        'text-gray-700 dark:text-white',
        'w-full md:my-8',
        'my-16 space-y-6',
      )}
    >
      <h2 className="flex font-semibold uppercase dark:text-primary">
        {t('generic.labels.quick_availability')}
      </h2>

      <div
        className={classNames(
          'flex flex-wrap',
          'space-y-4 md:space-y-0',
          'md:space-x-4',
        )}
      >
        {dates?.map(({ label, date, threshold }, i) => {
          return (
            threshold && (
              <Chip
                key={i}
                label={
                  <span className="flex justify-center">
                    {label}
                  </span>
                }
                className={classNames(
                  'capitalize',
                  'w-full m-0 md:w-1/5',
                  'transition-all ease-in-out duration-300',
                  'hover:bg-slate-100 dark:bg-zinc-800',
                )}
                variant="outlined"
                icon={
                  <ScheduleIcon
                    fontSize="small"
                    className="fill-sky-500"
                  />
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setForcingDate(date);
                  onOpenAddToCartDrawer();
                }}
              />
            )
          );
        })}
      </div>
    </div>
  );
}
