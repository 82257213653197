export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const PixelPageView = () => {
  window?.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const PixelEvent = (name, options = {}) => {
  window?.fbq("track", name, options);
};
