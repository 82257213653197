import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useGetUserDataQuery } from '@services';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

export default function UserPersonalData({ id }) {
  const { isMobile } = useMediaQueries();
  const { t } = useTranslation('common');

  const {
    data: user,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetUserDataQuery(id, {
    skip: id == null,
  });

  return (
    <>
      {isLoading || isFetching ? (
        <div className="flex items-center justify-center w-full py-24">
          <CircularProgress thickness={4} size={20} />
        </div>
      ) : (
        isSuccess && (
          <List dense={isMobile}>
            <ListItem>
              <ListItemText
                secondary={t('forms.name')}
                primary={user?.first_name}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={t('forms.surname')}
                primary={user?.last_name}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary="Email"
                primary={user?.email}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={t('forms.type')}
                primary={t('site.users_type.private')}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary="Active"
                primary={
                  Boolean(user?.active)
                    ? t('forms.yes')
                    : t('forms.no')
                }
              />
            </ListItem>
            {/* <ListItem>
              <ListItemText
                secondary="Iscritto alla Newsletter"
                primary={Boolean(user?.active) ? 'Si' : 'No'}
              />
            </ListItem> */}
            {/* <ListItem>
              <ListItemText
                secondary="Profilato"
                primary={Boolean(user?.active) ? 'Si' : 'No'}
              />
            </ListItem> */}
          </List>
        )
      )}
    </>
  );
}
