import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import { scroller } from 'react-scroll';
import { transformSchedulesByMonth } from '@utils';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MONTHS = [
  { id: 1, labels: { it: 'Gennaio', en: 'January' } },
  { id: 2, labels: { it: 'Febbraio', en: 'February' } },
  { id: 3, labels: { it: 'Marzo', en: 'March' } },
  { id: 4, labels: { it: 'Aprile', en: 'April' } },
  { id: 5, labels: { it: 'Maggio', en: 'May' } },
  { id: 6, labels: { it: 'Giugno', en: 'June' } },
  { id: 7, labels: { it: 'Luglio', en: 'July' } },
  { id: 8, labels: { it: 'Agosto', en: 'August' } },
  { id: 9, labels: { it: 'Settembre', en: 'September' } },
  { id: 10, labels: { it: 'Ottobre', en: 'October' } },
  { id: 11, labels: { it: 'Novembre', en: 'November' } },
  { id: 12, labels: { it: 'Dicembre', en: 'December' } },
];

const DAYS = [
  { key: 'mon_', labels: { it: 'Lunedì', en: 'Monday' } },
  {
    key: 'tue_',
    labels: { it: 'Martedì', en: 'Tuesday' },
  },
  { key: 'wed_', labels: { it: 'Mercoledì', en: 'Wednesday' } },
  { key: 'thu_', labels: { it: 'Giovedì', en: 'Thursday' } },
  { key: 'fri_', labels: { it: 'Venerdì', en: 'Friday' } },
  { key: 'sat_', labels: { it: 'Sabato', en: 'Saturday' } },
  {
    key: 'sun_',
    labels: { it: 'Domenica', en: 'Sunday' },
  },

  { key: 'hol_', labels: { it: 'Festivi', en: 'Holidays' } },
];

function MonthSelector({
  scheduledMonth,
  setScheduleMonth,
  locale,
}) {
  const { t } = useTranslation('common');

  return (
    <FormControl fullWidth className="flex max-w-lg">
      <InputLabel id="month-selector-label">
        {t('generic.labels.month')}
      </InputLabel>
      <Select
        labelId="month-selector-label"
        id="month-selector"
        value={scheduledMonth}
        label={t('generic.labels.month')}
        onChange={(event) => setScheduleMonth(event.target.value)}
      >
        {MONTHS.map((month) => (
          <MenuItem key={month.id} value={month.id}>
            {month.labels[locale]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function ScheduleDisplay({
  time,
  locale,
  onOpenAddToCartDrawer,
  showSchedulesWarning,
  warningMessage,
}) {
  const { t } = useTranslation('common');

  const currentDay = new Date()?.toLocaleDateString('en-US', {
    weekday: 'long',
  });

  const keyDate = currentDay?.toLowerCase()?.slice(0, 3) + '_';

  return (
    <div
      className={classNames(
        'flex flex-col',
        'w-full select-none',
        'text-center md:text-start',
      )}
    >
      <div className="flex w-full mb-4 md:mb-8">
        <span
          className={classNames(
            'flex space-x-1',
            'text-sm md:text-base',
            'w-1/3 md:w-1/6',
            'font-semibold',
          )}
        >
          <span className="capitalize">
            {t('generic.labels.from')}
          </span>
          <span>{time?.from}</span>
          <span>{t('generic.labels.to')}</span>
          <span>{time?.to}</span>
        </span>
        <span
          className={classNames(
            'flex-row w-1/3 md:w-1/4',
            'space-x-1',
            'text-sm md:text-base',
            'font-semibold',
          )}
        >
          <span className="inline-flex">
            {t('generic.labels.ticket_office')}
          </span>
        </span>
        {time?.access?.length > 0 && (
          <span
            className={classNames(
              'space-x-1',
              'w-1/3 md:w-1/4',
              'md:text-base text-sm',
              'font-semibold',
            )}
          >
            <span className="inline-flex">
              {t('generic.labels.public')}
            </span>
          </span>
        )}
      </div>

      <div className="relative flex w-full">
        <ul
          className={classNames(
            'list-none',
            'w-1/3 md:w-1/6',
            'pl-2 space-y-2',
            'dark:text-white text-gray-700',
          )}
        >
          {DAYS.map(({ key, labels }) => (
            <li key={key} className="min-h-[1.5rem]">
              <div className="flex">
                <Tooltip
                  arrow
                  title={
                    warningMessage && showSchedulesWarning ? (
                      <span
                        className={classNames(
                          'flex items-center justify-center',
                          'space-x-1',
                        )}
                      >
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          className={classNames(
                            'text-xs p-4 font-light leading-5',
                          )}
                        >
                          {warningMessage}
                        </ReactMarkdown>
                      </span>
                    ) : (
                      ''
                    )
                  }
                >
                  <button
                    onClick={(e) => onOpenAddToCartDrawer(e)}
                    className={classNames('text-sm md:text-base', {
                      'font-semibold': keyDate !== key,
                      'text-primary-500 italic font-normal':
                        keyDate === key,
                      /* 'text-red-800 dark:text-red-400':
                        showWarning && showSchedulesWarning, */
                    })}
                  >
                    {labels[locale]}
                  </button>

                  {/*
                  {showSchedulesWarning && (
                    <small className="ml-1 text-red-800 dark:text-red-400">
                      **
                    </small>
                  )} */}
                </Tooltip>
              </div>
            </li>
          ))}
        </ul>
        <div className="relative w-1/3 md:w-1/4">
          <ul className="space-y-2 text-gray-700 list-none dark:text-white">
            {time?.cashier?.map((h, index) => (
              <li key={index} className="min-h-[1.5rem]">
                <p
                  className={classNames(
                    'text-sm uppercase md:text-base',
                    {
                      'text-red-800 dark:text-red-600':
                        !h?.start && !h?.end,
                    },
                  )}
                >
                  {h?.start && h?.end
                    ? h.start + '-' + h.end
                    : t('generic.labels.close')}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {time?.access?.length > 0 && (
          <div className="relative w-1/3 md:w-1/4">
            <ul className="space-y-2 text-gray-700 list-none dark:text-white">
              {time?.access?.map((h, index) => (
                <li key={index} className="min-h-[1.5rem]">
                  <p
                    className={classNames(
                      'text-sm uppercase md:text-base',
                      {
                        'text-red-800 dark:text-red-600':
                          !h?.start && !h?.end,
                      },
                    )}
                  >
                    {h?.start && h?.end
                      ? h.start + '-' + h.end
                      : t('generic.labels.close')}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default function Schedules({
  schedules,
  hasCommunications,
  onOpenAddToCartDrawer,
  showSchedulesWarning = false,
  warningMessage = null,
}) {
  const [scheduledMonth, setScheduleMonth] = useState(
    new Date().getMonth() + 1,
  );
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return (
    <>
      <div className="relative flex flex-col space-y-2">
        <MonthSelector
          scheduledMonth={scheduledMonth}
          setScheduleMonth={setScheduleMonth}
          locale={locale}
        />
      </div>
      <div className="my-10 space-y-10">
        {transformSchedulesByMonth({
          schedules,
          index: scheduledMonth,
        })?.map((time, index) => (
          <ScheduleDisplay
            key={index}
            time={time}
            locale={locale}
            showSchedulesWarning={showSchedulesWarning}
            warningMessage={warningMessage}
            onOpenAddToCartDrawer={onOpenAddToCartDrawer}
          />
        ))}
        <div
          className={classNames('flex flex-col', 'justify-center')}
        >
          {hasCommunications && (
            <>
              <div
                className={classNames(
                  'space-y-5',
                  'flex flex-col',
                  'text-sm md:text-base',
                )}
              >
                <p>{t('product.labels.alert_notices')}</p>

                {showSchedulesWarning && warningMessage && (
                  <Alert
                    iconMapping={{
                      error: <></>,
                    }}
                    severity="error"
                    className="dark:text-[#5f2120] dark:bg-[#fac3c3] p-4"
                  >
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      className={classNames('text-sm md:text-base')}
                    >
                      {warningMessage}
                    </ReactMarkdown>
                  </Alert>
                )}
              </div>
              <Button
                size="small"
                variant="contained"
                className="w-full mx-auto mt-8 text-white md:max-w-xs bg-primary"
                onClick={() =>
                  scroller.scrollTo('communications', {
                    offset: -90,
                  })
                }
              >
                {t('buttons.check_notices')}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
