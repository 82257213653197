export * from './regex';
export * from './rgb';
export * from './dom';
export * from './schedules';
export * from './cart';
export * from './checkout';
export * from './pixel';

import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import kebabCase from 'lodash/kebabCase';
import replace from 'lodash/replace';
import size from 'lodash/size';
import toLower from 'lodash/toLower';

export const delay = async (
  t,
  pending = null,
  fulfilled = null,
) => {
  try {
    pending && typeof pending === 'function' && pending();
    await new Promise((res) => setTimeout(res, t));
    fulfilled && typeof fulfilled === 'function' && fulfilled();
  } catch (e) {
    console.e('delay', e);
  }
};

export const parseFormData = (data) => {
  try {
    if (isObject(data) && !isEmpty(data)) {
      const formData = new FormData();
      Object.keys(data).map((k) => {
        const hasKey = data[k]?.hasOwnProperty('value');
        const val = hasKey ? data[k].value : data[k];
        // console.log('parseFormData Function', k, val);
        formData.append(k, val);
      });
      return formData;
    }
    return new Error('Data must by filled object');
  } catch (error) {
    console.error('formData', error.message);
  }
};

export const iOS =
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export const subtractSeconds = (date, seconds) => {
  try {
    let newDate = new Date(
      new Date(date).setSeconds(
        new Date(date).getSeconds() + seconds,
      ),
    );
    return dayjs(newDate).format().toString();
  } catch (error) {
    console.error('subtractSeconds', error);
  }
};

export const secondsToHms = (d) => {
  let val = Number(d);
  const h = Math.floor(val / 3600);
  const m = Math.floor((val % 3600) / 60);
  const s = Math.floor((val % 3600) % 60);
  return `${h}:${m}:${String(s)?.length === 1 ? '0' + s : s}`;
};

export const showAnchor = ({ cond, val }) => {
  try {
    if (cond && cond) return [val, cond];
    return [];
  } catch (error) {
    console.error('showAnchor', error);
  }
};

export const parseQueryString = (params) => {
  try {
    if (params?.length > 0) {
      if (params?.length > 1) {
        return params.split(',');
      }
      return [params];
    }
    return [];
  } catch (error) {
    console.error('parseQueryString', error);
  }
};

// Remove some params in query string
export const checkQueryParams = (key, val) => {
  try {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    if (key?.length && val) {
      key.map((k) => {
        if (params.has(k)) {
          const qs_key = params.get(k).split(',');
          // Se la key ha più parametri allora filtro rimuovendo quella selezionata
          if (qs_key.length > 1) {
            params.set(
              k,
              qs_key.filter((q) => q !== val),
            );
          } else {
            // Rimuovo solo il parametro in qs corrispondente
            if (qs_key[0] === val) params.delete(k);
          }
        }
      });
      return params;
    }
  } catch (error) {
    console.error('checkQueryParams', error);
  }
};

export const getCleanedPaths = ({
  city,
  baseSegment = '/esperienze/',
  typeSegment = '',
  slug,
}) => {
  try {
    if (city) {
      const cleanedCity = kebabCase(city);
      const path =
        baseSegment + cleanedCity + '/' + typeSegment + slug;
      const cleanedPath = toLower(replace(path, '//', '/'));

      return {
        cityPath: baseSegment + cleanedCity,
        baseSegment,
        typeSegment,
        prodPath: slug ? cleanedPath : '/',
      };
    }

    return {
      prodPath: '/',
      cityPath: '',
      baseSegment: '',
      typeSegment: '',
    };
  } catch (e) {
    console.error('getCleanedPaths', e);
    return {
      prodPath: '/',
      cityPath: '',
      baseSegment: '',
      typeSegment: '',
    };
  }
};

export const locationData = ({
  site,
  externalLocation,
  product,
}) => {
  if (site?.data && !externalLocation) {
    const { city, address, hero, name, img, slug, coordinates } =
      product?.site?.data?.attributes;

    const heroMedia = hero?.media?.data.attributes;
    const { formats, url } = heroMedia;
    const { large, medium, small, thumbnail } = formats || {};

    return {
      slug,
      city,
      address,
      name: name || hero?.title,
      coordinates,
      img: img?.data?.attributes?.small?.url || small?.url,
    };
  }

  if (externalLocation && !site?.data) {
    const { city, address, name, img } = externalLocation;
    return {
      city,
      address,
      name,
      slug: null,
      img: img?.data?.attributes?.url,
    };
  }
  return null;
};

const rad = function (x) {
  return (x * Math.PI) / 180;
};

export const getDistance = function (p1, p2) {
  var R = 6378137;
  var dLat = rad(p2?.lat - p1?.lat);
  var dLong = rad(p2?.lng - p1?.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;

  if (Math.round(d / 1000) > 0) return Math.round(d / 1000);

  return false;
};

export function inRange(arr, min, max) {
  try {
    const isMin = size(arr) >= min;
    const isMax = size(arr) <= max;

    return isMin && isMax;
  } catch (error) {
    return console.error('inRange', error);
  }
}
