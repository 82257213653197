import { Header, Section } from '@components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useEffect, useState } from 'react';
import {
  useGetStrapiCitiesCoordinatesQuery,
  useGetStrapiCitiesQuery,
  useGetStrapiSiteFiltersTypesQuery,
} from '@services';

import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { scroller } from 'react-scroll';
import { useMediaQueries } from '@hooks';

const SSR = { sr: false };

const Maps = dynamic(
  () => import('./../../../components/Maps/Maps'),
  SSR,
);

const Marker = dynamic(
  () => import('./../../../components/Marker/Marker'),
  SSR,
);

export default function LocationsOnMap({
  title = '',
  label = '',
  description = '',
  dense = false,
}) {
  const { isMobile } = useMediaQueries();
  const { push, locale } = useRouter();
  const { t } = useTranslation('common');
  const onClickChipLocation = (route) =>
    push(`esperienze/${route}`);

  const {
    data: types = [],
    isLoading: isTypesLoading,
    isFetching: isTypesFetching,
    isSuccess: isTypesSuccess,
    isError: isTypesError,
  } = useGetStrapiSiteFiltersTypesQuery({
    locale,
  });

  const { data: cities = [], isSuccess: isCitiesSuccess } =
    useGetStrapiCitiesQuery({ locale });

  const {
    data: coordinates = [],
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetStrapiCitiesCoordinatesQuery({
    locale,
  });

  const [places, setPlaces] = useState([]);
  const [placesNotFiltered, setPlacesNotFiltered] = useState([]);
  const [zoom, setZoom] = useState(5);
  const [center, setCenter] = useState({
    lat: 40.84114116385369,
    lng: 14.250388338747635,
  });

  useEffect(() => {
    if (coordinates?.length > 0) {
      const addShowKeyToPlaces = coordinates.map(
        ({ attributes }) => ({
          show: false,
          ...attributes,
        }),
      );
      setPlaces([...addShowKeyToPlaces]);
      setPlacesNotFiltered([...addShowKeyToPlaces]);
    }
  }, [coordinates, locale]);

  return (
    <Section
      id="locationsOnMap"
      isLoading={isLoading || isFetching}
      dense={dense}
    >
      <Header
        title={title}
        label={label}
        description={description}
      />

      {!isMobile && (
        <div className="flex flex-wrap justify-center max-w-5xl pb-10 mx-auto">
          {isCitiesSuccess &&
            cities?.length > 0 &&
            sortBy(cities, 'attributes.name')?.map(
              ({ attributes }, i) => (
                <Chip
                  key={i}
                  className="m-1.5"
                  variant="outlined"
                  label={`#${attributes?.name.replace(/\s/g, '')}`}
                  onClick={() =>
                    onClickChipLocation(
                      attributes?.slug
                        .replace(/\s/g, '-')
                        .toLowerCase(),
                    )
                  }
                />
              ),
            )}
        </div>
      )}

      {isMobile && isCitiesSuccess && cities?.length > 0 && (
        <Splide
          className="w-11/12 mx-auto my-10 mb-4 md:my-4 md:w-3/4"
          options={{
            rewind: true,
            gap: '0.6rem',
            pagination: false,
            arrows: false,
            focus: 'center',
            autoWidth: true,
          }}
        >
          {sortBy(cities, 'attributes.name')?.map(
            ({ attributes }, i) => (
              <SplideSlide key={i}>
                <Chip
                  key={i}
                  className="m-1"
                  variant="outlined"
                  label={`#${attributes?.name.replace(/\s/g, '')}`}
                  onClick={() =>
                    onClickChipLocation(
                      attributes?.slug
                        .replace(/\s/g, '-')
                        .toLowerCase(),
                    )
                  }
                />
              </SplideSlide>
            ),
          )}
        </Splide>
      )}

      <Maps
        locale={locale}
        className="mx-auto h-96 overflow-clip rounded-xl"
        googleMapOption={{
          defaultZoom: zoom,
          defaultCenter: center,
          onClick: () => {
            const resetInfo = places.map((p) => ({
              ...p,
              show: false,
            }));
            setPlaces([...resetInfo]);
          },
          onChildClick: (key, { lat, lng }) => {
            let active = {
              ...find(places, { name: key }),
              show: true,
            };
            let notActive = places
              .filter((p) => p.name !== key)
              .map((c) => ({ ...c, show: false }));
            setPlaces([active, ...notActive]);

            setCenter({
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            });
          },
        }}
      >
        {isSuccess &&
          places.length > 0 &&
          places.map(
            ({
              coordinates,
              name,
              show,
              city,
              region,
              slug,
              address,
              type,
              img,
            }) => {
              const { small, thumbnail } =
                img?.data?.attributes?.formats || {};

              return (
                <Marker
                  lat={coordinates?.lat}
                  lng={coordinates?.lng}
                  key={name}
                  city={city?.data?.attributes?.name}
                  show={show}
                  address={address}
                  name={name}
                  region={region}
                  setCenter={setCenter}
                  setZoom={setZoom}
                  canonical_name={slug}
                  type={type?.data?.attributes?.slug}
                  img={small?.url || thumbnail?.url}
                />
              );
            },
          )}
      </Maps>
      <div className="relative flex-col justify-center hidden mt-8 space-y-3 md:flex">
        <div className="flex justify-center w-full text-lg italic">
          {t('generic.labels.filter_by')}
        </div>
        <div className="flex flex-row justify-center md:space-x-4">
          {isTypesSuccess &&
            sortBy(
              [
                ...types,
                {
                  name: t('generic.labels.all'),
                  slug: 'all',
                },
              ],
              'name',
            )?.map(({ name, slug }, i) => (
              <div
                className="flex items-center justify-center space-x-2"
                key={i}
              >
                <span
                  className={classNames('flex h-3 w-3 rounded-sm', {
                    'bg-green-600': slug.includes('parco'),
                    'bg-primary': slug.includes('museo'),
                    'bg-red-700': slug.includes('teatro'),
                    'bg-yellow-500': slug.includes('area'),
                    'bg-gray-400': slug.includes('castello'),
                    'bg-slate-800 dark:bg-slate-50':
                      slug.includes('all'),
                  })}
                />

                <button
                  onClick={() => {
                    //&& places.length !== placesNotFiltered.length
                    if (slug === 'all') {
                      setPlaces(placesNotFiltered);
                    } else {
                      const filtered = placesNotFiltered?.filter(
                        ({ type }) => {
                          const { slug: placeSlug } =
                            type.data.attributes;
                          return placeSlug === slug;
                        },
                      );
                      setPlaces(filtered);
                    }
                    scroller.scrollTo('locationsonmap', {
                      offset: 100,
                      duration: 200,
                    });

                    setZoom(5);
                    setCenter({
                      lat: 40.84114116385369,
                      lng: 14.250388338747635,
                    });
                  }}
                  className={classNames(
                    'text-base font-semibold',
                    'underline-offset-4 hover:underline',
                    'h-8',
                  )}
                >
                  {name}
                </button>
              </div>
            ))}
        </div>
      </div>
    </Section>
  );
}
