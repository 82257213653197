import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import { useRouter } from 'next/router';

export default function LimitCartDialog({
  open = false,
  onClose,
  maxWidth = 'sm',
  maxQuantityAllowed,
}) {
  const { locale } = useRouter();

  const lang = {
    title: {
      en: 'Maximum number of tickets reached',
      it: 'Numero massimo di biglietti raggiunto',
    },
    text: {
      en: `It is possible to add up to a maximum of ${maxQuantityAllowed} tickets per type.`,
      it: `È possibile aggiungere fino ad un massimo di ${maxQuantityAllowed} biglietti per tipo.`,
    },
    link: {
      en: 'You can cancel tickets from',
      it: 'Puoi cancellare i biglietti dal',
    },
    cart: {
      en: 'Cart',
      it: 'Carrello',
    },
    button: {
      en: 'Continue',
      it: 'Continua',
    },
  };

  return (
    <Dialog maxWidth={maxWidth} open={open} onClose={onClose} className='select-none'>
      <DialogTitle className="text-base text-center text-black dark:text-white">
        {lang?.title[locale]}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>{lang?.text[locale]}</span>
          <span className="flex">
            {lang?.link[locale]}
            <Link href="./cart" passHref>
              <a className="ml-1 font-semibold transition-colors ease-in-out hover:text-primary">
                {lang?.cart[locale]}
              </a>
            </Link>
            .
          </span>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button autoFocus className="w-full" onClick={onClose}>
          {lang?.button[locale]}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
