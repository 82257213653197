import { ThemeProvider, createTheme } from "@mui/material/styles";
import { currentThemeSelector, setThemeReducer } from "@slices";

import CssBaseline from "@mui/material/CssBaseline";
import MUI_DARK_THEME from "./themes/mui.dark.theme";
import MUI_LIGHT_THEME from "./themes/mui.light.theme";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const CustomThemeProvider = ({ children }) => {
  const currentTheme = useSelector(currentThemeSelector);
  const isDark = currentTheme == "dark";

  useEffect(() => {
    setThemeReducer(isDark ? "light" : "dark");
    document.documentElement.classList.toggle("dark", isDark);
  }, [currentTheme, isDark]);

  return (
    <ThemeProvider theme={createTheme(isDark ? MUI_DARK_THEME : MUI_LIGHT_THEME)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
