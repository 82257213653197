import { DefaultGallery } from '@components';
import sortBy from 'lodash/sortBy';
import { useGetStrapiSitesQuery } from '@services';
import { useRouter } from 'next/router';

export default function SiteGallery({
  options,
  query = null,
  title,
  label = null,
  description = null,
  showDescription,
  getDistanceFrom = null,
  dense = false,
  noPadding = false,
}) {
  const { push, locale } = useRouter();
  const {
    data: sites,
    isLoading,
    isSuccess,
    isFetching,
    isError,
  } = useGetStrapiSitesQuery({ query, locale });

  const onClickTitle = () =>
    push('/esperienze/musei-parchi-archeologici');
  
  
  return (
    <DefaultGallery
      type="museum"
      title={title}
      label={label}
      dense={dense}
      noPadding={noPadding}
      options={options}
      isLoading={isLoading || isFetching}
      description={description}
      showDescription={showDescription}
      onClickTitle={onClickTitle}
      pathTo="musei-parchi-archeologici"
      getDistanceFrom={getDistanceFrom}
      items={sortBy(
        sites?.data,
        'attributes.city.data.attributes.name',
      )}
    />
  );
}
