import classNames from 'classnames';

export default function ExpandButton({ onClick, className }) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        className,
        'py-2 font-medium',
        'text-primary-500 text-start',
      )}
    >
      Read More
    </button>
  );
}
