import classNames from 'classnames';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Warning from '@mui/icons-material/Warning';
import { useTranslation } from 'next-i18next';

export default function CartActions({
  user,
  total,
  allowPayment,
  cartHasReductions,
  onCreateOrder,
  onShowLogin,
  onShowDiscountStepper,
  onHandlePayment,
  isCreateOrderLoading,
  fixed = false,
}) {
  const { logged, reductionsIDs } = user;
  const { t } = useTranslation('common');

  const buttonCls = classNames(
    'w-full h-12 rounded-sm',
    'text-white uppercase',
    'bg-primary',
    {
      'fixed bottom-0 left-0 right-0 z-[99] h-12 w-full !rounded-none':
        fixed,
    },
  );

  // NOT LOGGED
  if (!logged)
    return (
      <Button
        className="w-full h-12 text-white rounded-sm bg-primary"
        onClick={onShowLogin}
      >
        {t('buttons.login')}
      </Button>
    );

  if (logged) {
    if (cartHasReductions && !allowPayment) {
      return (
        <Button
          key="add-IDs"
          endIcon={<Warning fontSize="small" />}
          className={buttonCls}
          onClick={onShowDiscountStepper}
        >
          {t('buttons.add_ids')}
        </Button>
      );
    }

    if (
      total === 0 &&
      allowPayment &&
      cartHasReductions &&
      reductionsIDs?.length > 0
    ) {
      return (
        <Button
          disabled={isCreateOrderLoading}
          key="create-order"
          onClick={onCreateOrder}
          className={buttonCls}
        >
          {isCreateOrderLoading ? (
            <CircularProgress
              size={20}
              thickness={4}
              className="text-white"
            />
          ) : (
            t('buttons.continue')
          )}
        </Button>
      );
    }

    if (allowPayment && total > 0) {
      return (
        <Button
          key="pay"
          onClick={onHandlePayment}
          className={buttonCls}
        >
          {t('buttons.pay')}
        </Button>
      );
    }

    if (total === 0 && !cartHasReductions) {
      return (
        <Button
          key="pay-free"
          onClick={onCreateOrder}
          className={buttonCls}
        >
          {t('buttons.continue')}
        </Button>
      );
    }
  }

  return null;
}
