import Link from 'next/link';
import classNames from 'classnames';

export default function Type({
  pathname,
  query,
  name = '',
  slug = '',
}) {
  const toCheck = slug?.toLowerCase().trim();
  const setTypeColor = () => {
    switch (toCheck) {
      case 'museo' || 'museum':
        return 'bg-primary dark:bg-primary-400';
      case 'teatro' || 'theater':
        return 'bg-red-700 dark:bg-red-600';
      case 'castello' || 'castle':
        return 'bg-gray-400';
      case 'area-archeologica' || 'archeological-area':
        return 'bg-yellow-500';
      case 'parco-archeologico' || 'archaeological-park':
        return 'bg-green-600';
      case 'mostra' || 'exhibition':
        return 'bg-blue-600';
      case 'spettacolo' || 'show':
        return 'bg-teal-500';
      case 'food':
        return 'bg-rose-400';
      case 'visita guidata':
        return 'bg-amber-600';
      default:
        return 'bg-primary dark:bg-primary-400';
    }
  };

  if (name && pathname && query && slug) {
    return (
      <Link passHref href={{ pathname, query: { types: slug } }}>
        <a
          className={classNames(
            setTypeColor(),
            'px-2.5 py-1 md:py-0.5',
            'underline-offset-2 hover:underline',
            'inline-flex items-center',
            'backdrop-blur-3xl',
            'text-white',
            'font-condensed text-xs uppercase tracking-wider',
          )}
        >
          {name}
        </a>
      </Link>
    );
  }

  return null;
}
