import CircularProgress from '@mui/material/CircularProgress';
import { Element } from 'react-scroll';
import classNames from 'classnames';

export default function Section({
  children,
  id = '',
  isLoading = false,
  className,
  dense = true
}) {
  return (
    <Element
      id={id?.toLowerCase()}
      name={id?.toLowerCase()}
      className={classNames(
        className,
        'section relative w-full',
        'text-gray-600 dark:text-white',
        'mx-auto',
        {
          'my-14 lg:my-16 xl:my-18 2xl:my-20': dense,
          'animate-pulse': isLoading,
        },
      )}
    >
      {isLoading ? (
        <div
          className={classNames(
            'h-60 w-full transform top-1/2 -translate-y-1/2',
            'section-loader absolute z-[999]',
            'flex items-center justify-center',
          )}
        >
          <CircularProgress size={20} />
        </div>
      ): children}
    </Element>
  );
}
