import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

// Ricordati di cambiarlo in locale
const baseUrl = 'https://aditusculture.com' + '/api/newsletter';

export const NewsletterService = createApi({
  reducerPath: 'Newsletter',
  tagTypes: ['Newsletter'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
  }),
  onQueryStarted: () => {},
  endpoints: (builder) => ({
    // GET USER DATA QUERY
    testAddPrivateToContactList: builder.query({
      query: (params = '') => `/private?` + params,
      transformResponse: (res, meta, arg) => {
        return res;
      },
    }),
    // STORE NEW USER
    addPrivateToContactList: builder.mutation({
      query: ({ email }) => {
        if (email) {
          return {
            url: baseUrl + '/private',
            method: 'POST',
            body: { email },
          };
        }
      },
      transformResponse: (res) => {
        console.log(res);
        return res;
      },
    }),
  }),
});

export const {
  useTestAddPrivateToContactListQuery,
  useAddPrivateToContactListMutation,
} = NewsletterService;

export default NewsletterService;
