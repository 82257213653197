import Button from '@mui/material/Button';
import { Masonry } from '@components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classNames from 'classnames';

export default function Food({ title, content, gallery, cta }) {
  return (
    <div
      className={classNames(
        'space-y-8 pb-8',
        'text-gray-700 dark:text-white',
        'items-center max-w-screen-xl mx-auto',
      )}
    >
      <Masonry images={gallery?.list?.data || []} />

      <div className="space-y-2">
        {title && title?.length > 0 && (
          <h4
            className={classNames(
              'font-title font-semibold',
              'text-base uppercase',
              'md:text-xl',
            )}
          >
            {title}
          </h4>
        )}

        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className="my-8 text-sm strapi-editorial md:text-base"
        >
          {content}
        </ReactMarkdown>
      </div>

      {cta && (
        <div
          className={classNames(
            'flex flex-col justify-center',
            'w-full mx-auto',
            'pt-4 space-y-2',
          )}
        >
          <Button
            size="small"
            variant="contained"
            className="w-full mx-auto text-white md:max-w-xs bg-primary"
          >
            <a href={cta?.hfref} target="_blank" rel="noreferrer">
              {cta?.label}
            </a>
          </Button>
        </div>
      )}
    </div>
  );
}
