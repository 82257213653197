export const toggleClasses = (el, status, cls) =>
  el && cls && el.classList.toggle(cls, status);

export const toggleBlurLayout = (cls, status) => {
  const layout = document.querySelector('.layout');
  if (layout && cls && Array.isArray(cls) && cls?.length > 0)
    cls?.map((c) => toggleClasses(layout, status, c));
};

export function toggleBodyOverflow() {
  // Verifica che document e document.body esistano
  if (typeof document === 'undefined' || !document.body) {
    console.warn('Document o document.body non trovati.');
    return;
  }

  // Ottieni lo stile effettivo del body
  const computedStyle = window.getComputedStyle(document.body);
  const currentOverflow = computedStyle
    ? computedStyle.overflow
    : '';

  switch (currentOverflow) {
    case 'hidden':
      document.body.style.overflow = 'auto';
      break;
    case 'scroll':
    case 'auto':
    case 'visible':
    default:
      document.body.style.overflow = 'hidden';
      break;
  }
}

export function toggleFullScreen() {
  const docElem = document?.documentElement;

  if (!document?.fullscreenElement) {
    const enterFullScreenMethod =
      docElem?.requestFullscreen ??
      docElem?.mozRequestFullScreen ??
      docElem?.webkitRequestFullscreen ??
      docElem?.msRequestFullscreen;

    enterFullScreenMethod?.call(docElem);
  } else {
    const exitFullScreenMethod =
      document?.exitFullscreen ??
      document?.mozCancelFullScreen ??
      document?.webkitExitFullscreen ??
      document?.msExitFullscreen;

    exitFullScreenMethod?.call(document);
  }
}
