import classNames from "classnames";

export default function DarkLayer({ className }) {
  return (
    <div
      className={classNames(
        className,
        "bg-gradient-to-t from-black/60",
        "inset-0 z-[5] h-full w-full",
        "absolute bottom-0 left-0 right-0 top-0",
      )}
    />
  );
}
