import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.NEXT_PUBLIC_ADITUS;
export const SitesService = createApi({
  reducerPath: 'Sites',
  tagTypes: ['Sites'],
  //refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;
      if (token) {
        headers.set('Accept', 'application/json');
        headers.set('Authorization', `Bearer ${token}`);
        return headers;
      }

      return headers;
    },
  }),
  onQueryStarted: () => {
    console.log('onQueryStarted SITES');
  },
  endpoints: (builder) => ({
    getTopSales: builder.query({
      query: () => '/sites/sales_top_five',
      transformResponse: (res) => {
        return res?.data;
      },
    }),
    getCities: builder.query({
      query: () => `/sites/cities`,
      transformResponse: (res) => {
        return res?.data || [];
      },
    }),
    getCoordinates: builder.query({
      query: () => '/sites/coordinates',
      transformResponse: (res) => {
        return res?.data;
      },
    }),

    getSiteTourBySlug: builder.query({
      query: ({ slug, date, hour, locale }) => {
        return `/v3/sites/${slug}/products?date_service=${date}&hour_service=${hour}&categories=["service","audioguide"]&lang=${locale}`;
      },
      transformResponse: (res) => {
        return res?.data;
      },
    }),
    getScheduleBySlug: builder.query({
      query: (slug) => `/sites/${slug}/schedule`,
      transformResponse: (res) => {
        return res?.data;
      },
    }),
    getScheduleExceptionsBySlug: builder.query({
      query: (slug) => `${slug}/schedule-exceptions`,
      transformResponse: (res) => {
        return res?.data;
      },
    }),
    getSlotsBySlugAndDate: builder.query({
      query: ({ slug, date }) => `/sites/${slug}/slots/${date}`,
      transformResponse: (res) => {
        return res?.data || [];
      },
    }),
    getTickets: builder.query({
      query: ({ slug, locale }) => {
        return `/v3/sites/${slug}/tickets?lang=${locale}`;
      },
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
    getTicketsByDateAndHour: builder.query({
      keepUnusedDataFor: 0,
      query: ({ slug, date, hour, locale }) => {
        return `/v3/sites/${slug}/tickets?hour_service=${hour}&date_service=${date}&lang=${locale}`;
      },
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
    getSitesByRegion: builder.query({
      query: () => `/sites?region=Puglia`,
      transformResponse: (res) => {
        return res?.data;
      },
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useGetTopSalesQuery,
  useGetCoordinatesQuery,
  useGetSiteTourBySlugQuery,
  useGetScheduleBySlugQuery,
  useGetSlotsBySlugAndDateQuery,
  useGetScheduleExceptionsBySlugQuery,
  useGetTicketsQuery,
  useGetTicketsByDateAndHourQuery,
  useLazyGetTicketsByDateAndHourQuery,
  useGetSitesByRegionQuery,
} = SitesService;

export default SitesService;
