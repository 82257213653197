import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'next-i18next';

export default function DeleteCartProductDialog({
  onClose,
  showDeleteConfirm,
  onDelete,
}) {
  // n18i
  const { t } = useTranslation('common');

  return (
    <Dialog onClose={onClose} open={showDeleteConfirm}>
      <DialogTitle>
        <span className="text-base">
          {t('generic.labels.confirm_cancel')}
        </span>
      </DialogTitle>
      <DialogActions>
        <Button className="w-1/2" onClick={onClose}>
          {t('buttons.no')}
        </Button>
        <Button autoFocus className="w-1/2" onClick={onDelete}>
          {t('buttons.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
