import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

export default function ButtonSlot({
  event_hour,
  available_pax,
  slotClassName,
  btnClassName,
  selected = null,
  onClick,
}) {
  const isSelected = selected === event_hour;
  const onSelectHour = () => onClick(event_hour);
  const { t } = useTranslation('common');

  if (event_hour) {
    return (
      <div
        className={classNames(
          slotClassName,
          'button-slot',
          'relative cursor-pointer md:rounded-sm',
          'transition-all duration-200 ease-in-out',
          'bg-gray-200 hover:text-primary dark:bg-black',
          {
            'border border-primary text-primary': isSelected,
            'pointer-events-none opacity-10 ':
              !available_pax || available_pax === 0,
          },
        )}
      >
        <code className="absolute top-0 hidden text-xxs opacity-30 left-1 lg:flex">
          {available_pax}
        </code>

        <Tooltip
          title={
            <span className="space-x-1 text-xs uppercase">
              <span>{available_pax}</span>
              <span>
                {t('drawers.book_product.steps.hour.available')}
              </span>
            </span>
          }
        >
          <button
            onClick={onSelectHour}
            disabled={available_pax === 0 || !available_pax}
            className={classNames(
              btnClassName,
              'w-full h-full',
              'p-1.5 md:p-2 text-xs md:text-sm',
              'text-center',
              'font-medium md:text-normal',
            )}
          >
            {event_hour}
          </button>
        </Tooltip>
      </div>
    );
  }
}
