import { Splide, SplideSlide } from '@splidejs/react-splide';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section } from '@components';
import { useMediaQueries } from '@hooks';
import classNames from 'classnames';

export default function Features({ list }) {
  const { isTablet } = useMediaQueries();

  const Feature = (f) => {
    const { name, description, icon } = f;

    return (
      <div className="relative flex justify-center space-x-6 md:p-4">
        {icon?.data?.attributes && (
          <div
            className={classNames(
              'flex items-center justify-center',
              'w-16 h-16',
              'rounded-sm shrink-0',
              'bg-slate-100',
              'select-none'
            )}
          >
            <LazyLoadImage
              alt="img"
              threshold={0}
              effect="opacity"
              src={icon?.data?.attributes?.url}
              className="object-cover w-8 h-auto mx-auto dark:brightness-0"
            />
          </div>
        )}

        <div className="flex flex-col">
          <h3 className="font-semibold">{name}</h3>
          <p className="flex max-w-xs text-sm text-start">
            {description}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Section id="Features">
      {isTablet && (
        <Splide options={{ arrows: false, autoplay: true }}>
          {list?.length > 0 &&
            list.map((f, i) => (
              <SplideSlide key={i}>
                <Feature {...f} key={i} />
              </SplideSlide>
            ))}
        </Splide>
      )}

      {!isTablet && (
        <div className="flex flex-row w-full xl:mx-auto xl:w-10/12 xl:justify-around">
          {list?.length > 0 &&
            list.map((f, i) => <Feature {...f} key={i} />)}
        </div>
      )}
    </Section>
  );
}
