import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.NEXT_PUBLIC_ADITUS + "/countries";
export const CountriesService = createApi({
  reducerPath: "Countries",
  tagTypes: ["Countries"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }

      return headers;
    },
  }),
  onQueryStarted: () => {
    console.log("onQueryStarted COUNTRIES");
  },
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => "",
      transformResponse: (res) => {
        return res?.data || [];
      },
    }),
  }),
});

export const { useGetCountriesQuery } = CountriesService;

export default CountriesService;
