import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import classNames from "classnames";
//import { iOS } from "@utils";
import sortBy from "lodash/sortBy";
import { useTranslation } from "next-i18next";


export default function DrawerFilters({
  cities,
  locations,
  types,
  filters,
  isFiltersLoading,
  onResetFilter,
  onChangeCity,
  onChangeTypes,
  filtersLoading,
  drawerFiltersOpen,
  isCitiesLoading,
  setDrawerFiltersOpen,
  groupedByRegion,
  regions,
  setRegions,
  setLocations,
}) {
  // i18n
  const { t } = useTranslation("common");

  // Events
  const opOpenFilters = () => setDrawerFiltersOpen(true);
  const onCloseFilters = () => setDrawerFiltersOpen(false);

  return (
    <SwipeableDrawer
      elevation={5}
      anchor={"right"}
      variant="temporary"
      hideBackdrop={false}
      // disableDiscovery={iOS}
      onOpen={opOpenFilters}
      onClose={onCloseFilters}
      open={drawerFiltersOpen}
      disableSwipeToOpen={false}
      //disableBackdropTransition={!iOS}
      ModalProps={{ keepMounted: true }}
      transitionDuration={{ enter: 200, exit: 500 }}
      sx={{
        "& .MuiDrawer-paper": {
          background: "none",
          border: "none",
        },
      }}
    >
      <div
        style={{ width: window.innerWidth / 1.5 }}
        className="relative flex-col h-screen overflow-hidden shadow-2xl z-100 bg-zinc-100 dark:bg-zinc-800"
      >
        <div className="h-screen">
          <div className="relative flex items-center justify-center w-full h-10 md:h-14">
            <IconButton
              color="inherit"
              component="span"
              onClick={onCloseFilters}
              className="absolute flex items-center justify-center w-10 h-10 text-xl cursor-pointer left-3 top-3"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <div className="flex flex-col px-6 pb-0 space-y-2">
            <Divider className="font-semibold uppercase text-primary">
              <Chip variant="outlined" label={t("generic.labels.filters")} className="px-6 w-fit rounded-xl text-primary" />
            </Divider>
          </div>

          <div className="relative flex flex-col w-full h-screen px-6 overflow-y-scroll top-10">
            <div className="flex flex-col mb-5 space-y-4 pb-72">
              {isCitiesLoading && (
                <div className="flex items-end justify-end w-full">
                  <CircularProgress thickness={4} size={20} />
                </div>
              )}

              {!isCitiesLoading && cities?.length > 0 && (
                <div className="flex flex-col">
                  <span className="w-full mb-2 font-semibold text-right uppercase text-primary">{t("generic.labels.cities")}</span>

                  <ul className="flex flex-col space-y-6">
                    {groupedByRegion(cities)?.map(({ slug, name, list }, y) => {
                      return (
                        <li className="text-right" key={y}>
                          <FormGroup className="mb-2 text-sm">
                            <FormControlLabel
                              label={<span className={classNames("text-right font-title text-xl font-medium")}>{name}</span>}
                              labelPlacement="start"
                              control={
                                <Checkbox
                                  size="small"
                                  onChange={() => {
                                    const filteredByRegion = cities?.filter(
                                      ({ attributes }) => attributes?.region.toLowerCase() === name.toLowerCase(),
                                    );
                                    if (regions?.filter((region) => region.toLowerCase() === name.toLowerCase())?.length > 0) {
                                      setRegions([...regions.filter((region) => region.toLowerCase() !== name.toLowerCase())]);
                                      setLocations([...locations.filter(({ region }) => region !== name)]);
                                    } else {
                                      setRegions([...regions, name.toLowerCase()]);
                                      setLocations([
                                        ...locations,
                                        ...filteredByRegion.map(({ attributes }) => {
                                          return {
                                            name: attributes?.name,
                                            slug: attributes?.slug,
                                            region: attributes?.region,
                                          };
                                        }),
                                      ]);

                                      window?.history.replaceState({}, document.title, window?.location?.pathname);
                                    }
                                  }}
                                  className="p-1 text-sm"
                                  value={name}
                                  checked={regions?.filter((region) => region.toLowerCase() === name?.toLowerCase())?.length > 0}
                                />
                              }
                            />
                          </FormGroup>
                          <div>
                            {list?.map(({ attributes }, i) => {
                              const { slug, name, region } = attributes;
                              return (
                                <FormGroup key={i} className="text-sm">
                                  <FormControlLabel
                                    key={attributes.name}
                                    label={attributes.name}
                                    labelPlacement="start"
                                    className="text-sm text-gray-700 dark:text-white"
                                    control={
                                      <Checkbox
                                        size="small"
                                        onChange={onChangeCity}
                                        className="p-1 text-sm"
                                        value={JSON.stringify({
                                          slug,
                                          name,
                                          region,
                                        })}
                                        checked={locations?.filter((l) => l?.slug === slug)?.length > 0}
                                      />
                                    }
                                  />
                                </FormGroup>
                              );
                            })}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {isFiltersLoading && (
                <div className="flex items-end justify-end w-full">
                  <CircularProgress thickness={4} size={20} />
                </div>
              )}

              {!isFiltersLoading && filters?.length > 0 && (
                <div className="flex flex-col">
                  <span className="w-full mb-2 font-semibold text-right uppercase text-primary">{t("generic.labels.type")}</span>

                  <FormGroup>
                    {sortBy(filters, "attributes.name")?.map((f, i) => {
                      const { slug, name } = f;

                      return (
                        <FormControlLabel
                          key={i}
                          label={name}
                          labelPlacement="start"
                          className="text-sm text-gray-700 dark:text-white"
                          control={
                            <Checkbox
                              size="small"
                              value={JSON.stringify({
                                slug,
                                name,
                              })}
                              className="p-1"
                              onChange={onChangeTypes}
                              checked={types?.filter((t) => t?.slug === slug)?.length > 0}
                            />
                          }
                        />
                      );
                    })}
                  </FormGroup>
                </div>
              )}

              {[...locations, ...types].length > 0 && (
                <button type="text" onClick={onResetFilter} className="w-full text-sm font-bold text-right uppercase">
                  Reset
                </button>
              )}
            </div>
          </div>

          <Button
            onClick={onCloseFilters}
            style={{ width: window.innerWidth / 1.5 }}
            className="fixed bottom-0 right-0 h-12 text-white rounded-none bg-primary md:h-14 "
          >
            {t("buttons.close")}
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
}
