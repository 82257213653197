import classNames from 'classnames';
import useAnimateNumber from 'react-hook-animate-number';
import { useRouter } from 'next/router';
import personages from './personage.data';
import FacebookIcon from '@mui/icons-material/Facebook';

export default function Results({ result, onResetStep, onShare }) {
  const { locale } = useRouter();
  const { number } = useAnimateNumber({ number: result });
  // Select the right personage to show
  const getPersonage = personages?.filter(({ range }) => {
    return result >= range?.min && result <= range?.max;
  });

  const onOpenShareLink = (e) => {
    try {
      e?.preventDefault();

      onShare({
        url: window?.location?.href,
        title: `${getPersonage[0]?.name}: Sei una capra`,
        description: getPersonage[0]?.quote[locale],
        images: [
          {
            url:
              window?.location?.origin +
              '/public/' +
              getPersonage[0]?.img,
            width: 800,
            height: 420,
            alt: 'Sei una capra',
          },
        ],
      });

      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${window?.location?.href}`,
        '_blank',
      );
    } catch (error) {
      console.error('onOpenShareLink', error);
    }
  };

  return (
    <div className="z-20 flex flex-col space-y-12 select-none">
      <div
        className={classNames(
          'relative mx-auto xl:max-w-lg w-full',
          'flex flex-col items-center justify-center',
        )}
      >
        {getPersonage?.length > 0 &&
          getPersonage?.map(({ name, job, img }, i) => {
            return (
              <div
                key={i}
                className={classNames(
                  'group relative select-none overflow-hidden',
                  'h-96 lg:w-96 rounded-md p-4 w-full',
                  'bg-slate-200 dark:bg-zinc-700',
                  'shadow-sm',
                )}
              >
                <div
                  className={classNames(
                    'relative z-20',
                    'flex flex-col justify-end',
                    'space-y-2 text-end text-white',
                  )}
                >
                  <span
                    className={classNames(
                      'text-sm font-medium uppercase',
                      'font-condensed tracking-widest',
                    )}
                  >
                    Il tuo punteggio
                  </span>
                  <h1 className="w-full text-6xl font-extrabold text-end font-title">
                    {number}
                    <span className="ml-1 text-xl font-normal">
                      %
                    </span>
                  </h1>
                </div>

                <div className="absolute bottom-0 left-0 z-10 w-full p-4 space-y-2 text-white">
                  <h1
                    className={classNames(
                      'mb-0 max-w-xs',
                      'font-title text-xl font-[900] uppercase',
                    )}
                  >
                    {name}
                  </h1>

                  <ul className="space-y-1">
                    <li className="italic">{job[locale]}</li>
                  </ul>
                </div>

                <img
                  src={img}
                  alt={name}
                  className={classNames(
                    'bg-gradient-to-t from-black/60',
                    'absolute',
                    'inset-0 -z-0 h-full w-full',
                    'object-cover object-center',
                    'brightness-50 group-hover:scale-110',
                    'transition-all duration-500 ease-in-out',
                  )}
                />
              </div>
            );
          })}
      </div>

      <p className="w-full mx-auto mt-8 text-center lg:text-xl xl:max-w-xl">
        {getPersonage[0]?.quote[locale]}
      </p>

      {/* <button
        onClick={onOpenShareLink}
        className={classNames(
          'bg-[#1877f2]',
          'flex items-center justify-center',
          'w-full xl:max-w-lg mx-auto space-x-4 mb-2',
          'rounded px-6 py-2.5 text-xs font-medium',
          'uppercase leading-normal text-white shadow-md',
          'hover:shadow-lg active:shadow-lg',
          'focus:shadow-lg focus:outline-none focus:ring-0',
          'transition duration-150 ease-in-out',
        )}
      >
        <span>Share</span>
        <FacebookIcon />
      </button> */}
    </div>
  );
}
