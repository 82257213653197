import { DarkLayer } from '@cardElements';
import { useState } from 'react';
import classNames from 'classnames';
import truncate from 'lodash/truncate';
import ExpandButton from './Sections/ExpandButton';
import Description from './Sections/Description';
import Header from './Sections/Header';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from '@mui/material/Skeleton';
import { useStrapiGetImgFormats } from '@hooks';

export default function ArtistCard({
  link,
  birthplace,
  fullname,
  show,
  img,
  bio,
}) {
  const [loading, setLoading] = useState(true);
  const [expand, setShowExpand] = useState(false);
  const onLoadMedia = () => setLoading(false);

  const { responsiveSrc } = useStrapiGetImgFormats({ img });

  return (
    <div
      className={classNames(
        'artist-card group relative',
        'h-110 p-4 md:p-6 xl:p-8 z-20',
        'overflow-hidden rounded-md',
        'flex flex-col justify-end',
        'bg-slate-100 dark:bg-zinc-800',
      )}
    >
      <Header
        link={link}
        show={show}
        expand={expand}
        className="absolute right-0 top-3 lef-0"
      />

      <div className={classNames('z-40 flex flex-col')}>
        <Description
          bio={bio}
          expand={expand}
          link={link}
          onClick={() => setShowExpand(false)}
        />
        <div
          className={classNames(
            'flex flex-col',
            'transition-opacity ease-in-out duration-150',
            {
              'opacity-0': expand,
              'delay-150': !expand,
            },
          )}
        >
          <div className="space-y-0.5 flex flex-col text-white">
            <span>{birthplace}</span>
            <h2
              className={classNames(
                'mb-0 max-w-xs',
                'text-xl uppercase',
                'font-title font-[900]',
              )}
            >
              {fullname}
            </h2>

            <ExpandButton
              className="md:hidden"
              onClick={() => setShowExpand(true)}
            />
          </div>

          {bio && (
            <p
              className={classNames(
                'hidden lg:flex text-white flex-col',
                'opacity-80 h-0 overflow-hidden',
                'text-sm leading-0',
                'group-hover:h-20',
                'transform-gpu',
                'transition-all duration-300 ease-in-out',
              )}
            >
              {truncate(bio, { length: 95, separator: /,.:? +/ })}
              <ExpandButton onClick={() => setShowExpand(true)} />
            </p>
          )}
        </div>
      </div>

      <DarkLayer />

      {loading && (
        <Skeleton
          animation="wave"
          height={'100%'}
          width={'100%'}
          variant="rectangular"
          className="absolute inset-0 z-50 w-full h-full"
        />
      )}

      <LazyLoadImage
        alt={
          img?.data?.attributes?.caption ||
          img?.data?.attributes?.alternativeText
        }
        effect="opacity"
        afterLoad={onLoadMedia}
        src={responsiveSrc}
        className={classNames(
          'absolute',
          'inset-0 z-0 h-full w-full',
          'object-cover object-center transform-gpu',
          'group-hover:scale-110 md:group-hover:brightness-50',
          'brightness-50 md:brightness-90 transition-all duration-300 ease-in-out',
          { 'blur-lg': expand },
        )}
      />
    </div>
  );
}
