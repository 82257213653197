import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

export default function Calendar({ end, start, showLabels }) {
  const { t } = useTranslation('common');
  const { isMobile } = useMediaQueries();
  const isTerminated = end && new Date(end) < new Date();
  const isComingSoon = start && new Date(start) > new Date();

  const ComingSoon = () => {
    return (
      <span className="font-medium text-blue-800 dark:text-blue-500">
        Coming Soon
      </span>
    );
  };

  const OnGoing = () => {
    return (
      <span className="font-medium text-green-600 dark:text-green-500">
        {t('generic.labels.ongoing')}
      </span>
    );
  };

  const Terminated = () => {
    return (
      <span className="font-medium text-red-700 dark:text-red-600">
        {t('generic.labels.terminated')}
      </span>
    );
  };

  if (start && end) {
    return (
      <>
        <Stack
          spacing={0.4}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          className="flex"
        >
          {start && !isTerminated && !isMobile && showLabels && (
            <span className="flex items-center justify-start space-x-1 text-sm">
              <span className="capitalize">
                {t('generic.labels.from')}
              </span>
              <span className="font-medium">
                {dayjs(start).format('DD MMMM YYYY')}
              </span>
            </span>
          )}

          {end && !isTerminated && !isMobile && showLabels && (
            <span className="flex items-center justify-start space-x-1 text-sm">
              <span className="capitalize">
                {t('generic.labels.to')}
              </span>
              <span className="font-medium">
                {dayjs(end).format('DD MMMM YYYY')}
              </span>
            </span>
          )}

          {isTerminated && <Terminated />}
          {!isTerminated && isComingSoon && <ComingSoon />}
          {!isTerminated && !isComingSoon && <OnGoing />}
        </Stack>
      </>
    );
  }
}
