import { LazyLoadImage } from 'react-lazy-load-image-component';
import Link from 'next/link';
import SimpleBar from 'simplebar-react';
import capitalize from 'lodash/capitalize';
import { getCleanedPaths } from '@utils';
import { setDrawerQuickBuyStatus } from '@slices';
import sortBy from 'lodash/sortBy';
import { toggleBlurLayout } from '@utils';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import truncate from 'lodash/truncate';

export default function CompactProductList({
  list,
  className,
  typeSegment,
}) {
  const { push } = useRouter();
  // Redux hooks
  const dispatch = useDispatch();

  // Events
  const onCloseDrawer = () => {
    toggleBlurLayout(null, false);
    dispatch(setDrawerQuickBuyStatus({ open: false }));
  };

  return (
    <SimpleBar
      style={{ maxHeight: window.innerHeight }}
      forceVisible="y"
      autoHide={true}
    >
      <ul
        className={[
          className,
          'h-full space-y-2 pb-60 md:mt-4 md:space-y-4 lg:space-y-5',
        ].join(' ')}
      >
        {sortBy(list, 'attributes.city.data.attributes.name').map(
          ({ attributes }, i) => {
            const { hero, img, city, name } = attributes;
            const heroMedia = hero?.media?.data?.attributes;
            const defaultImage = img?.data?.attributes?.formats;
            const hasVideo = heroMedia?.mime.includes('video');

            //Utils
            const { cityPath, prodPath } = getCleanedPaths({
              city: city?.data?.attributes?.slug,
              slug: attributes.slug,
              typeSegment,
            });

            return (
              <li key={i} className="flex">
                <span
                  className={classNames(
                    'h-24 w-24 overflow-clip',
                    'aspect-square shrink-0 rounded-sm',
                    'bg-slate-100 dark:bg-zinc-800',
                  )}
                >
                  <LazyLoadImage
                    alt="img"
                    threshold={0}
                    effect="opacity"
                    src={
                      hasVideo
                        ? defaultImage?.medium?.url
                        : heroMedia?.formats?.thumbnail?.url
                    }
                    className={classNames(
                      'cursor-pointer',
                      'object-cover',
                      'saturate-75 aspect-square',
                      'h-full w-full',
                    )}
                    onClick={() => push(prodPath)}
                  />
                </span>

                <div className="flex flex-col ml-3">
                  <Link passHref href={cityPath}>
                    <a
                      className={classNames(
                        'lg:text-md flex text-sm',
                        'text-secondary',
                        'font-title font-normal',
                        'underline-offset-2 hover:underline',
                      )}
                    >
                      {capitalize(city?.data?.attributes?.name)}
                    </a>
                  </Link>

                  <Link passHref href={prodPath}>
                    <a
                      onClick={onCloseDrawer}
                      className={classNames(
                        'text-sm font-normal md:text-lg',
                        'cursor-pointer',
                        'underline-offset-4 hover:underline',
                        'transition-all duration-[300ms] ease-in-out',
                      )}
                    >
                      {truncate(name, {
                        length: 50,
                        separator: /,.:? +/,
                      })}
                    </a>
                  </Link>
                </div>
              </li>
            );
          },
        )}
      </ul>
    </SimpleBar>
  );
}
