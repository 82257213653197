import {
  AgencyService,
  AuthService,
  CartService,
  CountriesService,
  GenericService,
  OrdersService,
  PaymentService,
  SchoolService,
  ServicesService,
  SitesService,
  StrapiService,
  UserService,
  WeatherService,
  NewsletterService
} from "@services";
import { cart, museums, theme, user } from "@slices";

import { combineReducers } from "redux";

/* import {
  createStateSyncMiddleware,
  withReduxStateSync,
} from 'redux-state-sync'; */

export default combineReducers({
  user,
  theme,
  museums,
  cart,
  [SitesService.reducerPath]: SitesService.reducer,
  [StrapiService.reducerPath]: StrapiService.reducer,
  [AuthService.reducerPath]: AuthService.reducer,
  [UserService.reducerPath]: UserService.reducer,
  [CartService.reducerPath]: CartService.reducer,
  [PaymentService.reducerPath]: PaymentService.reducer,
  [ServicesService.reducerPath]: ServicesService.reducer,
  [GenericService.reducerPath]: GenericService.reducer,
  [SchoolService.reducerPath]: SchoolService.reducer,
  [AgencyService.reducerPath]: AgencyService.reducer,
  [CountriesService.reducerPath]: CountriesService.reducer,
  [OrdersService.reducerPath]: OrdersService.reducer,
  [WeatherService.reducerPath]: WeatherService.reducer,
  [NewsletterService.reducerPath]: NewsletterService.reducer,
});
