import { Header, Section } from '@components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';
import classNames from 'classnames';

export default function TalkedAboutUsLogos({ list }) {
  const { isMobile } = useMediaQueries();
  const { t } = useTranslation('common');

  if (list?.length > 0) {
    return (
      <Section id="TalkedAboutUsLogos">
        <Header
          label={t('sections.talkedAboutUs.title')}
          description={null}
          title={null}
          darkMode={true}
        />

        {isMobile && (
          <Splide
            className='select-none'
            options={{
              gap: '0.5rem',
              padding: 0,
              speed: 400,
              drag: true,
              type: 'loop',
              arrows: false,
              autoplay: true,
              pauseOnHover: false,
              pagination: true,
              perPage: 1,
            }}
          >
            {list.map((l, i) => (
              <SplideSlide key={i}>
                <LazyLoadImage
                  alt="logos"
                  effect="opacity"
                  src={l.attributes.url}
                  className={classNames(
                    'object-cover',
                    'w-2/3 h-auto mx-auto',
                    'dark:brightness-0 dark:invert',
                  )}
                />
              </SplideSlide>
            ))}
          </Splide>
        )}

        {!isMobile && (
          <div
            className={classNames(
              'grid grid-cols-2 select-none',
              'sm:grid-cols-3 md:grid-cols-4',
            )}
          >
            {list.map((l, i) => (
              <div className="p-2 mx-auto" key={i}>
                <LazyLoadImage
                  effect="opacity"
                  src={l.attributes.url}
                  className={classNames(
                    'h-auto mx-auto',
                    'lg:w-36',
                    'dark:brightness-0 dark:invert',
                  )}
                />
              </div>
            ))}
          </div>
        )}
      </Section>
    );
  }

  return null;
}
