import classNames from 'classnames';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';

export default function BannerQuiz() {
  const { push } = useRouter();
  return (
    <div
      onClick={() => push('/quizioseo')}
      className={classNames(
        'flex items-center justify-center flex-col',
        'bg-slate-50 dark:bg-zinc-800',
        'h-32 my-20 space-y-1 cursor-pointer select-none',
        'relative overflow-hidden rounded-md',
      )}
    >
      <span className="text-sm font-medium tracking-widest text-gray-600 uppercase font-condensed dark:text-primary">
        Play
      </span>
      <h3
        className={classNames(
          'relative',
          'text-2xl tracking-airy-light',
          'uppercase font-extrabold font-title',
          'text-gray-600 dark:text-white',
        )}
      >
        QUIZIOSEO
      </h3>
      <span
        className={classNames(
          'absolute top-0 left-0 w-full h-full',
          'bg-center bg-cover bg-quiz',
          'opacity-10 -z-0',
        )}
      />

      {/* <div className="grid pt-2 ">quiz + museo</div> */}

      {/* <Button size='small' fullWidth className='max-w-xs text-white bg-primary'>PLAY</Button> */}
    </div>
  );
}
