import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useMediaQueries = () => {
  // Breakpoints
  const t = useTheme();
  const isMobile = useMediaQuery(() => t.breakpoints.down('md'));
  const isTablet = useMediaQuery(() => t.breakpoints.down('lg'));
  const isDesktop = useMediaQuery(() => t.breakpoints.up('lg'));
  const isLargeScreen = useMediaQuery('(min-width:1600px)');

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeScreen,
    // Tailwind breakpoints
    sm: useMediaQuery('(min-width:640px) and (max-width: 767px)'),
    md: useMediaQuery('(min-width:768px) and (max-width: 1023px)'),
    lg: useMediaQuery(
      '(min-width: 1024px) and (max-width: 1279px)',
    ),
    xl: useMediaQuery(
      '(min-width: 1280px) and (max-width: 1535px)',
    ),
    '2xl': useMediaQuery(
      '(min-width: 1536px) and (max-width: 1599px)',
    ),
    '3xl': useMediaQuery('(min-width: 1600px)'),
  };
};

export default useMediaQueries;
