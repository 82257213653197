import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

export default function Languages({ languages }) {
  if (languages) {
    const list = languages.split(',');
    return (
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={0.5}
      >
        {list?.map((lang, i) => {
          return (
            <span
              key={i}
              className="text-xs font-medium dark:text-primary"
            >
              {lang}
            </span>
          );
        })}
      </Stack>
    );
  }

  return null;
}
