import { orange } from "@mui/material/colors";
import commonThemeValues from "./commons";

const MUI_LIGHT_THEME = {
  ...commonThemeValues,
  palette: {
    mode: "light",
    primary: {
      main: "#faaf40",
      light: "#fab753",
      dark: "#e19d39",
      constrastText: "#ffff",
    },

    secondary: {
      main: "#f9640e",
      constrastText: "#ffff",
    },

    action: {
      selected: "#faaf40",
    },
  },
};

export default MUI_LIGHT_THEME;
