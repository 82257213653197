import AltRoute from '@mui/icons-material/AltRoute';
import AssuredWorkload from '@mui/icons-material/AssuredWorkload';
import CircularProgress from '@mui/material/CircularProgress';
import CreditScore from '@mui/icons-material/CreditScore';
import MobileFriendly from '@mui/icons-material/MobileFriendly';
import Sell from '@mui/icons-material/Sell';
import Verified from '@mui/icons-material/Verified';
import sortBy from 'lodash/sortBy';
import { useGetAdvantagesQuery } from '@services';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Button from "@mui/material/Button";
//import VerifiedIcon from '@mui/icons-material/Verified';


const ICON_MAP = {
  Sell: Sell,
  Verified: Verified,
  AltRoute: AltRoute,
  CreditScore: CreditScore,
  MobileFriendly: MobileFriendly,
  AssuredWorkload: AssuredWorkload,
};

const Advantages = ({
  onOpenAddToCartDrawer,
  btnCta,
}) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const { data, isLoading, isFetching, isSuccess, isError } =
    useGetAdvantagesQuery({ locale });

  if (isLoading || isFetching) {
    return (
      <div
        className={classNames(
          'flex items-center justify-center',
          'w-full p-10',
        )}
      >
        <CircularProgress thickness={4} size={20} />
      </div>
    );
  }

  if (isSuccess && data?.length > 0) {
    return (
      <div
        className={classNames(
          'text-gray-700 dark:text-white',
          'bg-slate-100 dark:bg-zinc-800',
          'my-3 space-y-2 md:my-8 md:space-y-4',
          'p-4 md:p-8 rounded-sm',
          'border-dashed border',
          'border-slate-200 dark:border-zinc-600',
          'relative',
        )}
      >
        <h2
          className={classNames(
            'flex pb-4 font-semibold uppercase dark:text-primary',
            'border-slate-200 dark:border-zinc-600 border-b',
          )}
        >
          {t('generic.labels.purchasing_online')}
          {/* <VerifiedIcon className='mx-2 fill-green-500'/> */}
        </h2>

        <div className="flex flex-wrap">
          {sortBy(data, 'attributes.text').map(({ attributes }) => {
            const IconComponent = ICON_MAP[attributes?.icon];
            return (
              <span
                className="flex items-center w-full h-12 pl-2 space-x-4 md:w-1/2 md:pl-0"
                key={attributes?.text}
              >
                {IconComponent && <IconComponent />}
                <span className="font-medium">
                  {attributes?.text}
                </span>
              </span>
            );
          })}

          {onOpenAddToCartDrawer && btnCta &&
            <Button
              size="small"
              variant="contained"
              fullWidth
              onClick={onOpenAddToCartDrawer}
              className={classNames(
                'uppercase relative mt-8',
                'rounded-sm',
                'flex items-center justify-center',
                'w-full lg:max-w-xs',
                'bg-primary text-white',
              )}
            >
              {btnCta}
            </Button>}
        </div>
      </div>
    );
  }

  if (isError) return null;

  return null;
};

export default Advantages;
