import { hasLetterAndNumber, lettersAndSpace } from "@utils";
import { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import { CustomTextInput } from "@components";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import every from "lodash/every";
import { useTranslation } from "next-i18next";

dayjs.extend(customParseFormat);

export default function AddIdentityDocument({ types, className, onSuccess, onError, name, site, id, reductions }) {
  const [loader, setLoader] = useState(false);
  // Form Values: validazione da fare meglio appena possibile
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    document_id: "",
    product_id: { value: id, valid: true },
    document_issued_by: "",
    reduction_id: { value: "", valid: false },
    document_type: { value: "", valid: false },
    document_type_other: {
      value: null,
      valid: true,
    },
    document_expire_at: {
      value: null,
      valid: false,
    },
  });

  const [allowSubmit, setAllowSubmit] = useState(false);

  //i18n
  const { t } = useTranslation("common");

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      onSuccess && onSuccess(formValues);
    } catch (error) {
      console.error(error);
      setLoader(false);
      onError && onError();
    }
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, "valid"));
  }, [formValues]);

  return (
    <>
      <form className={["", className].join(" ")} onSubmit={onSubmit}>
        <div className="font-medium">
          <span>{site?.name}</span> - <span>{name}</span>
        </div>
        <div className="space-y-2">
          <span className="flex justify-around space-x-2">
            <CustomTextInput
              fullWidth
              required
              type="text"
              reset={true}
              margin="dense"
              name="first_name"
              label={t("forms.name")}
              variant="outlined"
              regex={hasLetterAndNumber}
              className="w-full md:w-1/2"
              helperText="Caratteri speciali non consentiti"
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  first_name: value,
                })
              }
            />

            <CustomTextInput
              fullWidth
              required
              type="text"
              reset={true}
              margin="dense"
              className="w-full md:w-1/4"
              name="last_name"
              label={t("forms.surname")}
              variant="outlined"
              regex={hasLetterAndNumber}
              helperText="Caratteri speciali non consentiti"
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  last_name: value,
                })
              }
            />
          </span>

          <FormControl fullWidth margin="dense">
            <InputLabel>{t("forms.reductions.reduction_type")}</InputLabel>
            <Select
              // className="p-0 m-0"
              name="reduction_id"
              required
              value={formValues?.reduction_id.value}
              label={t("forms.reductions.reduction_type")}
              onChange={({ target }) =>
                setFormValues({
                  ...formValues,
                  reduction_id: {
                    value: target.value,
                    valid: true,
                  },
                })
              }
            >
              {reductions &&
                reductions?.length > 0 &&
                reductions?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel>{t("forms.reductions.document_type")}</InputLabel>
            <Select
              required
              margin="dense"
              name="document_type_id"
              value={formValues?.document_type.value}
              label={t("forms.reductions.document_type")}
              onChange={({ target }) =>
                setFormValues({
                  ...formValues,
                  document_type: {
                    value: target.value,
                    valid: true,
                  },
                })
              }
            >
              {types.map(({ name, slug }, i) => (
                <MenuItem key={i} value={slug}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {formValues?.document_type?.value === "other" && (
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className=""
              name="document_type_other"
              variant="outlined"
              label={t("forms.reductions.specify_document_type")}
              regex={lettersAndSpace}
              helperText="Caratteri speciali non consentiti"
              onSetValue={(value) => {
                setFormValues({
                  ...formValues,
                  document_type_other: {
                    value,
                    valid: true,
                  },
                });
              }}
            />
          )}

          <span className="flex w-full flex-col items-center justify-center space-y-4 md:flex-row md:space-x-4 md:space-y-0">
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className=""
              name="document_id"
              variant="outlined"
              label={t("forms.reductions.document_number")}
              regex={hasLetterAndNumber}
              helperText="Caratteri speciali non consentiti"
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  document_id: value,
                })
              }
            />

            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className=""
              name="document_issued_by"
              variant="outlined"
              label={t("forms.reductions.issued_by")}
              regex={hasLetterAndNumber}
              helperText="Caratteri speciali non consentiti"
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  document_issued_by: value,
                })
              }
            />
          </span>

          <span className="flex">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                views={["year"]}
                label={t("forms.reductions.document_expiration")}
                disablePast
                // inputFormat="yyyy"
                value={formValues.document_expire_at?.value}
                onChange={(day) => {
                  if (dayjs(day).isValid()) {
                    setFormValues({
                      ...formValues,
                      document_expire_at: {
                        value: day,
                        valid: true,
                      },
                    });
                  }
                }}
                renderInput={(params) => <TextField fullWidth required {...params} />}
              />
            </LocalizationProvider>
          </span>
        </div>

        <span className="mt-4 flex w-full">
          {loader ? (
            <LoadingButton loading className="h-10 w-full rounded-none bg-primary text-white transition delay-150 ease-in-out" />
          ) : (
            <Button
              type="submit"
              color="primary"
              onClick={onSubmit}
              disabled={!allowSubmit}
              className={classNames("h-10 w-full rounded-none bg-primary text-white transition delay-150 ease-in-out", {
                "cursor-not-allowed opacity-50": !allowSubmit,
              })}
            >
              {t("buttons.send")}
            </Button>
          )}
        </span>
      </form>
    </>
  );
}
