import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Login from '@mui/icons-material/Login';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonAddAlt1 from '@mui/icons-material/PersonAddAlt1';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

export default function SignButton({ onSigIn }) {
  // Next hooks
  const { push } = useRouter();
  const { isTablet } = useMediaQueries();
  // State
  const [anchorEl, setAnchorEl] = useState(null);
  // Utils
  const open = Boolean(anchorEl);
  // Events
  const onCloseMenu = () => setAnchorEl(null);
  const onOpenMenu = (e) => setAnchorEl(e.currentTarget);
  const onClickRegistrationBtn = () => push('/user/registration');
  // i18s
  const { t } = useTranslation('common');

  return (
    <div className="flex md:mr-4">
      {isTablet ? (
        [
          <IconButton
            size="small"
            key={1}
            aria-haspopup="true"
            onClick={onOpenMenu}
            className="w-12 centered"
            aria-expanded={open ? 'true' : undefined}
            aria-controls={open ? 'account-menu' : undefined}
          >
            <ManageAccounts className="w-12 centered fill-gray-700 dark:fill-white" />
          </IconButton>,
          <Menu
            key={2}
            open={open}
            id="account-menu"
            anchorEl={anchorEl}
            onClose={onCloseMenu}
            onClick={onCloseMenu}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            /* PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 0.5,
                '& .MuiAvatar-root': {
                  width: 28,
                  height: 28,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }} */
          >
            {[
              {
                label: t('navbar.login'),
                Icon: Login,
                onClick: onSigIn,
              },
              {
                label: t('navbar.signup'),
                Icon: PersonAddAlt1,
                onClick: onClickRegistrationBtn,
              },
            ].map(({ onClick, Icon, label }, i) => (
              <MenuItem onClick={onClick} key={label}>
                <ListItemIcon
                  key={i}
                  id={
                    label === t('navbar.login') ? 'btn-login' : null
                  }
                >
                  <Icon fontSize="small" />
                </ListItemIcon>
                {label}
              </MenuItem>
            ))}
          </Menu>,
        ]
      ) : (
        <Button
          key={3}
          size="small"
          id="btn-login"
          onClick={onSigIn}
          className="max-w-sm text-xs text-white rounded-sm w-22 bg-primary"
        >
          {t('navbar.login')}
        </Button>
      )}
    </div>
  );
}
