import { useGetTicketsByDateAndHourQuery } from '@services';
import { useRouter } from 'next/router';
import find from 'lodash/find';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';

export default function OfferPackage({
  slug,
  hour,
  date,
  ticketMimsId,
  quantity = 2,
  site,
  unformattedDate,
  ...all
}) {
  const { locale } = useRouter();

  const { data, isSuccess, isError, isLoading } =
    useGetTicketsByDateAndHourQuery({
      slug,
      hour,
      date,
      locale,
    });

  const product =
    isSuccess && find(data, { id: Number(ticketMimsId) });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-20 p-10 md:h-40">
        <CircularProgress thickness={4} size={20} />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center w-full p-10">
        <code>{JSON.stringify({ isError })}</code>
      </div>
    );
  }

  return (
    <section className="flex flex-col w-full my-8 space-y-6 text-gray-700 dark:text-white">
      <header>
        <span className="font-primary">{site?.city}</span>
        <h1 className="text-3xl font-bold font-title">
          {site?.name}
        </h1>
        <h2 className="text-xl font-medium font-title">
          {site?.hero?.subtitle}
        </h2>
        <address>{site?.address}</address>
        <p className="max-w-lg pt-2">{site?.intro_text}</p>
      </header>

      <span className='font-medium'>
        {dayjs(unformattedDate).format('DD MMMM YYYY')} alle {hour}
      </span>

      <ul className="space-y-2">
        <li>
          {quantity} x {product?.name}
        </li>
        <li className="flex items-baseline space-x-2">
          <span className="font-bold font-title">TOTALE</span>:
          <span className="text-2xl px-0.5">
            {product?.price_sale * quantity}
          </span>{' '}
          euro
        </li>
      </ul>
    </section>
  );
}
