import { CustomTextInput, HandleFormResponse } from '@components';
import {
  hasLetterAndNumber,
  validEmail,
  validPassword,
} from '@utils';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from 'next/link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';
import every from 'lodash/every';
import { useRegisterUserMutation } from '@services';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export default function RegistrationPrivate({
  className,
  onSuccess,
  onError,
}) {
  const [registerUser, { data, isSuccess, isError, isLoading }] =
    useRegisterUserMutation();
  const { push, locale } = useRouter();
  const [loader, setLoader] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    language_code: { value: locale, valid: true },
    newsletter: { value: null, valid: true },
    profilation: { value: 0, valid: true },
    anonymization: { value: 0, valid: true },
    privacy: { value: 0, valid: false },
  });

  const [allowSubmit, setAllowSubmit] = useState(false);

  // i18n
  const { t } = useTranslation('common');

  // Events
  const onSubmit = async (formValues) => {
    try {
      if (formValues) {
        setLoader(true);
        const { data } = await registerUser(formValues);

        if (data?.success) {
          push('/user/thank-you');
          onSuccess && onSuccess();
        }
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      onError && onError();
    }
  };

  const onShowCaptcha = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setShowCaptcha(true);
    } catch (error) {
      console.error('onShowCaptcha', error);
    }
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, 'valid'));
  }, [formValues]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      language_code: { value: locale, valid: true },
    });
  }, [locale]);

  return (
    <>
      <HandleFormResponse
        data={data}
        className="mt-8"
        isError={isError}
        isSuccess={isSuccess}
      />

      {(!isSuccess || isError) && showCaptcha && (
        <ReCAPTCHA
          hl={locale}
          className="my-6"
          onChange={async (e) => {
            await onSubmit({
              ...formValues,
              'g-recaptcha-response': e,
              domain: window.location.hostname,
            });
          }}
          sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA}
        />
      )}

      {!showCaptcha && (
        <form className={['space-y-6', className].join(' ')}>
          <CustomTextInput
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="first_name"
            label={t('forms.name')}
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                first_name: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="last_name"
            label={t('forms.surname')}
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                last_name: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="email"
            reset={true}
            maxLength={60}
            margin="dense"
            className="my-1"
            name="email"
            label={t('forms.email')}
            variant="outlined"
            regex={validEmail}
            //helperText={t("forms.validations.no_special_characters")}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                email: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            className="my-1"
            type="password"
            name="password"
            label={t('forms.password')}
            variant="outlined"
            regex={validPassword}
            autoComplete="new-password"
            helperText={t('forms.validations.password_rules')}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                password: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            className="my-1"
            type="password"
            name="password"
            label={t('forms.confirm_password')}
            autoComplete="new-password"
            variant="outlined"
            regex={validPassword}
            helperText={t('forms.validations.password_must_match')}
            customCondition={(x, y) => x === y}
            toCompare={formValues?.password}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                password_confirmation: value,
              })
            }
          />

          <FormControl className="px-2">
            <FormControlLabel
              label={
                <span className="text-xs md:text-sm">
                  {t('forms.accept_privacy')}
                  <a
                    className="mx-1 font-bold underline underline-offset-4"
                    href="/documents/M-GDPR-30-informativa-sito-internet.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              }
              control={
                <Checkbox
                  checked={Boolean(formValues.privacy.value)}
                  onChange={(e) => {
                    const val = e.target.checked;
                    setFormValues({
                      ...formValues,
                      privacy: {
                        value: +val,
                        valid: val,
                      },
                    });
                  }}
                />
              }
            />
          </FormControl>

          <FormControl className="px-2">
            <span className="text-xs md:text-sm">
              {t('forms.private.newsletter.text')}
            </span>

            <RadioGroup
              row
              className="my-2"
              name="policy-newsletter-group"
              aria-labelledby="policy-newsletter"
            >
              <FormControlLabel
                label={t('forms.yes')}
                control={
                  <Radio
                    size="small"
                    value={+true}
                    checked={Boolean(
                      formValues.newsletter.value === 1,
                    )}
                    onChange={(e) => {
                      const val = e.target.checked;
                      setFormValues({
                        ...formValues,
                        newsletter: {
                          value: 1,
                          valid: val,
                        },
                      });
                    }}
                  />
                }
              />
              <FormControlLabel
                label={t('forms.no')}
                control={
                  <Radio
                    size="small"
                    value={+false}
                    checked={Boolean(
                      formValues.newsletter.value === 0,
                    )}
                    onChange={(e) => {
                      const val = e.target.checked;
                      setFormValues({
                        ...formValues,
                        newsletter: {
                          value: 0,
                          valid: val,
                        },
                      });
                    }}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
          {formValues.newsletter.value === 0 && (
            <FormControl className="px-2 align-top">
              <FormControlLabel
                label={
                  <span className="text-xs md:text-sm">
                    {t('forms.private.anonymization.text')}
                  </span>
                }
                control={
                  <Checkbox
                    checked={Boolean(
                      formValues.anonymization.value,
                    )}
                    onChange={(e) => {
                      const val = e.target.checked;
                      setFormValues({
                        ...formValues,
                        anonymization: {
                          value: +val,
                          valid: val,
                        },
                      });
                    }}
                  />
                }
              />
            </FormControl>
          )}

          <Button
            type="submit"
            color="primary"
            onClick={onShowCaptcha}
            disabled={!allowSubmit}
            className={classNames(
              'h-10 w-full rounded-none bg-primary text-white transition delay-150 ease-in-out',
              {
                'cursor-not-allowed opacity-50': !allowSubmit,
              },
            )}
          >
            {t('buttons.sign_up')}
          </Button>

          <span className="flex justify-center text-sm">
            {t('forms.already_registered')}
            <Link href="/user/login" passHref>
              <a className="mx-1 text-primary">
                {t('buttons.login')}
              </a>
            </Link>
          </span>
        </form>
      )}
    </>
  );
}
