import { Header, Section } from '@components';
import { userSelector } from '@slices';
import { useSelector } from 'react-redux';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Link from 'next/link';
import capitalize from 'lodash/capitalize';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getCleanedPaths } from '@utils';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import truncate from 'lodash/truncate';

dayjs.extend(relativeTime);

export default function ProductsReminder() {
  const { productsReminder } = useSelector(userSelector);
  const { push } = useRouter();

  // i18n
  const { t } = useTranslation('common');

  return (
    productsReminder?.length > 0 && (
      <Section id="ProductsReminder">
        <Header
          darkMode={true}
          description={null}
          label={t('sections.last_see.label')}
          title={t('sections.last_see.title')}
        />

        <div
          className={classNames(
            'mx-auto w-full space-y-4',
            'flex flex-col items-center justify-center',
            //'md:p-4 lg:w-6/12 xl:w-8/12 2xl:w-full',
            //"2xl:flex-row 2xl:space-x-4 2xl:space-y-0",
          )}
        >
          {productsReminder.map((p, i) => {
            const { city, slug, media, name, date, segment } = p;

            //Utils
            const { cityPath, prodPath } = getCleanedPaths({
              city: city?.data?.attributes?.slug || city,
              slug: slug,
              typeSegment: '/' + segment + '/',
            });

            return (
              <div
                key={i}
                className={classNames(
                  'group',
                  'flex flex-row',
                  'h-24 w-full lg:w-6/12 xl:w-4/12',
                  'bg-slate-100 dark:bg-zinc-800',
                  'relative overflow-hidden rounded-md',
                )}
              >
                <div className="relative flex justify-center flex-shrink-0 w-1/4 h-auto overflow-hidden">
                  <Link href={prodPath}>
                    <LazyLoadImage
                      alt="img"
                      threshold={0}
                      effect="opacity"
                      src={
                        media?.formats?.thumbnail?.url ||
                        media?.formats?.small?.url
                      }
                      onClick={() => push(prodPath)}
                      className={classNames(
                        'h-full w-full',
                        'absolute bottom-0 left-0 right-0 top-0',
                        'transition-all duration-[900ms] ease-in-out',
                        'group-hover:scale-110 group-hover:saturate-100',
                        'saturate-75 cursor-pointer rounded-sm object-cover object-center',
                      )}
                    />
                  </Link>
                </div>

                <div className="relative flex flex-col items-start w-full p-3">
                  {cityPath && (
                    <Link passHref href={cityPath}>
                      <a
                        className={classNames(
                          'absolute left-4 top-2 flex max-w-sm',
                          'text-sm font-medium text-secondary',
                          'underline-offset-2 hover:underline md:text-sm',
                        )}
                      >
                        {capitalize(
                          city?.data?.attributes?.name || city,
                        )}
                      </a>
                    </Link>
                  )}

                  <span className="absolute text-xs opacity-50 right-4 top-2 dark:text-white xl:text-sm">
                    {dayjs(date).fromNow()}
                  </span>

                  {prodPath && (
                    <Link passHref href={prodPath}>
                      <a
                        className={classNames(
                          'absolute left-4 top-1/2 ',
                          'text-sm font-medium',
                          'underline-offset-4 hover:underline',
                          'flex w-10/12 -translate-y-1/3',
                          'dark:text-white md:text-sm xl:text-base',
                        )}
                      >
                        {truncate(name, {
                          length: 50,
                          separator: /,.:? +/,
                        })}
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Section>
    )
  );
}
