import CircularProgress from "@mui/material/CircularProgress";
import { DefaultGallery } from "@components";
import sortBy from "lodash/sortBy";
import { useGetStrapiItinerantsQuery } from "@services";
import { useRouter } from "next/router";

export default function ItinerantsGallery({ title, label, description, query = null }) {
  const { push, locale } = useRouter();

  const {
    data: itinerants,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetStrapiItinerantsQuery({
    query,
    locale,
  });

  const onClickTitle = () => push("/esperienze/tour-visite-guidate");

  return (
    <DefaultGallery
      type="tour"
      isLoading={isLoading || isFetching}
      title={title}
      label={label}
      options={{
        arrow: false,
        perPage: itinerants?.data?.length === 1 ? 1 : 3,
      }}
      description={description}
      onClickTitle={onClickTitle}
      pathTo="tour-visite-guidate"
      items={sortBy(itinerants?.data, "attributes.city.data.attributes.name")}
    />
  );
}
