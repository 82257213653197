import { Header } from '@components';

export default function Dizioseo({ list, description }) {
  if (list?.length === 0) return null;

  return (
    list?.length > 0 && (
      <div className="pt-16 space-y-8">
        <Header title="Dizioseo" label={description} />

        <ul className="mx-auto space-y-10">
          {list?.map(({ name, text }, i) => {
            return (
              <li
                key={i}
                className="flex flex-col items-center justify-center w-full mx-auto space-y-3"
              >
                <span className="text-lg italic font-extrabold tracking-widest">
                  {name}
                </span>
                <p className="max-w-xl text-center opacity-80">
                  {text}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
}
