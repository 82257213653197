import { DarkLayer } from '@cardElements';
import { useState } from 'react';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from '@mui/material/Skeleton';
import { useStrapiGetImgFormats } from '@hooks';
import Link from 'next/link';
import truncate from 'lodash/truncate';
import { useRouter } from 'next/router';

export default function DestinationCardDetails({
  name,
  slug,
  abstract,
  img,
}) {
  const { locale } = useRouter();
  const [loading, setLoading] = useState(true);
  const onLoadMedia = () => setLoading(false);
  const { responsiveSrc, small } = useStrapiGetImgFormats({ img });

  return (
    <div
      className={classNames(
        'group relative',
        'h-110 p-4 md:p-6 xl:p-8 z-20',
        'overflow-hidden rounded-md',
        'flex flex-col justify-end',
        'bg-slate-100 dark:bg-zinc-800',
      )}
    >
      <div className="absolute z-10 space-y-2 text-center transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
        <span className="text-sm font-medium tracking-widest text-center text-white uppercase font-condensed">
          {locale === 'it' ? 'Visita' : 'Visit'}
        </span>
        <Link
          passHref
          href={{
            pathname: '/esperienze/[city]',
            query: { city: slug },
          }}
        >
          <a
            className={classNames(
              'font-[900] ',
              'tracking-wider',
              'underline-offset-4 hover:underline',
              'mb-0 flex max-w-xs cursor-pointer',
              'text-center font-title text-3xl uppercase text-white',
            )}
          >
            {name}
          </a>
        </Link>
      </div>

      {abstract && (
        <p
          className={classNames(
            'flex text-white flex-col z-50',
            'h-0 overflow-hidden',
            'text-sm leading-0',
            'group-hover:h-20',
            'transform-gpu',
            'transition-all duration-300 ease-in-out',
          )}
        >
          {truncate(abstract, { length: 180, separator: /,.:? +/ })}
        </p>
      )}

      <DarkLayer />

      {loading && (
        <Skeleton
          animation="wave"
          height={'100%'}
          width={'100%'}
          variant="rectangular"
          className="absolute inset-0 z-50 w-full h-full"
        />
      )}

      <LazyLoadImage
        alt={
          img?.data?.attributes?.caption ||
          img?.data?.attributes?.alternativeText
        }
        effect="opacity"
        afterLoad={onLoadMedia}
        src={responsiveSrc}
        width={small?.width}
        height="auto"
        className={classNames(
          'absolute',
          'inset-0 z-0 h-full w-full',
          'object-cover object-center transform-gpu',
          'group-hover:scale-110 md:group-hover:brightness-50',
          'brightness-50 md:brightness-90 transition-all duration-300 ease-in-out',
        )}
      />
    </div>
  );
}
