import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
import { useGetWeatherQuery } from '@services';
import CircularProgress from '@mui/material/CircularProgress';

export default function Weather({ lat, lng }) {
  const { data, isLoading, isFetching, isSuccess, isError } =
    useGetWeatherQuery({ lat, lng });

  if (isLoading || isFetching) {
    return (
      <div className="h-32 centered">
        <CircularProgress thickness={4} size={20} />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <ul className="w-full max-w-xl pl-4 space-y-2 text-gray-700 dark:text-white">
        {data?.forecast?.forecastday?.map(({ date, day }, i) => {
          return (
            <li
              key={i}
              className="flex items-center justify-between w-full"
            >
              <span className="font-semibold capitalize">
                {dayjs(date).format('ddd, DD MMM')}
              </span>
              <div className="flex space-x-6">
                <span className="flex-shrink-0 space-x-2 basis-5">
                  <span>{day?.mintemp_c}°</span>
                  <span>/</span>
                  <span>{day?.maxtemp_c}°</span>
                </span>
              </div>
              <LazyLoadImage
                className="flex w-10 h-auto"
                alt={day.condition?.text}
                src={day.condition?.icon}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  return null;
}
