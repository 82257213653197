import Link from 'next/link';
import classNames from 'classnames';
import truncate from 'lodash/truncate';

export default function Title({ href, title,className }) {
  return href && title ? (
    <Link passHref href={href ?? '/'}>
      <a
        className={classNames(
          'text-white',
          'mb-0 max-w-xs',
          'underline-offset-4 hover:underline',
          'font-title text-xl font-[900] uppercase',
          className,
        )}
      >
        {truncate(title, { length: 45, separator: /,.:? +/ })}
      </a>
    </Link>
  ) : (
    <h3
      className={classNames(
        'text-white',
        'mb-0 max-w-xs',
        'font-title text-xl font-[900] uppercase',
      )}
    >
      {title}
    </h3>
  );
}
