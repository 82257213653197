import AccessibleIcon from '@mui/icons-material/Accessible';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import InfoIcon from '@mui/icons-material/Info';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PetsIcon from '@mui/icons-material/Pets';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import facilities from './facilities.data.js';
import isEqual from 'lodash/isEqual';

import {
  SubIcon,
  DogLeashIcon,
  DogGuideIcon,
  PetTherapyIcon,
  DogCarrierIcon,
} from '@icons';

export default function Facilities({ list }) {
  const iconClasses =
    'w-4 h-auto fill-gray-700 dark:fill-white text-gray-700 dark:text-white';

  const facilityIconMap = {
    audio: (
      <HeadphonesIcon className={iconClasses} fontSize="small" />
    ),
    machine: (
      <LocalDrinkIcon className={iconClasses} fontSize="small" />
    ),
    info: <InfoIcon className={iconClasses} fontSize="small" />,
    disable: (
      <AccessibleIcon className={iconClasses} fontSize="small" />
    ),
    caffe: (
      <LocalCafeIcon className={iconClasses} fontSize="small" />
    ),
    visit: (
      <FollowTheSignsIcon
        className={iconClasses}
        fontSize="small"
      />
    ),
    bookshop: (
      <MenuBookIcon className={iconClasses} fontSize="small" />
    ),
    animals: <PetsIcon className={iconClasses} fontSize="small" />,
    restaurant: (
      <RestaurantIcon className={iconClasses} fontSize="small" />
    ),
    family: (
      <FamilyRestroomIcon
        className={iconClasses}
        fontSize="small"
      />
    ),
    school: <SchoolIcon className={iconClasses} fontSize="small" />,
    official: (
      <AssuredWorkloadIcon
        className={iconClasses}
        fontSize="small"
      />
    ),
    groups: <GroupsIcon className={iconClasses} fontSize="small" />,
    single: <PersonIcon className={iconClasses} fontSize="small" />,
    sub: <SubIcon className={iconClasses} />,
    dogGuide: <DogGuideIcon className={iconClasses} />,
    dogLeash: (
      <DogLeashIcon className="w-3.5 h-auto text-gray-700 dogLeash fill-gray-700 dark:fill-white dark:text-white" />
    ),
    dogCarrier: (
      <DogCarrierIcon className="w-6 h-6 text-gray-700 dogCarrier fill-gray-700 dark:fill-white dark:text-white" />
    ),
    petTherapy: <PetTherapyIcon className={iconClasses} />,
  };

  return (
    list?.length > 0 && (
      <Stack
        direction="row"
        className='items-center'
        divider={<Divider orientation="vertical" flexItem />}
        spacing={0.5}
      >
        {list?.map(({ attributes: a }, i) => {
          const toCheck = a?.name?.toLowerCase().trim();
          const uppercaseName = a?.name.toUpperCase();
          const index = i + new Date().getTime();

          for (let [key, value] of Object.entries(facilities)) {
            if (
              facilities[key].some((l) => {
                return isEqual(l.toLowerCase().trim(), toCheck);
              })
            ) {
              return (
                <Tooltip
                  arrow
                  title={uppercaseName}
                  key={uppercaseName}
                >
                  {facilityIconMap[key]}
                </Tooltip>
              );
            }
          }
        })}
      </Stack>
    )
  );
}
