import Facilities from './Sections/Facilities/Facilities';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getCleanedPaths } from '@utils';
import Abstract from './Sections/Abstract/Abstract';
import CompactCardContainer from './Sections/Container/CompactCardContainer';
import Paths from './Sections/Paths/Paths';
import { useStrapiGetImgFormats } from '@hooks';
import CityLink from './Sections/Links/City';
import ProductLink from './Sections/Links/Product';
import Languages from './Sections/Languages/Languages';

export default function ItinerantCompactCard({ product, loading }) {
  const {
    slug,
    city,
    hero,
    abstract,
    facilities,
    paths,
    languages,
    type
  } = product;

  const { small } = useStrapiGetImgFormats({
    img: hero?.media?.data?.attributes,
  });

  //Utils
  const { cityPath, prodPath } = getCleanedPaths({
    slug,
    city: city?.data?.attributes?.slug,
    typeSegment: '/tour-visite-guidate/',
  });

  // Utils
  const facilitiesList = facilities?.list?.data || [];
  const hasFacilities = facilitiesList?.length > 0;
  const hasPaths = paths?.list?.length > 0;
  const hasDurations = product?.duration?.length > 0;

  return (
    <CompactCardContainer
      img={small?.url}
      loading={loading}
      prodPath={prodPath}
      type={type?.data?.attributes?.name}
    >
      <CityLink
        href={cityPath}
        name={product?.city?.data?.attributes?.name}
      />

      <ProductLink href={prodPath} name={hero?.title} />
      {!hasPaths && <Abstract text={abstract} />}
      {hasPaths && <Paths list={paths?.list} />}

      {(hasDurations || hasFacilities) && (
        <div className="absolute items-center hidden space-x-4 text-sm bottom-5 md:flex">
          <div className="flex items-center space-x-2">
            <AccessTimeIcon
              fontSize="small"
              className="opacity-80"
            />
            <span>{product?.duration?.[0]?.text}</span>
          </div>

          <Facilities list={facilitiesList} />
        </div>
      )}

      {languages && (
        <div className="absolute items-center hidden space-x-4 text-sm opacity-80 right-5 bottom-5 md:flex">
          <Languages languages={languages} />
        </div>
      )}
    </CompactCardContainer>
  );
}
