import classNames from 'classnames';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function Description({
  bio,
  expand,
  onClick,
  link,
}) {
  return (
    <div
      className={classNames(
        'transform-gpu opacity-70',
        'absolute bottom-0 left-0 right-0',
        'w-full overflow-hidden z-10',
        'bg-slate-100 dark:bg-zinc-800',
        'transition-all duration-200 ease-in-out delay-400',
        { 'h-110': expand, 'h-0': !expand },
      )}
    >
      <p
        className={classNames(
          'text-sm',
          'p-4',
          'transition-opacity duration-200 ease-in-out delay-200',
          { 'opacity-100': expand },
        )}
      >
        {bio}
      </p>

      <div
        className={classNames(
          'absolute bottom-0 left-0 right-0',
          'flex flex-row items-center opacity-0',
          'w-full p-6',
          'transition-opacity duration-200 ease-in-out delay-200',
          {
            'justify-between': link != null,
            'justify-end': link === null,
            'opacity-100': expand,
          },
        )}
      >
        {link && (
          <a href={link} target="_blank" className="flex">
            <OpenInNewIcon
              fontSize="small"
              className={classNames('', {})}
            />
          </a>
        )}

        <button
          onClick={onClick}
          className={classNames('text-sm font-medium text-inherit')}
        >
          Close
        </button>
      </div>
    </div>
  );
}
