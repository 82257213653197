import { cartSelector, userSelector } from '@slices';
import {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import EuroIcon from '@mui/icons-material/Euro';
import { QuantitySelector } from '@components';
import SimpleBar from 'simplebar-react';
import isEqual from 'lodash/isEqual';
import { useGetCartQuery } from '@services';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { Box, Tab, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const Tickets = forwardRef(
  (
    {
      tickets,
      onChange,
      fullPrice,
      currentCart,
      defaultSlot = null,
      onLimitReachedCallback,
      setMaxQuantityAllowed,
      isTicketsLoading,
      isTicketsSuccess,
      date,
      hour,
      resetInnerCart = null,
      showTotal = true,
      itemClassNames,
      className,
    },
    ref,
  ) => {
    const { t } = useTranslation('common');
    const [cart, setCart] = useState([...currentCart]);
    const [activeTab, setActiveTab] = useState(null);
    const theme = useTheme();
    // Redux hooks
    const clientCart = useSelector(cartSelector);
    const user = useSelector(userSelector);
    // Redux Rtk
    const {
      data: serverCart,
      isLoading: isServerCartLoading,
      isSuccess: isServerCartSuccess,
    } = useGetCartQuery({}, { skip: !user?.logged });

    // Update cart
    useEffect(() => {
      if (!isEqual(currentCart, cart)) {
        onChange(cart);
      }
    }, [cart, currentCart]);

    const groupTicketsBy = (tickets) => {
      try {
        const groupByCard = tickets?.some(({ isCard }) =>
          Boolean(isCard),
        );

        const groupByCategory = tickets.some(
          ({ name }) =>
            name.toLowerCase().includes('audioguide') ||
            name.toLowerCase().includes('audioguida'),
        );
        const groupByLangs = tickets?.some(
          ({ name }) =>
            name.includes('(IT)') || name.includes('(EN)'),
        );
        const groupByPaths = tickets?.some(
          ({ product_subcategory }) => product_subcategory,
        );

        if (groupByCategory) {
          return [
            {
              label: `${t(
                'drawers.book_product.steps.see_also.audioguide',
              )}`,
              list: tickets.filter(
                ({ name }) =>
                  name.toLowerCase().includes('audioguide') ||
                  name.toLowerCase().includes('audioguida'),
              ),
            },
            {
              label: `${t(
                'drawers.book_product.steps.see_also.tour',
              )} - ${t(
                'drawers.book_product.steps.see_also.onlyIT',
              )}`,

              list: tickets.filter(
                ({ name }) =>
                  !name?.toLowerCase()?.includes('audioguide') &&
                  name.includes('(IT)'),
              ),
            },
            {
              label: `${t(
                'drawers.book_product.steps.see_also.tour',
              )} - ${t(
                'drawers.book_product.steps.see_also.onlyEN',
              )}`,
              list: tickets.filter(
                ({ name }) =>
                  !name?.toLowerCase()?.includes('audioguide') &&
                  name?.includes('(EN)'),
              ),
            },
          ];
        }
        if (groupByCard) {
          return [
            {
              label: 'tickets',
              list: tickets.filter((t) => !Boolean(t?.isCard)),
            },
            {
              label: 'CARD',
              list: tickets.filter((t) => Boolean(t?.isCard)),
            },
          ];
        }
        if (groupByLangs) {
          return [
            {
              label: t(
                'drawers.book_product.steps.see_also.onlyIT',
              ),
              list: tickets.filter(({ name }) =>
                name.includes('(IT)'),
              ),
            },
            {
              label: t(
                'drawers.book_product.steps.see_also.onlyEN',
              ),
              list: tickets.filter(({ name }) =>
                name.includes('(EN)'),
              ),
            },
          ];
        }

        return [
          {
            label: 'tickets',
            list: tickets,
          },
        ];
      } catch (error) {
        console.error('groupTicketsBy', error);
        return [
          {
            label: 'tickets',
            list: tickets,
          },
        ];
      }
    };

    useEffect(() => {
      if (resetInnerCart !== null) setCart([]);
    }, [resetInnerCart]);

    useImperativeHandle(ref, () => ({
      resetState: () => {
        setCart([]);

        // console.log('reset card');
      },
    }));
    useEffect(() => {
      if (tickets?.length > 0) {
        const _activeTab =
          groupTicketsBy(tickets)[0].label.toLowerCase();
        setActiveTab(_activeTab);
      }
    }, [tickets]);
    return (
      <>
        {/* Show loader during user cart get in order to merge current order to previous one */}
        {(isTicketsLoading || isServerCartLoading) && (
          <div
            className={classNames(
              'absolute left-1/2 top-1/2',
              '-translate-x-1/2 -translate-y-1/2 transform',
              'bg-zinc-100 dark:bg-zinc-800',
              'w-11/12 z-[999]',
              'flex items-center justify-center',
            )}
          >
            <CircularProgress size={20} />
          </div>
        )}

        {!isTicketsLoading &&
          isTicketsSuccess &&
          tickets?.length > 0 && (
            <Box>
              <TabContext value={activeTab}>
                <TabList
                  variant={
                    groupTicketsBy(tickets).length > 2
                      ? 'scrollable'
                      : 'standard'
                  }
                  onChange={(e, newValue) => setActiveTab(newValue)}
                >
                  {groupTicketsBy(tickets).map(({ label }, i) => {
                    const tabValue =
                      label?.toLowerCase() || 'tickets';
                    return (
                      <Tab
                        key={i}
                        value={tabValue}
                        label={tabValue}
                        sx={{
                          minWidth: '50% !important',
                        }}
                      />
                    );
                  })}
                </TabList>
                {groupTicketsBy(tickets)?.map(
                  ({ label, list }, i) => {
                    const panelValue =
                      label?.toLowerCase() || 'tickets';

                    return (
                      <TabPanel
                        key={i}
                        value={panelValue}
                        sx={{
                          padding: '32px',
                          [theme.breakpoints.down('sm')]: {
                            padding: '8px 0px',
                            height: '100%',
                            maxHeight: '65vh',
                            overflowY: 'auto',
                          },
                        }}
                      >
                        <ul
                          className={classNames(
                            'flex h-full w-full flex-col',
                            'text-gray-700 dark:text-white',
                            'space-y-3',
                          )}
                        >
                          {list?.map(
                            (
                              {
                                id,
                                name,
                                price_sale,
                                document_required,
                                service,
                                product_subcategory,
                                min_pax,
                              },
                              i,
                            ) => {
                              const { site } = service;
                              let currentProduct = currentCart.find(
                                (c) => c.id === id,
                              );
                              const hasQuantity = currentCart.find(
                                (t) => t.id === id,
                              )?.qty;
                              const clientQuantity =
                                clientCart?.products.filter(
                                  ({ product }) =>
                                    product?.id === id,
                                );
                              const serverQuantity =
                                isServerCartSuccess
                                  ? serverCart?.products?.filter(
                                      ({ product }) =>
                                        product?.id === id,
                                    )
                                  : [];
                              const tot =
                                clientQuantity.length +
                                (hasQuantity || 0) +
                                (serverQuantity?.length || 0);
                              let limit =
                                service?.slots === false
                                  ? service?.category === 'service'
                                    ? 20
                                    : 6
                                  : service?.slot_availability;

                              if (
                                service.category === 'audioguide' ||
                                name
                                  ?.toLowerCase()
                                  .includes('audioguide')
                              ) {
                                limit = 20;
                              }
                              setMaxQuantityAllowed &&
                                setMaxQuantityAllowed(limit);

                              return (
                                <li
                                  key={id}
                                  className={classNames(
                                    itemClassNames,
                                    'flex flex-col md:flex-row',
                                    'md:items-center md:justify-between',
                                  )}
                                >
                                  <div
                                    className={classNames(
                                      'flex items-center',
                                      'md:justify-start',
                                      'md:items-center',
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        'px-4 text-lg font-medium',
                                        'w-18 2xl:font-semibold',
                                      )}
                                    >
                                      {price_sale}€
                                    </span>
                                    <span
                                      className={classNames(
                                        'flex flex-col',
                                      )}
                                    >
                                      {product_subcategory && (
                                        <span className="pt-1 uppercase text-sm font-medium dark:text-primary">
                                          {product_subcategory}
                                        </span>
                                      )}
                                      <span
                                        className={classNames({
                                          'uppercase text-sm font-semibold':
                                            !product_subcategory,
                                          'text-xs':
                                            product_subcategory,
                                        })}
                                      >
                                        {name
                                          .replace('(IT)', '')
                                          .replace('(EN)', '')}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="flex flex-col md:justify-end">
                                    <QuantitySelector
                                      key={id}
                                      date={date}
                                      hour={hour}
                                      minPax={min_pax}
                                      currentProduct={
                                        currentProduct
                                      }
                                      alert={Boolean(
                                        serverQuantity?.length +
                                          clientQuantity?.length,
                                      )}
                                      minAllowed={
                                        user?.logged
                                          ? serverQuantity?.length
                                          : clientQuantity?.length
                                      }
                                      quantity={tot || 0}
                                      onClickSlot={(slot) => {
                                        if (currentProduct) {
                                          currentProduct = {
                                            ...currentProduct,
                                            hour_service: slot,
                                          };
                                          setCart([
                                            currentProduct,
                                            ...currentCart.filter(
                                              (c) => c.id !== id,
                                            ),
                                          ]);
                                        }
                                      }}
                                      slots={service?.slots}
                                      limitReached={tot === limit}
                                      onAddCallback={() => {
                                        if (tot < limit) {
                                          if (currentProduct) {
                                            let { qty } =
                                              currentProduct;
                                            currentProduct = {
                                              ...currentProduct,
                                              qty: ++qty,
                                              ...(defaultSlot
                                                ? {
                                                    hour_service:
                                                      defaultSlot,
                                                  }
                                                : {}),
                                            };
                                            setCart([
                                              currentProduct,
                                              ...currentCart.filter(
                                                (c) => c.id !== id,
                                              ),
                                            ]);
                                          } else {
                                            setCart([
                                              {
                                                id,
                                                name,
                                                qty: 1,
                                                price_sale,
                                                document_required,
                                                in_concession: site
                                                  ? Boolean(
                                                      site?.in_concession,
                                                    )
                                                  : true,
                                                product_category:
                                                  service?.category,
                                                product_name: name,
                                                product_price:
                                                  price_sale,
                                                ...(defaultSlot
                                                  ? {
                                                      hour_service:
                                                        defaultSlot,
                                                    }
                                                  : {}),
                                              },
                                              ...currentCart,
                                            ]);
                                          }
                                        } else {
                                          onLimitReachedCallback &&
                                            onLimitReachedCallback();
                                        }
                                      }}
                                      onRemoveCallback={() => {
                                        if (currentProduct) {
                                          if (tot > 1) {
                                            let { qty } =
                                              currentProduct;
                                            currentProduct = {
                                              ...currentProduct,
                                              qty: --qty,
                                            };
                                            setCart([
                                              currentProduct,
                                              ...currentCart.filter(
                                                (c) => c.id !== id,
                                              ),
                                            ]);
                                          } else
                                            setCart([
                                              ...currentCart.filter(
                                                (c) => c.id !== id,
                                              ),
                                            ]);
                                        }
                                      }}
                                      onResetCallback={(v) => {
                                        setCart([
                                          ...currentCart.filter(
                                            (c) => c.id !== id,
                                          ),
                                        ]);
                                      }}
                                    />
                                  </div>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </TabPanel>
                    );
                  },
                )}
              </TabContext>
            </Box>
          )}

        {isTicketsSuccess && tickets?.length === 0 && (
          <span className="flex justify-center w-full">
            {t('drawers.book_product.steps.tickets.no_results')}
          </span>
        )}

        {tickets?.length > 0 && showTotal && (
          <>
            <div
              className={classNames(
                'absolute items-center justify-end',
                'hidden w-full md:flex px-14 bottom-20',
              )}
            >
              <div className="flex items-center space-x-4">
                <span className="text-lg xl:text-xl">TOTAL</span>
                <span
                  className={classNames(
                    'flex items-center',
                    'mx-1 text-2xl',
                    'font-bold text-center text-medium',
                  )}
                >
                  {[
                    ...clientCart?.products,
                    ...(serverCart?.products || []),
                  ]?.reduce(
                    (acc, { product: { price_sale: $ } }) =>
                      acc + $,
                    fullPrice,
                  )}
                  <EuroIcon
                    className="flex ml-1"
                    fontSize="small"
                  />
                </span>
              </div>
            </div>
          </>
        )}
      </>
    );
  },
);

Tickets.displayName = 'Tickets';

export default Tickets;
