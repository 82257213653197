import { Header, Section } from '@components';
import { Fragment } from "react";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export default function B2BServices({
  title = 'Title',
  label = 'Label',
  description = '',
}) {
  const { push } = useRouter();
  //i18n
  const { t } = useTranslation('common');
  // Events
  const onGoToPageSchool = () => push('/educational');
  const onGoToTourOperator = () => push('/tour-operator');

  const buttons = [
    {
      onClick: onGoToPageSchool,
      label: t('sections.culture_services.cta.school'),
      info: t('sections.culture_services.info.school'),
    },
    {
      onClick: onGoToTourOperator,
      label: t('sections.culture_services.cta.tour_operator'),
      info: t('sections.culture_services.info.tour_operator'),
    },
  ];

  return (
    <Section id="B2BServices">
      <Header
        title={title}
        label={label}
        description={description}
      />
      <div
        className={classNames(
          'mt-8',
          'mx-auto w-full lg:w-10/12',
          'flex flex-col lg:flex-row lg:justify-center',
        )}
      >
        {buttons.map(({ onClick, label, info }, i) => {
          return (
            <Fragment key={i}>
              <div className="flex flex-col justify-center lg:w-1/3 lg:items-center">
                <Button
                  key={i}
                  fullWidth
                  onClick={onClick}
                  className={classNames(
                    'rounded-sm tracking-wide',
                    'py-2 text-center',
                    'mx-auto w-10/12 lg:w-full',
                    'bg-primary',
                    'text-white',
                  )}
                >
                  {label}
                </Button>
                <span className="justify-center hidden w-full h-12 my-3 text-xs text-center lg:flex">
                  {info}
                </span>
              </div>
              {i === 0 && (
                <>
                  <div className="hidden px-10 lg:flex">
                    <Divider
                      orientation="vertical"
                      className="h-10"
                    />
                  </div>

                  <Divider className="flex my-4 lg:hidden">
                    <span className="text-sm text-gray-700 dark:text-white">
                      oppure
                    </span>
                  </Divider>
                </>
              )}
            </Fragment>
          );
        })}
      </div>
    </Section>
  );
}
