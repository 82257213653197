import { useTranslation } from 'next-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import truncate from 'lodash/truncate';
import { useMediaQueries } from '@hooks';

export default function Paths({ list }) {
  const { t } = useTranslation('common');
  const { isTablet, isMobile } = useMediaQueries();

  if (list?.length > 0) {
    return (
      <List
        className="hidden py-0 pl-2 my-0 space-y-0 md:block"
        dense
      >
        {list?.map(({ name }, i) => (
          <ListItem key={i} className="p-0 m-0 text-sm">
            <ListItemIcon className="w-8 p-0 m-0 min-w-min">
              <ArrowRightIcon
                fontSize="small"
                className="text-primary"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <span className="text-sm font-normal font-title">
                  {truncate(name, {
                    length: isMobile ? 40 : isTablet ? 50 : 65,
                    separator: /,.:? +/,
                  })}
                </span>
              }
              secondary={
                <span className="text-xs">
                  {t('generic.labels.path') + '  ' + (i + 1)}
                </span>
              }
            />
          </ListItem>
        ))}
      </List>
    );
  }

  return null;
}
