import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useGetOrdersQuery } from '@services';
import minBy from 'lodash/minBy';
import { useTranslation } from 'next-i18next';

dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);

export default function OrdersList() {
  const { t } = useTranslation('common');
  const {
    data: orders,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetOrdersQuery();

  return (
    <>
      {isLoading || isFetching ? (
        <div className="flex items-center justify-center w-full py-24">
          <CircularProgress thickness={4} size={20} />
        </div>
      ) : (
        isSuccess &&
        orders?.length > 0 && (
          <div className="space-y-4">
            {[...orders].reverse().map((order, i) => {
              const {
                tickets,
                order_number,
                total_price,
                items,
                created_at,
              } = order;

              const minDate = minBy(items, 'date_service');

              const allowPrintTicket = items.filter((item) => {
                const { date_service, hour_service } = item;
                const date = date_service + ' ' + hour_service;
                return dayjs().isSameOrBefore(dayjs(date));
              });

              return (
                <div
                  key={i}
                  className="relative p-6 rounded-md bg-slate-100 dark:bg-zinc-800"
                >
                  <span className="absolute text-sm opacity-50 right-8 top-5">
                    {dayjs(created_at?.date).fromNow()}
                  </span>

                  <List dense={true}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <span className="text-green-600">
                            {order_number}
                          </span>
                        }
                        secondary={t(
                          'pages.personal_area.orders.number',
                        )}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary={dayjs(created_at?.date).format(
                          'DD/MM/YYYY',
                        )}
                        secondary={t(
                          'pages.personal_area.orders.created',
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={dayjs(
                          minDate?.date_service,
                        ).fromNow(true)}
                        secondary={t(
                          'pages.personal_area.orders.first_entry',
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={items.reduce(
                          (acc, item) => acc + item?.qty,
                          0,
                        )}
                        secondary={t(
                          'pages.personal_area.orders.quantity',
                        )}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary={<span>{total_price} euro</span>}
                        secondary={t(
                          'pages.personal_area.orders.total',
                        )}
                      />
                    </ListItem>

                    {allowPrintTicket?.length > 0 && (
                      <Button
                        startIcon={
                          <PictureAsPdfIcon fontSize="large" />
                        }
                        size="small"
                        target="_blank"
                        rel="noreferrer"
                        variant="link"
                        disableFocusRipple
                        disableElevation
                        fullWidth
                        href={'https://' + tickets}
                        title="tickets"
                        className="flex max-w-sm mx-auto text-xs font-medium"
                        download={'https://' + tickets}
                      >
                        Scarica biglietti
                      </Button>
                    )}
                  </List>
                </div>
              );
            })}
          </div>
        )
      )}
    </>
  );
}
