import Facilities from './Sections/Facilities/Facilities';
import { getCleanedPaths } from '@utils';
import Abstract from './Sections/Abstract/Abstract';
import CompactCardContainer from './Sections/Container/CompactCardContainer';
import CityLink from './Sections/Links/City';
import ProductLink from './Sections/Links/Product';
import { useStrapiGetImgFormats } from '@hooks';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Button } from '@mui/material';
import { useTranslation } from 'next-i18next';

export default function SiteCompactCard({ product, loading }) {
  const {
    slug,
    city,
    address,
    hero,
    facilities,
    abstract,
    intro_text,
    type,
    itinerants,
  } = product;
  const { push } = useRouter();
  const { t } = useTranslation();
  const { small } = useStrapiGetImgFormats({
    img: hero?.media?.data?.attributes,
  });

  const facilitiesList = facilities?.list?.data || [];

  const hasItinerant = itinerants?.data?.length > 0;

  const { cityPath, prodPath } = getCleanedPaths({
    city: city?.data?.attributes?.slug,
    slug,
    typeSegment: '/musei-parchi-archeologici/',
  });

  return (
    <CompactCardContainer
      img={small?.url}
      loading={loading}
      prodPath={prodPath}
      type={type?.data?.attributes?.name}
      hasButton
    >
      <div className="flex flex-col h-full justify-between">
        <CityLink
          href={cityPath}
          name={product?.city?.data?.attributes?.name}
        />

        <div>
          <ProductLink href={prodPath} name={hero?.title} />
          <span className="hidden text-sm md:flex">{address}</span>
        </div>

        <Abstract text={abstract || intro_text} />
        <div className="flex md:flex-row flex-col md:justify-between md:items-center pt-2 lg:pt-3">
          {hasItinerant && (
            <>
              {itinerants?.data.map((itinerant, i) => {
                const { slug: slug_i } = itinerant?.attributes;
                const visitGuided =
                  slug_i.includes('visita-guidata');
                const audioguide = slug_i.includes('audioguida');
                if (visitGuided || audioguide)
                  return (
                    <Button
                      variant="text"
                      key={slug_i}
                      className="hidden md:block"
                      onClick={() => {
                        push(
                          `${cityPath}/tour-visite-guidate/${slug_i}`,
                        );
                      }}
                    >
                      {visitGuided
                        ? t('buttons.tour')
                        : t('buttons.audioguide')}
                    </Button>
                  );
              })}
            </>
          )}
          <Button
            variant="text"
            onClick={() => {
              push(`${prodPath}`);
            }}
            className="hidden md:block"
          >
            {t('buttons.hour_price')}
          </Button>
          <Button
            onClick={() => {
              push(`${prodPath}?showCalendar=true`);
            }}
            size="small"
            className="bg-primary text-white"
            sx={{
              fontSize: '12px !important',
              padding: '0px 24px',
              height: '32px',
            }}
            variant="contained"
          >
            {t('buttons.buy')}
          </Button>
        </div>

        <div
          className={classNames(
            'hidden md:flex items-center ',
            'space-x-4 text-sm',
          )}
        >
          <Facilities list={facilitiesList} />
        </div>
      </div>
    </CompactCardContainer>
  );
}
