import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from 'next/link';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { useRouter } from 'next/router';
import { useMediaQueries } from '@hooks';
import truncate from 'lodash/truncate';

type BreadcrumbsProps = {
  className?: string;
};

export default function CustomBreadcrumbs({
  className,
}: BreadcrumbsProps) {
  const router = useRouter();
  const isHome = router?.pathname === '/';
  const isBlogSection = router?.pathname?.includes('blog');
  const isDidattica = router?.pathname?.includes('didattica');
  const { isMobile } = useMediaQueries();

  if (!isHome) {
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        className={classNames(
          className,
          'container',
          'mx-auto my-8 md:my-10 lg:my-12 xl:my-14',
          'text-sm md:text-base',
          'flex w-full px-3 md:px-6',
        )}
      >
        <Link href="/" passHref>
          <a className="text-primary">Home</a>
        </Link>

        {isDidattica && (
          <Link href="/didattica" passHref>
            <a className={classNames('capitalize text-primary')}>
              Didattica
            </a>
          </Link>
        )}

        {router?.query && router?.query?.city && (
          <Link
            passHref
            href={{
              pathname: '/esperienze/[city]',
              query: {
                city: kebabCase(router?.query?.city.toString()),
              },
            }}
          >
            <a className="capitalize text-primary">
              {router?.query?.city.toString().replaceAll('-', ' ')}
            </a>
          </Link>
        )}

        {router?.query &&
          router?.query?.city &&
          router?.query?.site && (
            <Link
              passHref
              href={{
                pathname:
                  '/esperienze/[city]/musei-parchi-archeologici/[slug]',
                query: {
                  city: kebabCase(router?.query?.city.toString()),
                  slug: kebabCase(router?.query?.site.toString()),
                },
              }}
            >
              <a className="capitalize text-primary">
                {router?.query?.site
                  ?.toString()
                  .replaceAll('-', ' ')}
              </a>
            </Link>
          )}

        {isBlogSection && (
          <Link href="/blog" passHref>
            <a className={classNames('capitalize text-primary')}>
              Blog
            </a>
          </Link>
        )}

        {router?.query?.slug && (
          <span className="capitalize pointer-events-none">
            {isMobile
              ? truncate(router?.query.slug as string)
                  .split('-')
                  .join(' ')
              : (router?.query.slug as string).split('-').join(' ')}
          </span>
        )}
      </Breadcrumbs>
    );
  }

  return null;
}
