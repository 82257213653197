import { Section } from '@components';
import classNames from 'classnames';
import { NewsletterForm } from '@components';
import { useRouter } from 'next/router';
import { useState } from 'react';

export default function Newsletter() {
  const { locale } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  if (locale === 'en') return null;

  return (
    <Section
      id="newsletter"
      className="!py-0 !mb-0 bg-newsletter h-110 saturate-[0.8] relative"
      isLoading={isLoading}
      dense={false}
    >
      <div
        className={classNames(
          'relative w-10/12 text-center',
          '-translate-y-1/2 top-1/2',
          '-translate-x-1/2 left-1/2',
          'text-white',
          'flex justify-center flex-col',
        )}
      >
        <header
          className={classNames('flex flex-col justify-center')}
        >
          <span
            className={classNames(
              'text-sm font-medium mb-2',
              'uppercase font-condensed',
              'tracking-airy-light',
            )}
          >
            {isSuccess
              ? 'Vivi la Cultura in prima Persona'
              : 'Iscriviti alla nostra newsletter'}
          </span>

          <h3
            className={classNames(
              'font-title font-extrabold',
              'text-white text-xl md:text-4xl',
              'uppercase',
            )}
          >
            {isSuccess
              ? 'Grazie per esserti iscritto'
              : 'Segui la Cultura in prima Persona'}
          </h3>

          {!isSuccess && (
            <p className="max-w-2xl mx-auto mt-4 text-base">
              Iscriviti alla Newsletter Aditus e ricevi
              aggiornamenti sui Musei e Parchi Archeologici, visite
              guidate, storia e curiosità dal mondo della cultura.
            </p>
          )}
        </header>

        {!isSuccess && (
          <NewsletterForm
            variant="filled"
            className={classNames(
              'max-w-xl p-2 mt-8 rounded-md',
              '-translate-x-1/2 left-1/2',
             
            )}
            setIsLoading={setIsLoading}
            onSuccess={() => {
              setIsSuccess(true);
            }}
            onError={() => {
              setIsSuccess(false);
            }}
          />
        )}
      </div>
    </Section>
  );
}
