import classNames from 'classnames';
import truncate from 'lodash/truncate';
import { useMediaQueries } from '@hooks';

export default function Abstract({ text }) {
  const { isDesktop } = useMediaQueries();

  if (text && text.length > 0) {
    return (
      <p
        className={classNames(
          'mt-2 hidden md:flex',
          'text-sm xl:text-base',
          'text-gray-700 dark:text-white opacity-70',
          'leading-[0.5rem]]',
        )}
      >
        {truncate(text, {
          length: isDesktop ? 200 : 180,
          separator: /,? +\+-/,
        })}
      </p>
    );
  }

  return null;
}
