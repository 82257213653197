import groupBy from "lodash/groupBy";
import omit from "lodash/omit";

export const transformSchedules = ({ schedules, lang = "it" }) => {
  try {
    if (schedules.length > 0) {
      const months = [
        {
          id: 1,
          labels: { it: "Gennaio", en: "January" },
        },
        {
          id: 2,
          labels: { it: "Febbraio", en: "February" },
        },
        {
          id: 3,
          labels: { it: "Marzo", en: "March" },
        },
        {
          id: 4,
          labels: { it: "Aprile", en: "April" },
        },
        {
          id: 5,
          labels: { it: "Maggio", en: "May" },
        },
        {
          id: 6,
          labels: { it: "Giugno", en: "June" },
        },
        {
          id: 7,
          labels: { it: "Luglio", en: "July" },
        },
        {
          id: 8,
          labels: { it: "Agosto", en: "August" },
        },
        {
          id: 9,
          labels: { it: "Settembre", en: "September" },
        },
        {
          id: 10,
          labels: { it: "Ottobre", en: "October" },
        },
        {
          id: 11,
          labels: { it: "Novembre", en: "November" },
        },
        {
          id: 12,
          labels: { it: "Dicembre", en: "December" },
        },
      ];

      const days = [
        {
          key: "mon_",
          labels: { it: "Lunedì", en: "Monday" },
        },
        {
          key: "tue_",
          labels: { it: "Martedì", en: "Tuesday" },
        },
        {
          key: "wed_",
          labels: { it: "Mercoledì", en: "Wednesday" },
        },
        {
          key: "thu_",
          labels: { it: "Giovedì", en: "Thursday" },
        },
        {
          key: "fri_",
          labels: { it: "Venerdì", en: "Friday" },
        },
        {
          key: "sat_",
          labels: { it: "Sabato", en: "Saturday" },
        },
        {
          key: "sun_",
          labels: { it: "Domenica", en: "Sunday" },
        },
        {
          key: "hol_",
          labels: { it: "Festivi", en: "Holiday" },
        },
      ];

      const transformedSchedules = schedules.map((s, i) => {
        const hours = days.map(({ key, labels }) => ({
          day: labels[lang],
          start: s[key + "start"],
          end: s[key + "end"],
          break_start: s[key + "break_start"],
          break_end: s[key + "break_end"],
        }));

        return {
          hours,
          id: s.month,
          month: months[s.month - 1]?.labels?.[lang],
          type: s.type,
          from: s.day_start,
          to: s.day_end,
        };
      });
      return groupBy(transformedSchedules, "id");
    }
  } catch (error) {
    console.error("transformSchedules", error.message);
  }
};

export const transformSchedulesByMonth = ({ schedules, index }) => {
  try {
    if (schedules && index) {
      // Use previous function to transform all schedules and get only the one selected grouped by 'to'
      const getGroupByKey = groupBy(transformSchedules({ schedules })?.[index], "to");

      let month = groupBy(
        Object.values(getGroupByKey)
          .flat()
          .map((g) => ({
            [g.type]: g.hours,
            ...omit(g, ["hours", "type"]),
          })),
        "from",
      );

      month = Object.values(month).map(([a, b, ...c]) => ({
        ...a,
        ...b,
        ...c,
      }));

      return month;
    }

    return;
  } catch (error) {
    console.error("transformSchedulesByMonth", error);
  }
};
