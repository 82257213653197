import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';

export default function Didactics({
  description,
  media,
  info_contact,
}) {
  if (description || media || info_contact) {
    return (
      <div
        className={classNames(
          'space-y-8 pb-8',
          'text-gray-700 dark:text-white',
          'items-center max-w-screen-xl mx-auto',
        )}
      >
        {description && (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="my-8 text-sm strapi-header-editorial md:text-base"
          >
            {description}
          </ReactMarkdown>
        )}

        {info_contact && (
          <a
            href={`mailto:${info_contact}`}
            target="_blank"
            className="flex items-center space-x-4"
          >
            <EmailIcon fontSize="small" />
            <span className="hover:underline underline-offset-4">
              {info_contact}
            </span>
          </a>
        )}

        {media?.data?.map(({ attributes }) => {
          return (
            <Button
              size="small"
              variant="contained"
              download={attributes?.name + '.pdf'}
              href={attributes?.url}
              rel="noreferrer"
              target="_blank"
              className={classNames(
                'mt-4',
                'w-full mx-auto md:max-w-xs',
                'text-white  bg-primary',
                'flex justify-center',
              )}
            >
              Download
            </Button>
          );
        })}
      </div>
    );
  }

  return null;
}
