import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import { useTranslation } from 'next-i18next';
import EmojiFlagsRoundedIcon from '@mui/icons-material/EmojiFlagsRounded';

export default function Paths({ paths }) {
  const { t } = useTranslation('common');

  if (paths && paths?.list?.length > 0) {
    return (
      <div className="space-y-8 text-gray-700 dark:text-white">
        <span>{paths?.description}</span>
        <div className="space-y-8">
          {paths.list.map((l, i) => (
            <div
              key={i}
              className="flex flex-row items-baseline space-x-4 "
            >
              <div>
                <EmojiFlagsRoundedIcon
                  className="w-8 opacity-50"
                  fontSize="small"
                />
              </div>
              <div className="space-y-2">
                <span className="text-sm">
                  {t('generic.labels.path')} {i + 1}
                </span>
                <h3 className="text-sm font-semibold uppercase font-title">
                  {l?.name}
                </h3>

                {l?.text && (
                  <p className="max-w-xl text-sm lg:text-base">
                    {l.text}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
}
