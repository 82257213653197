import CircularProgress from "@mui/material/CircularProgress";
import { DefaultGallery } from "@components";
import sortBy from "lodash/sortBy";
import { useGetStrapiTempProductsQuery } from "@services";
import { useRouter } from "next/router";

export default function TemporaryGallery({ title, label, description }) {
  const { push, locale } = useRouter();
  const { data: temporaries, isLoading, isFetching, isSuccess, isError } = useGetStrapiTempProductsQuery({ locale });

  const onClickTitle = () => push("/esperienze/mostre-eventi");

  if (isError) console.log("TemporaryGallery", isError);

  // Show only active event
  const filterByActiveDate = ({ attributes }) => new Date(attributes.end) > new Date();

  return (
    <>
      {(isLoading || isFetching) && (
        <div className="flex items-center justify-center w-full h-20 p-10 md:h-40">
          <CircularProgress thickness={4} size={20} />
        </div>
      )}

      {isSuccess && temporaries?.data.filter(filterByActiveDate)?.length > 0 && (
        <DefaultGallery
          type="event"
          title={title}
          label={label}
          pathTo="mostre-eventi"
          description={description}
          onClickTitle={onClickTitle}
          items={sortBy(temporaries?.data.filter(filterByActiveDate), "attributes?.city?.data?.attributes?.name")}
          options={{
            arrow: false,
            perPage: temporaries?.data.filter(filterByActiveDate)?.length === 1 ? 1 : 3,
          }}
        />
      )}
    </>
  );
}
