import { Header, Section, ProductCard } from '@components';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import Link from 'next/link';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useMediaQueries } from '@hooks';

function DefaultGallery({
  type,
  options,
  label = null,
  items = [],
  title = null,
  description = null,
  onClickTitle,
  showDescription,
  pathTo = null,
  isLoading,
  getDistanceFrom = null,
  dense = false,
  noPadding = false,
}) {
  const id = uuidv4();
  const { isMobile, isTablet, isDesktop } = useMediaQueries();
  const { t } = useTranslation('common');

  // Types of products: names match with Strapi models
  const isMuseum = type === 'museum';
  const isEvent = type === 'event';
  const isTour = type === 'tour';

  const onSlideVisible = (s, { slide }) => {
    try {
      const video = slide.querySelector('.has-video video');
      if (video?.readyState == 4) video.play();
    } catch (error) {
      console.error(error);
    }
  };

  const onSlideHidden = (s, { slide }) => {
    try {
      const video = slide.querySelector('.has-video video');
      if (video) video.pause();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Section
      id={`slider-${id}`}
      sLoading={isLoading}
      noPadding={noPadding}
    >
      <Header
        title={title}
        label={label}
        onClick={onClickTitle}
        description={description}
        showDescription={showDescription}
      />

      {isMobile &&
        items?.length > 2 &&
        pathTo &&
        pathTo?.length > 0 && (
          <div className="relative flex justify-end w-full px-2 mx-auto mb-4 lg:w-11/12">
            <Link passHref href={'/esperienze/' + pathTo}>
              <a
                className={classNames(
                  'cursor-pointer',
                  'underline-offset-2 hover:underline md:right-10',
                  'text-xs uppercase md:text-sm',
                  'font-condensed font-medium',
                  'text-gray-700 dark:text-white',
                )}
              >
                {t('components.gallery.see_all')}
              </a>
            </Link>
          </div>
        )}

      <Splide
        id={id}
        onMove={undefined}
        onScroll={undefined}
        onMounted={undefined}
        onHidden={onSlideHidden}
        onVisible={onSlideVisible}
        className="relative w-full mx-auto"
        options={{
          rewind: true,
          rewindByDrag: true,
          gap: items?.length === 1 ? '0rem' : '0.7rem',
          type: 'slide',
          width: isTablet
            ? '100vw'
            : items?.length === 1
            ? '75%'
            : '100vw',
          //autoplay: !isDesktop,
          pagination: true,
          lazyLoad: 'nearby',
          arrows: false,
          padding: isTablet ? 0 : 40,
          drag:
            isTablet && items?.length > 1
              ? 'slide'
              : items?.length <= 2
              ? false
              : 'free',
          video: { loop: true, mute: true },
          perPage: isTablet ? 1 : options?.perPage ?? 3,
        }}
      >
        {items?.length > 0 &&
          items.map((item, i) => (
            <SplideSlide key={i}>
              <ProductCard
                key={i}
                product={item}
                isSingleItem={items.length === 1}
                getDistanceFrom={getDistanceFrom}
                segment={
                  (isMuseum && 'musei-parchi-archeologici/') ||
                  (isEvent && 'mostre-eventi/') ||
                  (isTour && 'tour-visite-guidate/')
                }
              />
            </SplideSlide>
          ))}
      </Splide>

      {!isMobile &&
        items?.length > 1 &&
        pathTo &&
        pathTo?.length > 0 && (
          <div className="relative w-full mx-auto lg:w-11/12">
            <Link passHref href={'/esperienze/' + pathTo}>
              <a
                className={classNames(
                  'cursor pointer',
                  'absolute bottom-0 right-0 top-3 md:right-10',
                  'text-gray-700 dark:text-white',
                  'underline-offset-2 hover:underline',
                  'font-condensed font-medium',
                  'text-xs uppercase md:text-sm',
                )}
              >
                {t('components.gallery.see_all')}
              </a>
            </Link>
          </div>
        )}
    </Section>
  );
}

export default DefaultGallery;
