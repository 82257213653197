import {
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

export default function Plan({ handleClose, plan, name, open }) {
  const { isTablet } = useMediaQueries();
  const { t } = useTranslation('common');

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        fullScreen={isTablet}
        onClose={handleClose}
      >
        <DialogTitle className="text-gray-700 dark:text-white">
          {name}
        </DialogTitle>
        <DialogContent className="overflow-hidden">
          <TransformWrapper
            initialScale={isTablet ? 3 : 2}
            initialPositionX={isTablet ? -250 : -450}
            initialPositionY={isTablet ? -250 : -200}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <div className="flex justify-end pb-4 space-x-2">
                  <Tooltip title="Zoom In">
                    <IconButton
                      size="large"
                      onClick={() => zoomIn()}
                    >
                      <ZoomIn />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Zoom Out">
                    <IconButton
                      size="large"
                      onClick={() => zoomOut()}
                    >
                      <ZoomOut />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton
                      size="large"
                      download={name + '.png'}
                      href={plan.data?.attributes?.url}
                      rel="noreferrer"
                      target="_blank"
                      title={'Plan ' + name}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <TransformComponent wrapperClass="h-full md:h-auto">
                  <img
                    alt={'mappa ' + name}
                    className="object-cover object-center"
                    src={plan.data?.attributes?.url}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t('buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
