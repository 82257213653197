import AccessTime from '@mui/icons-material/AccessTime';
import classNames from 'classnames';

export default function Duration({ list }) {
  if (list?.length > 0) {
    return (
      <div className="space-y-4">
        {list.map((d, i) => (
          <div
            className="flex flex-row items-center space-x-4"
            key={i}
          >
            <div className="flex align-center">
              <AccessTime
                className="w-8 opacity-50 fill-gray-600 dark:fill-white"
                fontSize="small"
              />
            </div>
            <div>
              {d.type && (
                <span
                  className={classNames(
                    'text-sm',
                    'font-title',
                    'font-semibold uppercase',
                    'dark:text-white text-gray-700',
                  )}
                >
                  {d.type}
                </span>
              )}
              <p className="text-base">{d.text}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
  return null;
}
