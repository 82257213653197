/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: '/',
    label: { it: 'Esperienze', en: 'Experiences' },
    sublinks: [
      {
        path: '/esperienze/musei-parchi-archeologici',
        label: {
          it: 'Musei e Parchi Archeologici',
          en: 'Museums and Archaeological Parks',
        },
      },
      {
        path: '/esperienze/mostre-eventi',
        label: {
          it: 'Mostre ed Eventi',
          en: 'Exhibitions and Events',
        },
      },
      {
        path: '/esperienze/tour-visite-guidate',
        label: {
          it: 'Audioguide e Visite Guidate',
          en: 'Audio Guides and Guided Visits',
        },
      },
      {
        path: '/didattica',
        label: {
          it: 'Offerta Didattica',
          en: 'Educational Offer',
        },
      },
      {
        path: '/esperienze/food',
        label: {
          it: 'Food',
          en: 'Food',
        },
      },
    ],
  },

  {
    path: '/educational',
    label: { it: 'Scuole', en: 'School' },
  },
  {
    path: '/tour-operator',
    label: {
      it: 'Tour Operator',
      en: 'Tour operators',
    },
  },
  {
    path: '/about ',
    label: { it: 'Chi Siamo', en: 'About Us' },
  },
  {
    path: '/blog ',
    label: { it: 'Blog', en: 'Blog' },
  },
];
