import classNames from 'classnames';
import { useWindowScroll } from 'react-use';

export default function BackTopButton() {
  const { y } = useWindowScroll();

  const onScrollTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

  return (
    <div
      className={classNames(
        'bg-primary rounded-sm',
        'flex items-center justify-center',
        'fixed bottom-0 right-5',
        'z-40 h-12 w-12',
        'delay-50 transform-gpu transition-transform ease-in-out',
        {
          'translate-y-20': y < 1000,
          '-translate-y-16 lg:-translate-y-8': y > 1000,
        },
      )}
    >
      <button
        onClick={onScrollTop}
        className={classNames(
          'w-6 h-6 cursor-pointer',
          'text-sm font-medium text-white',
        )}
      >
        TOP
      </button>
    </div>
  );
}
