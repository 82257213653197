import { LazyLoadImage } from 'react-lazy-load-image-component';
import Link from 'next/link';
import classNames from 'classnames';

export default function CompactCardContainer({
  img,
  loading,
  slug,
  prodPath,
  children,
  type,
  hasButton = false,
}) {
  return (
    <div
      key={slug}
      className={classNames(
        'group relative',
        hasButton ? 'h-44  md:h-72' : 'h-36  md:h-64  ',
        'rounded-md',
        'overflow-hidden',
        'flex',
        'bg-slate-100 dark:bg-zinc-800',
        { 'animate-pulse': loading },
      )}
    >
      <div
        className={classNames(
          'relative overflow-hidden',
          'flex justify-center',
          'w-2/4 md:w-1/4',
        )}
      >
        <Link href={prodPath}>
          <LazyLoadImage
            alt="img"
            threshold={0}
            effect="opacity"
            src={img}
            className={classNames(
              'h-full w-full aspect-square',
              'object-cover object-center',
              'transition-all duration-300 ease-in-out',
              'saturate-75 cursor-pointer rounded-l-sm',
              'group-hover:scale-110 group-hover:saturate-100',
            )}
          />
        </Link>
      </div>

      <div
        className={classNames(
          'relative py-3 px-5 h-full',
          'w-3/4 flex flex-col space-y-1.5',
        )}
      >
        {type && (
          <span
            className={classNames(
              'hidden xl:block',
              'px-5 py-0.5',
              'font-thin text-white',
              'absolute -right-2 -top-0',
              'text-xs',
              'rounded-bl-md backdrop-blur-xl',
              'bg-primary/90 dark:bg-primary-400/95',
              'text-center font-condensed uppercase tracking-widest',
              'group-hover:bg-primary-600 max-w-xs',
              'transition-colors ease-in-out duration-500',
            )}
          >
            {type}
          </span>
        )}

        {children}
      </div>
    </div>
  );
}
