import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useStrapiGetImgFormats } from '@hooks';

export default function Artist({
  link,
  bio,
  fullname,
  birthplace,
  dob,
  img,
}) {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);
  const { t } = useTranslation('common');

  const { thumbnail } = useStrapiGetImgFormats({ img });

  return (
    <>
      <ListItemButton
        onClick={bio && handleClick}
        className={classNames(
          'space-x-4 md:space-x-6',
          'ml-0 pl-1 md:my-2',
          'hover:bg-transparent',
          {
            'cursor-default': !bio,
          },
        )}
      >
        <ListItemAvatar>
          <LazyLoadImage
            effect="opacity"
            src={thumbnail?.url}
            className={classNames(
              'object-cover object-center',
              'h-20 w-20 rounded-md',
              'saturate-75 aspect-video',
            )}
          />
        </ListItemAvatar>

        <ListItemText
          primary={
            <span className="text-lg font-medium font-title md:text-xl">
              {fullname}
            </span>
          }
          secondary={
            <span className="flex flex-col md:space-y-1">
              {
                <span className="space-x-1 text-sm md:text-base">
                  {birthplace && (
                    <span className="text-base italic">
                      {birthplace}
                    </span>
                  )}
                  {dob && <span className="text-sm">{dob}</span>}
                </span>
              }

              {link && link?.length > 0 && (
                <a
                  className={classNames(
                    'w-fit',
                    'text-primary-600 dark:text-primary-400',
                    'cursor-pointer text-sm md:text-base',
                    'underline-offset-2 hover:underline',
                  )}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('generic.labels.discover_more')}
                </a>
              )}
            </span>
          }
        />
        {bio && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {bio && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton className="p-0 m-0 hover:bg-transparent">
              <ListItemText
                primary={
                  <p className="pb-8 text-sm md:pb-4 md:text-base">
                    {bio}
                  </p>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
      )}
    </>
  );
}
