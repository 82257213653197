import { useEffect, useState } from 'react';

import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LinearProgress from '@mui/material/LinearProgress';
import { useInterval } from '@hooks';
import classNames from 'classnames';

export default function Timer({ limit, onTimeout, step, className }) {
  const [timer, setTimer] = useState(limit);

  useInterval(() => {
    if (timer > 0) setTimer(timer - 1);
  }, 1000);

  useEffect(() => {
    if (timer === 0) {
      onTimeout();
      setTimer(limit);
    }
  }, [timer]);

  useEffect(() => {
    setTimer(limit);
  }, [step]);

  return (
    <div className={classNames(className)}>
      {/* <LinearProgress
        className="rounded-2xl"
        variant="determinate"
        value={(timer / limit) * 100}
      /> */}
      <span
        className={classNames('space-x-1',{
          'text-red-600 dark:text-red-500': timer <= 3,
        })}
      >
        <span className='text-lg font-medium lg:text-xl'>{timer}</span>
        <span className="italic">sec</span>
      </span>
    </div>
  );
}
