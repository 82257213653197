import useMediaQueries from './useMediaQueries';
import isNil from 'lodash/isNil';

const placeHolderImage =
  'https://images.unsplash.com/photo-1545431613-51ec097943c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=50';

const useStrapiGetImgFormats = ({ img }) => {
  try {
    const { isMobile, isTablet, isDesktop, isLargeScreen } =
      useMediaQueries();

    if (img && typeof img === 'object') {
      const pointer = img?.data?.attributes || img;

      // Formats
      const { formats, url, ...others } = pointer;

      let fallBackFormats = isNil(formats)
        ? {
            thumbnail: { url, ...others },
            small: { url, ...others },
            medium: { url, ...others },
            large: { url, ...others },
          }
        : {...formats};
      
      
 

      if (!fallBackFormats?.hasOwnProperty('thumbnail')) {
        fallBackFormats.thumbnail = { ...formats?.small };
      }

      if (!fallBackFormats?.hasOwnProperty('small')) {
        fallBackFormats['small'] = {
          ...(formats?.medium ||
            formats?.large || { url, ...others }),
        };
      }

      if (!fallBackFormats?.hasOwnProperty('medium')) {
        fallBackFormats['medium'] = {
          ...(formats?.medium || { url, ...others }),
        };
      }

      if (!fallBackFormats?.hasOwnProperty('large')) {
        fallBackFormats['large'] = { url, ...others };
      }

      const { thumbnail, small, medium, large } = fallBackFormats;

      // Optimise asap
      const responsiveSrc = isMobile
        ? medium?.url
        : isTablet
        ? large?.url || url
        : isDesktop
        ? large?.url
        : isLargeScreen
        ? url
        : url;

      return {
        url,
        ...fallBackFormats,
        responsiveSrc,
        ...others,
      };
    }

    return {
      url: placeHolderImage,
      responsiveSrc: placeHolderImage,
      formats: {
        thumbnail: { url: placeHolderImage },
        small: { url: placeHolderImage },
        medium: { url: placeHolderImage },
        large: { url: placeHolderImage },
      },
    };
  } catch (error) {
    console.error('useStrapiGetImgFormats: Image Missing', error, {
      img,
    });
    return {
      url: placeHolderImage,
      responsiveSrc: placeHolderImage,
      formats: {
        thumbnail: { url: placeHolderImage },
        small: { url: placeHolderImage },
        medium: { url: placeHolderImage },
        large: { url: placeHolderImage },
      },
    };
  }
};

export default useStrapiGetImgFormats;
