import { logoutReducer, setReconciliationReducer, setReductionsIDsReducer, userSelector } from "@slices";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Settings from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import { useLogoutUserMutation } from "@services";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "next-i18next";

export default function CustomAvatar() {
  // Nextjs hooks
  const router = useRouter();
  // Redux hooks
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  // State
  const [anchorEl, setAnchorEl] = useState(null);
  // Redux Rtk
  const [logoutUser] = useLogoutUserMutation();
  // Utils
  const open = Boolean(anchorEl);

  // i18n
  const { t } = useTranslation("common");

  // Events
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const onClickProfileIcon = () => router.push("/user/profile");
  const onClickLogout = async (e) => {
    try {
      await logoutUser();
      dispatch(logoutReducer());
      dispatch(setReconciliationReducer(false));
      router.push("/");
    } catch (error) {
      console.error("onClickLogout", error);
    }
  };

  return (
    <>
      <Tooltip title={t("pages.personal_area.title")}>
        <IconButton
          size="small"
          disableRipple={true}
          aria-haspopup="true"
          onClick={handleClick}
          className="p-0 m-0"
          disableFocusRipple={true}
          aria-expanded={open ? "true" : undefined}
          aria-controls={open ? "account-menu" : undefined}
        >
          <Avatar sx={{ width: 24, height: 24, padding: 0 }} className="bg-primary">
            <span className="text-sm dark:text-white">{user?.first_name?.[0].toUpperCase()}</span>
          </Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        open={open}
        id="account-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 28,
              height: 28,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {[
          {
            label: t("pages.personal_area.title"),
            Icon: Settings,
            onClick: onClickProfileIcon,
          },
          {
            label: "Logout",
            Icon: Logout,
            onClick: () => {
              dispatch(setReductionsIDsReducer([]));
              onClickLogout();
            },
          },
        ].map(({ onClick, Icon, label }, i) => (
          <MenuItem onClick={onClick} key={label}>
            <ListItemIcon>
              <Icon fontSize="small" />
            </ListItemIcon>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
