import Link from "next/link";

export default function City({ href, city }) {
  return (
    href &&
    city && (
      <Link passHref href={href}>
        <a className="text-base capitalize italic underline-offset-4 hover:underline">{city}</a>
      </Link>
    )
  );
}
