import GoogleMapReact from "google-map-react";

export default function Maps({ children, className, googleMapOption }) {
  return (
    <div className={className}>
      <GoogleMapReact
        onClick={googleMapOption?.onClick}
        onChildClick={googleMapOption?.onChildClick}
        yesIWantToUseGoogleMapApiInternals
        zoom={googleMapOption?.defaultZoom}
        defaultZoom={googleMapOption?.defaultZoom}
        defaultCenter={googleMapOption?.defaultCenter}
        center={googleMapOption?.defaultCenter}
        onGoogleApiLoaded={(map, maps) => (googleMapOption?.onGoogleApiLoaded ? googleMapOption.onGoogleApiLoaded(map, maps) : null)}
        bootstrapURLKeys={{
          key: process.env.NEXT_PUBLIC_GMAPS,
        }}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
}
