import { useEffect, useState } from 'react';
import { userSelector } from '@slices';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'next-i18next';
import CartProduct from './CartProduct/CartProduct';
import { DeleteCartProductDialog } from '@components';

dayjs.extend(localizedFormat);

export default function CartList({
  cart,
  onDeleteItem,
  onDeleteItems,
  isLoading = false,
  isFetching = false,
  setAllowPayment,
}) {
  // User
  const user = useSelector(userSelector);
  // State
  const [cartItems, setCartItems] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idsToRemove, setIdsToRemove] = useState(null);
  // n18i
  const { t } = useTranslation('common');
  // Events
  const handleCloseDialog = () => setShowDeleteConfirm(false);
  const handleOpenDialog = () => setShowDeleteConfirm(true);
  const onDeleteProduct = () => {
    try {
      isArray(idsToRemove)
        ? onDeleteItems({ ids: idsToRemove })
        : onDeleteItem(idsToRemove);

      handleCloseDialog();
    } catch (error) {
      console.error('onDeleteCartProduct', error);
    }
  };

  useEffect(() => {
    const groupKey = 'date_service';
    const groupByDate = groupBy(sortBy(cart, groupKey), groupKey);

    const cartGroupedByDate = Object.entries(groupByDate).map(
      (i) => {
        const [key, values] = i;
        return { date: key, list: values };
      },
    );

    const formattedCart = cartGroupedByDate.map((p) => {
      return {
        ...p,
        list: Object.entries(
          groupBy(p.list, 'product.site.name'),
        ).map((i) => {
          const [key, values] = i;

          const current = values[0];
          const { product } = current;

          return {
            name: key,
            region: product?.site?.region,
            city: product?.site?.city,
            address: product?.site?.address,
            site_slug: product?.site?.canonical_name,
            // Il prodotto è di tipo museum card (per la Puglia)
            isCard: Boolean(product?.isCard),
            service: product?.service,
            // Check if site in in concession: if not there is no site page to link product
            in_concession: Boolean(product?.site?.in_concession),
            is_temporary: includes(
              ['exhibition', 'event'],
              product?.category,
            ),
            subtotal:
              Array.isArray(values) &&
              values?.reduce(
                (acc, { product: { price_sale: $ } }) => acc + $,
                0,
              ),
            ids:
              Array.isArray(values) && values?.map(({ id }) => id),
            products: Object.entries(
              groupBy(values, 'hour_service'),
            ).map((y) => {
              const [slot, slots] = y;
              return {
                slot: slot,
                slots: Object.entries(
                  groupBy(slots, 'product.name'),
                ).map((z) => {
                  const [type, types] = z;
                  return { type, types };
                }),
              };
            }),
          };
        }),
      };
    });

    setCartItems(formattedCart);
  }, [cart]);

  return (
    <>
      <DeleteCartProductDialog
        onClose={handleCloseDialog}
        showDeleteConfirm={showDeleteConfirm}
        onDelete={onDeleteProduct}
      />

      <div className="p-0 100vw">
        {cartItems?.length > 0 &&
          cartItems.map((item, i) => {
            return (
              <CartProduct
                key={i}
                user={user}
                item={item}
                cart={cart}
                setIdsToRemove={setIdsToRemove}
                loading={isLoading || isFetching}
                handleOpenDialog={handleOpenDialog}
                setAllowPayment={setAllowPayment}
              />
            );
          })}
      </div>
    </>
  );
}
