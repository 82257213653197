import Badge from '@mui/material/Badge';
import { cartSelector, userSelector } from '@slices';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useGetCartQuery } from '@services';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

export default function CartButton() {
  const { push, locale } = useRouter();
  const onClickIcon = () => push('/cart');
  // i18s
  const { t } = useTranslation('common');
  // Redux
  const { logged } = useSelector(userSelector);
  const cartClient = useSelector(cartSelector);

  const {
    data: serverCart,
    isFetching,
    isLoading,
  } = useGetCartQuery({ locale }, { skip: !logged });

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 1,
      top: 2,
      border: `1px solid ${theme.palette.background.default}`,
      padding: '0',
      minWidth: 18,
      height: 18,
      fontSize: '0.4rem',
      fontSize: '0.70rem',
      // backgroundColor: theme.palette.primary.main
    },
  }));

  return (
    <>
      {/* Wrapped around fragment to avoid odd console error */}
      <Tooltip title={t('navbar.cart')}>
        <IconButton
          aria-label="cart"
          onClick={onClickIcon}>
          <StyledBadge
            badgeContent={
              logged
                ? serverCart?.products?.length
                : cartClient?.products?.length
            }
            color="secondary"
          >
            {isFetching || isLoading ? (
              <CircularProgress thickness={4} size={16} />
            ) : (
              <ShoppingCartIcon
                fontSize="small"
                className="p-0 m-0 fill-gray-700 dark:fill-white"
              />
            )}
          </StyledBadge>
        </IconButton>
      </Tooltip>
    </>
  );
}
