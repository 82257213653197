import { orange } from "@mui/material/colors";
import { itIT } from "@mui/material/locale";

const commonThemeValues = {
  itIT,
  shadows: Array(25).fill("none"),
  spacing: 20,
  tonalOffset: 0.2,
  contrastThreshold: 10,
  shape: { borderRadius: 5 },
  typography: {
    fontFamily: "Barlow, Arial, sans-serif",
  },
  props: {
    MuiAccordionSummary: {
      disableElevation: true,
    },
  },
  components: {
    MuiTreeItem: {
      styleOverrides: {
        root: {
          //backgroundColor: 'red',
          ".Mui-selected": {
            background: "transparent !important;",
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
          backgroundColor: "transparent",
        },
      },
    },

    MuiTab: {
      /* styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
          '&.Mui-selected': {
            color: '#faaf40',
            '&:before': {
              display: 'none',
            },
          },
        },
      }, */
    },

    MuiFab: {
      styleOverrides: {
        backgroundColor: orange[700],
        root: {
          "& .MuiTabs-scroller": {
            "& .MuiTabs-indicator": {
              height: 0,
              borderRadius: "20px 20px 0 0",
              backgroundColor: orange[700],
            },
          },
        },
      },
    },

    /*  MuiTabs: {
      indicator: {
        backgroundColor: orange[700],
      },
      styleOverrides: {
        root: {
          '& .MuiTabs-scroller': {
            '& .MuiTabs-indicator': {
              height: 0,
              borderRadius: '20px 20px 0 0',
              backgroundColor: orange[700],
            },
          },
        },
      },
    }, */

    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {},
        },
      },
      defaultProps: {
        disableRipple: true,
        size: "small",
      },
    },

    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          //fontSize: '1.9rem',
          minWidth: "100%",
          backgroundColor: "transparent",
          button: {
            backgroundColor: "current",
          },
        },
      },
    },

    PrivatePickersFadeTransitionGroup: {
      styleOverrides: {
        root: {
          overflowY: "hidden !important;",
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          backgroundColor: "transparent",
          //fontWeight: 700
        },
      },
    },

    PrivatePickersSlideTransition: {
      styleOverrides: {
        root: {
          /*  'div[role="row"]': {
            justifyContent: "space-between"
          }, */
        },
      },
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: "100%",
          maxHeight: "100%",
          backgroundColor: "transparent",
        },
      },
      defaultProps: {
        disableRipple: true,
        size: "small",
      },
      daySelected: {
        backgroundColor: "transparent",
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: "none",
            },
          },
        },
      },
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        "&:-webkit-autofill": {
          "-webkit-box-shadow": "none",
          "-webkit-text-fill-color": "#fff",
        },
      },
    },

    MuiButton: {
      color: "#000",
      backgroundColor: "#000",
      "&:hover": {
        backgroundColor: "#000",
      },
    },

    MuiTreeItem: {
      ".Mui-selected": {
        backgroundColor: "trasparent !important;",
      },
    },
  },
};

export default commonThemeValues;
