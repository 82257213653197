import { useGetSlotsBySlugAndDateQuery } from '@services';
import Slots from './../Slots';

export default function SiteSlots({ slug, date, onClickSlot }) {
  const { data, isLoading, isFetching, isSuccess, isError } =
    useGetSlotsBySlugAndDateQuery({ slug, date });

  if (slug && date && onClickSlot) {
    return (
      <Slots
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        onClickSlot={onClickSlot}
      />
    );
  }

  return null;
}
