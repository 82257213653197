import Button from '@mui/material/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section } from '@components';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useStrapiGetImgFormats } from "@hooks";

export default function InEvidence({
  title = 'In Evidence',
  label = 'Label',
  description = '',
  cta,
  img,
}) {
  const { push } = useRouter();
  const { responsiveSrc } = useStrapiGetImgFormats({ img });


  if (title && img) {
    return (
      <Section
        id="inEvidence"
        fullWidth
        className="items-center justify-center h-80 bg-slate-200 md:h-96 xl:h-100"
      >
        <div
          className={classNames(
            'relative z-10 h-full w-full space-y-4',
            'flex flex-col items-center justify-center',
          )}
        >
          <div className="space-y-1">
            {label && label?.length > 0 && (
              <span
                className={classNames(
                  'flex justify-center',
                  'text-primary',
                  'font-condensed uppercase',
                  'text-sm font-medium tracking-wider',
                )}
              >
                {label}
              </span>
            )}
            <h3
              className={classNames(
                'text-white',
                'text-xl md:text-4xl',
                'font-title font-extrabold uppercase',
                'max-w-xs md:max-w-xl xl:max-w-none',
              )}
            >
              {title}
            </h3>
          </div>

          {description && description?.length > 0 && (
            <p
              className={classNames(
                'text-center text-sm text-white',
                'max-w-xs md:max-w-xl md:text-base',
              )}
            >
              {description}
            </p>
          )}

          {cta?.label && cta?.hfref && (
            <Button
              onClick={() => push(cta?.hfref)}
              size="small"
              className="w-full max-w-xs text-white rounded-sm bg-primary"
            >
              {cta?.label}
            </Button>
          )}
        </div>

        <LazyLoadImage
          src={responsiveSrc}
          threshold={0}
          effect="opacity"
          alt="image for hero component"
          className={classNames(
            'h-full w-full',
            'z-0 brightness-50',
            'object-cover object-center',
            'absolute bottom-0 left-0 right-0 top-0',
          )}
        />
      </Section>
    );
  }
  return null;
}
