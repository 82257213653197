import { LazyLoadImage } from "react-lazy-load-image-component";
import Link from "next/link";
import Skeleton from "@mui/material/Skeleton";
import classNames from "classnames";
import { useState } from "react";
import { useStrapiGetImgFormats } from "@hooks";

export default function DestinationCard({ slug, img, name, wrapped = true }) {
  const [loading, setLoading] = useState(true);
  // Events
  const onLoadMedia = () => setLoading(false);
  const { small} = useStrapiGetImgFormats({ img });

  return (
    <div
      className={classNames(
        'aspect-square rounded-md',
        'bg-slate-100 dark:bg-zinc-800',
        'group relative inline-flex overflow-hidden',
        {
          'my-2 h-40 w-full md:m-4 md:h-56 md:w-56': wrapped,
          'h-40 w-full md:h-56': !wrapped,
        },
      )}
    >
      {!loading && (
        <>
          <Link
            passHref
            href={{
              pathname: '/esperienze/[city]',
              query: { city: slug },
            }}
          >
            <a
              className={classNames(
                'font-[900] ',
                'tracking-widest',
                'absolute left-1/2 top-1/2',
                'underline-offset-4 hover:underline',
                'z-10 mb-0 flex max-w-xs cursor-pointer',
                '-translate-x-1/2 -translate-y-1/2 transform',
                'text-center font-title text-xl uppercase text-white',
              )}
            >
              {name}
            </a>
          </Link>
          <span className="absolute inset-0 z-[5] h-full w-full bg-black/10" />
        </>
      )}

      {loading && (
        <Skeleton
          animation="wave"
          height={'100%'}
          width={'100%'}
          variant="rectangular"
          className="absolute inset-0 z-20 w-full h-full"
        />
      )}

      <LazyLoadImage
        alt=""
        effect="opacity"
        afterLoad={onLoadMedia}
        src={small?.url}
        width={small?.width}
        height="auto"
        className={classNames(
          'absolute h-full w-full',
          'inset-0 z-0 object-cover object-center',
          'transition-all duration-500 ease-in-out',
          'opacity-1 saturate-75 brightness-75 group-hover:scale-110',
        )}
      />
    </div>
  );
}
