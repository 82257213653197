import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
import Link from 'next/link';
import { Tag } from '@cardElements';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useTranslation } from 'next-i18next';
import { useStrapiGetImgFormats } from '@hooks';
import truncate from 'lodash/truncate';
import getReadingTime from 'blog-reading-time';
import { useRouter } from 'next/router';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Tooltip from '@mui/material/Tooltip';

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);

export default function ArticleCard({ article, className }) {
  const { push } = useRouter();

  const { t } = useTranslation('common');
  const {
    img,
    slug,
    type,
    title,
    abstract,
    publishedAt,
    content,
    basic,
    article_categories,
  } = article;

  const today = dayjs();
  const publishedDate = dayjs(publishedAt).add(7, 'day');
  const showNewArticleBadge = publishedDate.isSameOrAfter(today);

  const { slug: categorySlug, name: categoryName } =
    article_categories.data[0]?.attributes || {};

  const { small, medium, responsiveSrc, url } =
    useStrapiGetImgFormats({ img });

  return (
    <div
      key={slug}
      className={classNames(
        className,
        'shadow-inner',
        'h-100 p-4 md:p-6',
        'overflow-hidden rounded-sm',
        'default-card group relative',
        'flex w-full flex-col justify-end',
        'bg-slate-100 dark:bg-zinc-800',
      )}
    >
      <div className="z-10">
        <Tag
          onClick={() => push(`/blog/tag/${categorySlug}`)}
          tag={categoryName}
        />

        <div
          className={classNames(
            'w-full space-y-2 pb-4 md:pb-0',
            'text-sm text-white',
            'flex flex-col items-start justify-between',
          )}
        >
          <span className="flex w-full space-x-2">
            <>
              {showNewArticleBadge ? (
                <span
                  className={classNames(
                    'inline-flex items-center bg-white px-2.5 py-0.5',
                    'text-primary backdrop-blur-3xl',
                    'font-condensed text-xs uppercase tracking-wider',
                  )}
                >
                  {t('generic.labels.new')}
                </span>
              ) : (
                <time className="text-sm">
                  {dayjs(publishedAt).fromNow()}
                </time>
              )}

              {(content || basic) && (
                <span className="flex items-center pl-2 border-l-2 border-white/50 opacity-70">
                  <span>{getReadingTime(content)} min</span>
                </span>
              )}

              {type === 'itinerary' && (
                <span className="flex items-center pl-2 border-l-2 border-white/50 opacity-80">
                  <Tooltip
                    title={
                      <span className="capitalize">{type}</span>
                    }
                  >
                    <ModeOfTravelIcon fontSize="small" />
                  </Tooltip>
                </span>
              )}

              {type === 'ranking' && (
                <span className="flex items-center pl-2 border-l-2 border-white/50 opacity-80">
                  <Tooltip
                    title={
                      <span className="capitalize">{type}</span>
                    }
                  >
                    <FormatListBulletedIcon fontSize="small" />
                  </Tooltip>
                </span>
              )}
            </>
          </span>

          <Link passHref href={'/blog/' + slug}>
            <a
              className={classNames(
                'text-white',
                'mb-0 max-w-xs md:max-w-lg',
                'underline-offset-4 hover:underline',
                'font-title text-xl font-[900] uppercase',
              )}
            >
              {truncate(title, {
                length: 45,
                separator: /,.:? +/,
              })}
            </a>
          </Link>
        </div>

        {abstract && (
          <p className="text-sm text-white opacity-80 lg:text-base">
            {truncate(abstract, {
              length: 80,
              separator: /,? +/,
            })}
          </p>
        )}
      </div>

      <div className="absolute inset-0 z-[5] h-full w-full bg-gradient-to-t from-black/80" />
      <LazyLoadImage
        src={responsiveSrc}
        effect="opacity"
        alt={
          img?.data?.attributes?.caption ||
          img?.data?.attributes?.alternativeText
        }
        className={classNames(
          'absolute',
          'inset-0 z-0 h-full w-full',
          'object-cover object-center',
          'group-hover:scale-110 group-hover:brightness-95',
          'brightness-90 transition-all duration-500 ease-in-out',
        )}
      />
    </div>
  );
}
