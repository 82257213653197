import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Header } from "@components";

export default function Faqs({ faqs }) {
  return (
    faqs?.length > 0 && (
      <div className="mx-auto mb-24 max-w-3xl space-y-4">
        <Header showDescription={false} title="FAQS" />

        {faqs.map(({ answer, question, file, cta }, i) => {
          return (
            <Accordion key={i} elevation={0} disableGutters className="mb-2">
              <AccordionSummary className="px-0 md:px-10" expandIcon={<ExpandMoreIcon />}>
                <h3 className="font-semibold tracking-wide">{question}</h3>
              </AccordionSummary>
              <AccordionDetails className="px-0 md:px-10 md:py-5">
                <p>{answer}</p>

                {file?.data?.attributes?.url && cta && (
                  <Button size="small" variant="outlined" className="mx-auto mt-8 flex w-full md:max-w-sm">
                    <a href={file.data.attributes.url} target="_blank" rel="noreferrer">
                      {cta}
                    </a>
                  </Button>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    )
  );
}
