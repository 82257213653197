import { LoginForm, RecoverForm } from "@components";

import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useState } from "react";
import { useTranslation } from "next-i18next";

type LoginAndRecoverFormProps = {
  className?: string;
  onSubmitLogin?: () => void;
};

export default function LoginAndRecoverForm({ className, onSubmitLogin }: LoginAndRecoverFormProps) {
  // React hooks
  const [showRecoverForm, setShowRecoverForm] = useState(false);

  // i18n
  const { t } = useTranslation("common");

  // Events
  const onShowRecoverForm = (e) => {
    e.preventDefault();
    setShowRecoverForm(true);
  };
  const onHideRecoverForm = (e) => {
    e.preventDefault();
    setShowRecoverForm(false);
  };

  return (
    <div className={["", className].join(" ")}>
      {showRecoverForm ? (
        <>
          <RecoverForm className="w-full" LoginButton={<KeyboardBackspaceIcon className="cursor-pointer" onClick={onHideRecoverForm} />} />
        </>
      ) : (
        <LoginForm
          className="w-full"
          onSuccess={onSubmitLogin}
          RecoverButton={
            <Button variant="text" size="small" onClick={onShowRecoverForm} className="mt-2 flex justify-center bg-transparent text-xs">
              {t("dialogs.login.actions.forgot_password")}?
            </Button>
          }
        />
      )}
    </div>
  );
}
