import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { setDrawerMenuStatus } from "@slices";
import { useDispatch } from "react-redux";

export default function MobileMenuButton({ className = "" }) {
  // Redux hooks
  const dispatch = useDispatch();
  // Events
  const onClickMenu = () => dispatch(setDrawerMenuStatus({ isOpen: true }));

  return (
    <IconButton
      color="inherit"
      component="button"
      disableRipple={true}
      onClick={onClickMenu}
      disableFocusRipple={true}
      className={[className, "m-0 flex p-0"].join(" ")}
    >
      <MenuIcon fontSize="small" />
    </IconButton>
  );
}
