export const validEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const noSpecialCharacters = /[`!@#$%^&*()_+\-°=\[\]{};':"\\|,.<>\/?~]/;
export const hasLetterAndNumber = /^[a-zA-Z0-9]|[à-ú]|[À-Ú]{1,}$/;
export const onlyNumber = /^[0-9]*$/;
export const onlyLetters = /^[a-zA-Z]+$/;
export const lettersAndSpace = /^[a-zA-Z\s]*$/;
export const validPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=(?:[^0-9]*[0-9]){1})(?=.*[!-/:-@[-`{-~])(?=.{8,})/;

export const atLeastFiveCharacters = /^(?=.*[a-zA-Z0-9]).{5,}$/;

export const validTelephone = /^[0-9\s+-\/]*$/;
