import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import React from "react";
import classNames from "classnames";
import getReadingTime from "blog-reading-time";
import { scroller } from "react-scroll";
import { useTranslation } from "next-i18next";

export default function Abstract({ subtitle, text, description }) {
  const { t } = useTranslation("common");

  if (!subtitle || !text) {
    return null;
  }

  const handleClick = () => {
    try {
      const btn = document.querySelector(".btn-expand-paragraph");
      if (btn && btn.classList.contains("close")) {
        btn.click();
      }
      scroller?.scrollTo("description", {
        smooth: true,
        duration: 500,
        offset: -85,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className={classNames(
          'mb-14',
          'space-y-4',
          'text-gray-700',
          'dark:text-white',
        )}
      >
        <h3
          className={classNames(
            'font-title',
            'text-base',
            'font-semibold',
            'uppercase',
            'md:text-xl',
          )}
        >
          {subtitle}
        </h3>
        <p className={classNames('text-sm', 'md:text-base')}>
          {text}
        </p>

        {description && (
          <div
            className={classNames(
              'w-full mx-auto',
              'flex flex-col',
              'justify-center',
              'pt-4 space-y-2',
            )}
          >
            <Button
              size="small"
              variant="contained"
              //color="info"
              className={classNames(
                'mx-auto',
                'bg-primary text-white',
                'w-full md:max-w-xs',
              )}
              onClick={handleClick}
            >
              {t('buttons.continue_reading')}
            </Button>
            <span
              className={classNames(
                'mx-auto',
                'flex',
                'space-x-1',
                'text-sm',
                'text-gray-700',
                'opacity-50',
                'dark:text-white',
                'md:pb-0',
              )}
            >
              <span>{t('generic.labels.reading_time')}</span>
              <span>{getReadingTime(description)} min</span>
            </span>
          </div>
        )}
      </div>
     {/*  <Divider /> */}
    </>
  );
}
