import { AditusIcon, AditusLogo } from '@icons';
import {
  AssistanceIcon,
  Avatar,
  CartButton,
  MobileMenuButton,
  SearchButton,
  SignButton,
  SwitchLocale,
  ToggleThemeButton,
} from '@components';
import { useDispatch, useSelector } from 'react-redux';

import Link from 'next/link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Sticky from 'react-stickynode';
import classNames from 'classnames';
import links from './navbar.data';
import { showLoginDialogReducer } from '@slices';

import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { userSelector } from '@slices';
import { useMediaQueries } from '@hooks';

export default function Navbar() {
  // Next hooks
  const { pathname, locale } = useRouter();
  // State
  const [isSticky, setSticky] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // Redux
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useMediaQueries();
  // Check
  const isRegistrationPage = pathname === '/user/registration';
  const open = Boolean(anchorEl);
  // i18s
  const { t } = useTranslation('common');

  // Events
  const handleOpenLoginDialog = () =>
    dispatch(showLoginDialogReducer(true));
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const onSetStickyStatus = ({ status }) => setSticky(status === 2);

  return (
    <>
      <Sticky
        top={0}
        innerZ={30}
        enabled={true}
        activeClassName="is-sticky"
        onStateChange={onSetStickyStatus}
      >
        <nav
          className={classNames(
            'flex items-center',
            'w-auto overflow-hidden',
            'transition-all delay-0 ease-in-out',
            'bg-white/90 backdrop-blur-xl dark:bg-black/90',

            {
              'h-16 md:h-[6rem]': !isSticky,
              'h-16 shadow-lg md:h-[4rem] lg:h-[5rem]': isSticky,
            },
          )}
        >
          <Link href="/" passHref>
            <a className="absolute flex left-5 w-fit md:left-14">
              <AditusLogo
                className={classNames(
                  'fill-dark dark:fill-white',
                  'hidden h-auto w-20 cursor-pointer md:flex md:w-28',
                  {
                    'w-[8rem]': !isMobile && !isSticky,
                    'w-[5rem]': !isMobile && isSticky,
                  },
                )}
              />

              <AditusIcon
                className={classNames(
                  'fill-dark dark:fill-white',
                  'flex h-auto w-[2rem] cursor-pointer md:hidden',
                )}
              />

              {/* {pathname?.includes("blog") &&
                <span className="transform z-50 absolute px-[10px] rounded-sm text-xs text-white -rotate-6 bg-secondary uppercase -bottom-2 md:right-2 tracking-widest font-semibold font-title">blog</span>} */}
            </a>
          </Link>

          {isDesktop && (
            <ul className="flex justify-center w-full space-x-5 text-gray-700 dark:text-slate-100 lg:space-x-10">
              {links.map(({ path, label, sublinks }, i) => {
                const hasSublinks =
                  sublinks && sublinks?.length > 0;
                return (
                  <li key={i} className={path}>
                    {hasSublinks ? (
                      <>
                        <button
                          id="basic-button"
                          aria-haspopup="true"
                          onClick={handleClick}
                          aria-expanded={open ? 'true' : undefined}
                          aria-controls={
                            open ? 'basic-menu' : undefined
                          }
                          className={classNames(
                            'flex py-2',
                            'transition-all delay-0 duration-500 ease-in-out',
                            'text-sm font-medium uppercase hover:text-primary lg:text-base',
                            { 'text-primary': open },
                          )}
                        >
                          {label[locale]}
                        </button>

                        <Menu
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleCloseMenu}
                        >
                          <MenuList dense>
                            {sublinks.map((s, i) => (
                              <MenuItem
                                key={s.path}
                                onClick={handleCloseMenu}
                              >
                                <Link href={s.path} passHref>
                                  <a
                                    className={classNames(
                                      'flex py-2',
                                      'text-xs font-medium uppercase',
                                      'transition-all delay-0 duration-500 ease-in-out',
                                    )}
                                  >
                                    {s.label[locale]}
                                  </a>
                                </Link>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                      </>
                    ) : (
                      <Link href={path} passHref>
                        <a
                          className={classNames(
                            'flex py-2',
                            'transition-all delay-0 duration-500 ease-in-out',
                            'text-sm font-medium uppercase hover:text-primary lg:text-base',
                          )}
                        >
                          {label[locale]}
                        </a>
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          )}

          <div className="absolute right-0 flex items-center lg:right-8">
            {!user?.logged && (
              <span className="w-auto centered">
                <SignButton
                  key="sign-button"
                  onSigIn={handleOpenLoginDialog}
                  isRegistrationPage={isRegistrationPage}
                />
              </span>
            )}

            <span className="w-12 centered">
              <SwitchLocale />
            </span>

            <span className="w-12 centered">
              <CartButton />
            </span>

            {!isMobile && (
              <span className="w-12 centered">
                <SearchButton />
              </span>
            )}

            <span className="flex w-12 centered">
              <ToggleThemeButton className="hidden md:flex" />
            </span>

            <span className="w-12 centered">
              <AssistanceIcon />
            </span>

            {user?.logged && (
              <span className="w-12 centered">
                <Avatar />
              </span>
            )}

            {!isDesktop && (
              <span className="w-12 centered">
                <MobileMenuButton />
              </span>
            )}
          </div>
        </nav>
      </Sticky>
    </>
  );
}
