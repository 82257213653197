import CloseIcon from '@mui/icons-material/Close';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import truncate from 'lodash/truncate';

export default function Marker({
  name,
  lat,
  lng,
  city,
  canonical_name,
  show,
  address,
  setCenter,
  setZoom,
  type,
  img,
}) {
  const cleanedCityName = city
    ?.toLowerCase()
    .trim()
    .replace(' ', '-');
  const setSitePath = `esperienze/${cleanedCityName}/musei-parchi-archeologici/${canonical_name}`;

  return (
    <div
      className="relative bottom-0"
      onClick={() => {
        setCenter({ lat, lng });
        setZoom(8);
      }}
      key={name}
      lat={lat}
      lng={lng}
    >
      <Tooltip title={name}>
        <div
          className={classNames(
            'h-6 w-6 -translate-x-1/2 -translate-y-1/2 md:h-8 md:w-8',
            'border-gray-700 text-white shadow-sm shadow-black/50',
            'flex items-center justify-center text-center',
            'hover:scale-150 hover:border-none',
            'rounded-full border',
            'transition-all duration-100 ease-in-out',
            {
              'bg-green-600': type.includes('parco'),
              'bg-primary': type.includes('museo'),
              'bg-red-700': type.includes('teatro'),
              'bg-yellow-500': type.includes('area'),
              'bg-gray-400': type.includes('castello'),
            },
          )}
        >
          <FlagCircleIcon
            fontSize="large"
            className={classNames(
              'm-0 p-0',
              'h-full w-full cursor-pointer',
              'fill-slate-100 dark:fill-black',
            )}
          />
        </div>
      </Tooltip>

      {show && (
        <div
          className={classNames(
            'flex flex-col',
            'rounded-md shadow-md',
            'z-50 w-64 p-4',
            'absolute bottom-8 left-8 ',
            'bg-white dark:bg-black',
          )}
        >
          <IconButton
            className="absolute w-4 h-4 right-2 top-2 text-slate-700 dark:text-white"
            disableFocusRipple={true}
            disableRipple={true}
            size="small"
          >
            <CloseIcon className="w-4 h-4" fontSize="small" />
          </IconButton>

          <div className="flex flex-col space-y-1">
            <Link passHref href={setSitePath}>
              <a
                className={classNames(
                  'text-xs font-medium uppercase md:text-sm',
                  'underline-offset-2 hover:underline',
                  'font-title',
                  'text-gray-700 dark:text-white',
                )}
              >
                {truncate(name, { length: 40,    separator: /,.:? +/, })}
              </a>
            </Link>
            <span className="flex max-w-xs text-xxs">
              {address}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
