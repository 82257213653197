import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SellIcon from '@mui/icons-material/Sell';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { useTranslation } from 'next-i18next';
import { Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export default function Prices({
  cta = 'CTA',
  data = [],
  info,
  isError,
  isLoading,
  isSuccess,
  mobileMode = false,
  onClick,
  ticketsAvailable = null,
}) {
  const { t } = useTranslation('common');
  const [activeTab, setActiveTab] = useState(null);
  const renderMessage = () => (
    <span className="flex justify-center w-full text-red-700 dark:text-red-600">
      {t('generic.labels.service_unavailable')}
    </span>
  );

  const renderLoading = () => (
    <div className="flex justify-center w-full p-4">
      <CircularProgress thickness={4} size={20} />
    </div>
  );

  const groupTicketsBy = (tickets: any[]) => {
    try {
      const groupByCard = tickets?.some(({ isCard }) =>
        Boolean(isCard),
      );

      const groupByLangs = tickets?.some(
        ({ name }) =>
          name.includes('(IT)') || name.includes('(EN)'),
      );

      /*  const groupByPaths = tickets?.some(
        ({ product_subcategory }) => product_subcategory,
      ); */

      /*   if (groupByPaths) {
        return [
          {
            label: 'Percorso',
            list: tickets.filter((t) => !Boolean(t?.isCard)),
          },
          {
            label: 'Percorso',
            list: tickets.filter((t) => Boolean(t?.isCard)),
          },
        ];
      } */

      if (groupByCard) {
        return [
          {
            label: 'tickets',
            list: tickets.filter((t) => !Boolean(t?.isCard)),
          },
          {
            label: 'CARD',
            list: tickets.filter((t) => Boolean(t?.isCard)),
          },
        ];
      }

      if (groupByLangs) {
        return [
          {
            label: t('drawers.book_product.steps.see_also.onlyIT'),
            list: tickets.filter(({ name }) =>
              name.includes('(IT)'),
            ),
          },
          {
            label: t('drawers.book_product.steps.see_also.onlyEN'),
            list: tickets.filter(({ name }) =>
              name.includes('(EN)'),
            ),
          },
        ];
      }

      return [
        {
          label: 'tickets',
          list: tickets,
        },
      ];
    } catch (error) {
      console.error('groupTicketsBy', error);
      return [
        {
          label: 'tickets',
          list: tickets,
        },
      ];
    }
  };

  const renderTicketsList = () => (
    <Box sx={{ width: '100%' }}>
      <TabContext value={activeTab}>
        <TabList
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant={
            groupTicketsBy(data).length > 2
              ? 'scrollable'
              : 'standard'
          }
        >
          {groupTicketsBy(data).map(({ label, list }, y) => {
            if (list?.length > 0) {
              const tabValue = label?.toLowerCase() || 'tickets';
              return (
                <Tab
                  key={y}
                  value={tabValue}
                  label={label?.toLowerCase() || 'tickets'}
                  sx={{
                    minWidth: '50% !important',
                  }}
                />
              );
            }

            return null;
          })}
        </TabList>

        {groupTicketsBy(data).map(({ label, list }, y) => {
          if (list?.length > 0) {
            const panelValue = label?.toLowerCase();

            return (
              <TabPanel key={y} value={panelValue} className="p-4 ">
                <ul
                  className={classNames('space-y-2.5', {
                    'pb-4': y < groupTicketsBy(data)?.length - 1,
                  })}
                >
                  {list.map((price, i) => {
                    const {
                      name,
                      price_sale,
                      product_subcategory,
                    } = price;
                    return (
                      <li
                        key={i}
                        className="flex items-center justify-between"
                      >
                        <span className="w-10 text-base font-bold text-end">
                          {price_sale}€
                        </span>
                        <span
                          className={classNames(
                            { '!text-xs': name?.length > 25 },
                            'flex flex-col',
                            'w-full pl-2',
                            'text-sm text-start',
                          )}
                        >
                          {product_subcategory && (
                            <span
                              className={classNames(
                                'pt-1',
                                'uppercase text-sm',
                                'font-medium dark:text-primary',
                              )}
                            >
                              {product_subcategory}
                            </span>
                          )}
                          <span
                            className={classNames({
                              'uppercase text-sm':
                                !product_subcategory,
                              'text-xxs lg:text-xs':
                                product_subcategory,
                            })}
                          >
                            {name
                              .replace('(IT)', '')
                              .replace('(EN)', '')}
                          </span>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </TabPanel>
            );
          }

          return null;
        })}
      </TabContext>
    </Box>
  );
  useEffect(() => {
    if (data?.length > 0) {
      const _activeTab =
        groupTicketsBy(data)[0].label.toLowerCase();
      setActiveTab(_activeTab);
    }
  }, [data]);
  return (
    <div
      className={classNames(
        'space-y-6 relative rounded-sm p-3 md:p-6',
        'text-gray-700 dark:text-white',
        'border-dashed border',
        'border-slate-200 dark:border-zinc-600',
        'bg-slate-100 dark:bg-zinc-800',
      )}
    >
      {ticketsAvailable && ticketsAvailable > 0 ? (
        <span
          className={classNames(
            'flex justify-center italic',
            'opacity-960 rounded-md text-sm',
            'p-2 dark:bg-zinc-700 bg-slate-200',
          )}
        >
          <span>+</span>
          <span className="mx-1 font-bold">{ticketsAvailable}</span>
          <span>{t('generic.labels.places_available')}</span>
        </span>
      ) : null}

      {(isError || (isSuccess && data?.length === 0)) &&
        renderMessage()}

      {isLoading && renderLoading()}

      {isSuccess && data?.length > 0 && renderTicketsList()}

      {info && (
        <p
          className={classNames(
            'text-sm p-2 lg:max-w-xl pt-4',
            'border-t border-dashed',
            'border-slate-200 dark:border-zinc-500',
          )}
        >
          {info}
        </p>
      )}

      {isFunction(onClick) && isSuccess && data?.length > 0 && (
        <Button
          onClick={onClick}
          className={classNames(
            'w-full mx-auto rounded-sm',
            'text-white uppercase',
            'bg-primary',
          )}
        >
          {cta}
        </Button>
      )}
      <div
        className={classNames(
          'w-full space-x-2',
          'flex items-center justify-center',
        )}
      >
        <span className="text-sm font-medium tracking-wider uppercase">
          {t('generic.labels.best_price_guaranteed')}
        </span>
        <SellIcon
          fontSize="small"
          className="fill-gray-700 dark:fill-white"
        />
      </div>
    </div>
  );
}
