import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment } from 'react';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

export default function Discounts({ description, list }) {
  const { t } = useTranslation('common');

  if (list) {
    return (
      <div>
        <p className="mb-10 text-sm leading-relaxed md:text-base">
          {description}
        </p>

        {list.map((d, i) => {
          const { discounts, name } = d;

          return (
            <Fragment key={i}>
              <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  className="px-0 py-0 m-0 md:px-1"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                >
                  <span className="flex items-center justify-center space-x-4">
                    <PermIdentityIcon
                      fontSize="small"
                      className="opacity-50"
                    />
                    <span className="flex flex-col">
                      <h3
                        className={classNames(
                          'p-0 m-0 text-sm',
                          'font-semibold uppercase font-title',
                          'text-gray-700 dark:text-white',
                        )}
                      >
                        {name}
                      </h3>
                      <span className="space-x-1 text-sm italic lowercase opacity-80">
                        <span>{discounts?.data?.length}</span>
                        <span>
                          {discounts?.data?.length > 1
                            ? t('generic.labels.types')
                            : t('generic.labels.type')}
                        </span>
                      </span>
                    </span>
                  </span>
                </AccordionSummary>
                <AccordionDetails className="px-0 md:px-10 md:py-5">
                  <ul className="pl-3 space-y-4 text-sm text-gray-700 list-outside dark:text-white md:list-inside md:text-base">
                    {discounts?.data.map(({ attributes }, y) => (
                      <li key={y} className="max-w-2xl space-x-2">
                        <span>•</span>
                        <span>{attributes?.description}</span>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            </Fragment>
          );
        })}
      </div>
    );
  }

  return null;
}
