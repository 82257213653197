/* eslint-disable react-hooks/exhaustive-deps */

import { CustomTextInput, HandleFormResponse } from "@components";
import { ReactElement, useEffect, useState } from "react";
import { atLeastFiveCharacters, validEmail } from "@utils";
import { loginReducer, showLoginDialogReducer } from "@slices";

import Link from "next/link";
import LoadingButton from "@mui/lab/LoadingButton";
import classNames from "classnames";
import every from "lodash/every";
import { useDispatch } from "react-redux";
import { useLoginUserMutation } from "@services";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

type LoginFormProps = {
  className?: string;
  onSuccess?: () => void;
  onError?: () => void;
  RecoverButton?: ReactElement;
};

export default function LoginForm({ className, onSuccess, onError, RecoverButton }: LoginFormProps) {
  const dispatch = useDispatch();
  const { locale, pathname, push } = useRouter();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const [allowSubmit, setAllowSubmit] = useState(false);

  // API
  const [loginUser, { isSuccess, isLoading, isFetching, isError, data }] = useLoginUserMutation();

  const isActivationPage = pathname === "/user/activation";

  // i18n
  const { t } = useTranslation("common");

  // Events
  const onCloseDialog = () => dispatch(showLoginDialogReducer(false));

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      e.preventDefault();
      await loginUser(formValues);
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, "valid"));
    // Update store with login value
    if (isSuccess && data.success) {
      const { data: res } = data;

      console.log(res?.user?.type, res?.user?.type !== "customer", res?.user?.redirect_url + `?lang=${locale}`);

      if (res?.user?.type !== "customer" && res?.user?.redirect_url) {
        window.location.replace("https://" + res?.user?.redirect_url + `?lang=${locale}`);
      } else {
        dispatch(loginReducer(res));
        push("/");
        // isActivationPage && push('/');
        onSuccess && onSuccess();
      }
    }
  }, [formValues, dispatch, isSuccess, data]);

  return (
    <>
      {!isSuccess && (
        <form className={["", className].join(" ")} onSubmit={onSubmit}>
          <div className="space-y-4">
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="email"
              maxLength={70}
              label="Email"
              variant="outlined"
              regex={atLeastFiveCharacters}
              helperText="Caratteri speciali non consentiti"
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  email: value,
                })
              }
            />
            <div className="relative flex w-full flex-col items-end">
              <CustomTextInput
                required
                fullWidth
                reset={true}
                margin="dense"
                className="my-1"
                type="password"
                name="password"
                label="Password"
                variant="outlined"
                regex={atLeastFiveCharacters}
                autoComplete="new-password"
                helperText="La password deve contenere almeno 6 caratteri"
                onSetValue={(value) =>
                  setFormValues({
                    ...formValues,
                    password: value,
                  })
                }
              />
              {RecoverButton && RecoverButton}
            </div>
          </div>

          <LoadingButton
            type="submit"
            color="primary"
            onClick={onSubmit}
            loading={isLoading || isFetching}
            loadingPosition="center"
            disabled={!allowSubmit}
            className={classNames("bg-primary text-white", "transition delay-150 ease-in-out", "mb-4 mt-8 h-10 w-full rounded-none", {
              "cursor-not-allowed opacity-50": !allowSubmit,
            })}
          >
            {!isLoading && !isSuccess && t("dialogs.login.actions.login")}
          </LoadingButton>
          <span className="flex justify-center text-sm">
            {t("dialogs.login.actions.suggest_sign_up")}
            <Link href="/user/registration" passHref>
              <a className="mx-1 font-medium uppercase text-primary" onClick={onCloseDialog}>
                {t("dialogs.login.actions.sign_up")}
              </a>
            </Link>
          </span>
        </form>
      )}

      <HandleFormResponse data={data} isError={isError} isSuccess={isSuccess} skipSuccess={true} />
    </>
  );
}

// anastasiya.pazhys@ltp.travel
