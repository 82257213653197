import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import sortBy from 'lodash/sortBy';
import { useState } from 'react';

const BrochureItem = ({ attributes, secondaryText }) => (
  <ListItem
    secondaryAction={
      <IconButton
        aria-label="download"
        download={attributes?.name + '.pdf'}
        href={attributes?.url}
        rel="noreferrer"
        target="_blank"
      >
        <DownloadIcon
          fontSize="small"
          className="dark:fill-primary"
        />
      </IconButton>
    }
  >
    <ListItemIcon>
      <PictureAsPdfIcon />
    </ListItemIcon>
    <ListItemText
      primary={
        <span>
          Brochure: <span className='font-semibold'>{attributes?.caption}</span>
        </span>
      }
      secondary={secondaryText}
    />
  </ListItem>
);

export default function Brochures({ list, show, name }) {
  const [showDialog, setShowDialog] = useState(show);
  const sortedData = sortBy(list?.data, 'attributes.caption');

  return (
    <>
      <div className="flex flex-col justify-center pt-8 space-y-4 md:pt-0">
        <List dense className="p-2 text-dark dark:text-white">
          {sortedData?.map((item, i) => (
            <BrochureItem
              key={i}
              attributes={item.attributes}
              secondaryText={name}
            />
          ))}
        </List>
      </div>

      {sortedData?.length > 0 && (
        <Dialog
          fullWidth
          maxWidth="xs"
          onClose={() => setShowDialog(false)}
          open={showDialog}
        >
          <DialogTitle className="relative flex-col">
            <span className="flex text-lg">Brochure</span>
            <span className="flex text-base font-light text-primary">
              {name}
            </span>

            <IconButton
              aria-label="close"
              onClick={() => setShowDialog(false)}
              className="absolute right-2 top-3"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>
          <List dense className="p-2">
            {sortedData?.map((item, i) => (
              <BrochureItem
                key={i}
                attributes={item.attributes}
                secondaryText="Guida"
              />
            ))}
          </List>
        </Dialog>
      )}
    </>
  );
}
