import { Splide, SplideSlide } from '@splidejs/react-splide';
import { ProductCard } from '@components';
import { useMediaQueries } from '@hooks';
import { v4 as uuidv4 } from 'uuid';

function EmptyGallery({ type, options, items = [] }) {
  const id = uuidv4();
  const { isMobile, isTablet } = useMediaQueries();

  // Types of products: names match with Strapi models
  const isMuseum = type === 'museum';
  const isEvent = type === 'event';
  const isTour = type === 'tour';
  const isSchool = type === 'school';

  const onSlideVisible = (s, { slide }) => {
    try {
      const video = slide.querySelector('.has-video video');
      if (video?.readyState == 4) video.play();
    } catch (error) {
      console.error(error);
    }
  };

  const onSlideHidden = (s, { slide }) => {
    try {
      const video = slide.querySelector('.has-video video');
      if (video) video.pause();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Splide
      id={id}
      onMove={undefined}
      onScroll={undefined}
      onMounted={undefined}
      onHidden={onSlideHidden}
      onVisible={onSlideVisible}
      className="relative w-full mx-auto"
      options={{
        gap: items?.length === 1 ? '0rem' : '1rem',
        type: 'slide',
        width: isMobile
          ? '100vw'
          : items?.length === 1
          ? '75%'
          : '100vw',
        autoplay: isMobile,
        pagination: true,
        lazyLoad: 'nearby',
        arrows: false,
        padding: isMobile ? 0 : 40,
        drag:
          isMobile && items?.length > 1
            ? 'slide'
            : items?.length <= 2
            ? false
            : 'free',
        video: { loop: true, mute: true },
        perPage: isMobile
          ? 1
          : isTablet
          ? options?.perPage ?? 2
          : options?.perPage ?? 3,
      }}
    >
      {items &&
        items?.length > 0 &&
        items.map((item, i) => (
          <SplideSlide key={i}>
            <ProductCard
              key={i}
              product={item}
              baseSegment={isSchool ? '/didattica/' : '/esperienze/'}
              segment={
                (isMuseum && 'musei-parchi-archeologici/') ||
                (isEvent && 'mostre-eventi/') ||
                (isTour && 'tour-visite-guidate/') ||
                (isSchool && '/')
              }
            />
          </SplideSlide>
        ))}
    </Splide>
  );
}

export default EmptyGallery;
