import { createSelector, createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    mode: typeof window !== "undefined" && window.matchMedia("(prefers-color-scheme: dark)")?.matches ? "dark" : "light",
    drawerMenuOpen: false,
  },
  reducers: {
    setThemeReducer(state, { payload }) {
      if (payload) state.mode = payload;
    },
    setDrawerMenuStatus(state, { payload }) {
      if (payload?.isOpen) state.drawerMenuOpen = payload?.isOpen;
      else state.drawerMenuOpen = !state.drawerMenuOpen;
    },
  },
});

export const { setThemeReducer, setDrawerMenuStatus } = themeSlice.actions;

export const currentThemeSelector = createSelector(
  ({ theme }) => theme,
  ({ mode }) => mode,
);

export const drawerMenuSelector = createSelector(
  ({ theme }) => theme,
  ({ drawerMenuOpen }) => drawerMenuOpen,
);

export default themeSlice.reducer;
