import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import isFunction from "lodash/isFunction";
import isObject from "lodash/isObject";

import { useTranslation } from "next-i18next";

export default function HandleFormResponse({
  className = "",
  data,
  isSuccess = null,
  isError = null,
  successTitle = null,
  successMessage = null,
  errorTitle = null,
  errorMessage = null,
  noTitle = false,
  successEvent = null,
  successCta = null,
  skipSuccess = false,
}) {
  const { t } = useTranslation("common");

  return (
    <div className={`${className} relative flex flex-col items-center justify-center`}>
      {/* SUCCESS MESSAGE */}
      {!skipSuccess && data && isSuccess && data?.success && (
        <Alert severity="success" className="bg-transparent">
          {!noTitle && <AlertTitle className="capitalize">{successTitle || "Success"}</AlertTitle>}

          {successMessage || t("forms.response.generic_success")}

          {successEvent && isFunction(successEvent) && successCta && (
            <Button fullWidth size="small" color="inherit" className="my-4" onClick={successEvent}>
              {successCta}
            </Button>
          )}
        </Alert>
      )}

      {/* ERROR MESSAGE API FAIL*/}
      {isError && (
        <Alert severity="error" className="bg-transparent">
          {!noTitle && <AlertTitle className="capitalize">{errorTitle || "Error"}</AlertTitle>}

          {isError || errorMessage || t("forms.response.generic_error")}
        </Alert>
      )}

      {/* ERROR MESSAGE API SUCCESS */}
      {isSuccess && !data?.success && (
        <Alert severity="error" className="bg-transparent">
          {!noTitle && <AlertTitle className="capitalize">{errorTitle || "Error"}</AlertTitle>}
          {isObject(data?.status) ? (
            <div className="flex flex-col">
              {Object.keys(data.status)?.map((k) => (
                <code className="space-x-2" key={k}>
                  <span className="font-medium">{k?.toUpperCase()}:</span>
                  <span>{data.status[k]}</span>
                </code>
              ))}
            </div>
          ) : (
            <code>{data?.status}</code>
          )}
        </Alert>
      )}
    </div>
  );
}
