import TimelineItem, {
  timelineItemClasses,
} from '@mui/lab/TimelineItem';
import classNames from 'classnames';

import CheckIcon from '@mui/icons-material/Check';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactMarkdown from 'react-markdown';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import remarkGfm from 'remark-gfm';
import Divider from '@mui/material/Divider';

export default function Itinerary({ itinerary }) {
  return (
    <Timeline
      className="p-0"
      sx={{
        minHeight: 50,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {itinerary?.map(({ name, slots, img }, i) => {
        return (
          <TimelineItem key={i}>
            <TimelineSeparator>
              <TimelineDot
                className={classNames(
                  'rounded-sm bg-slate-300 dark:bg-primary md:hidden',
                )}
              />
              <span
                className={classNames(
                  'text-xs  md:text-sm',
                  'rounded-md md:rounded-lg',
                  'h-6 w-6 md:h-9 md:w-9',
                  'text-black dark:text-white',
                  'bg-slate-50 dark:bg-zinc-800',
                  'hidden md:items-center md:justify-center md:flex',
                  'border border-slate-300 dark:border-primary',
                )}
              >
                {i + 1}
              </span>

              <TimelineConnector className="w-[1.5px] bg-slate-300 opacity-50" />
            </TimelineSeparator>
            <TimelineContent className="flex flex-col">
              <span
                className={classNames(
                  'text-xl uppercase',
                  'font-title font-extrabold',
                  'dark:text-white text-gray-700',
                )}
              >
                {name}
              </span>

              {img?.data && (
                <LazyLoadImage
                  alt={
                    img?.data?.attributes?.caption ||
                    img?.data?.attributes?.alternativeText
                  }
                  effect="opacity"
                  className={classNames(
                    'mt-12 h-60 w-full',
                    'relative object-cover object-center',
                    'saturate-75 rounded-md md:aspect-video',
                  )}
                  src={img?.data?.attributes?.url}
                />
              )}

              <ul className="py-6 space-y-8">
                {slots?.map((s, y) => (
                  <li className="space-y-6" key={y}>
                    <h3
                      className={classNames(
                        'relative px-2 rounded-xs',
                        'bg-primary w-fit',
                        'text-white',
                        'font-medium font-title',
                        'text-sm uppercase',
                      )}
                    >
                      {s?.name}
                    </h3>

                    <ul className="space-y-6 md:p-3.5">
                      {s?.activities?.map((activity, z) => {
                        const { title, content, img } = activity;
                        return (
                          <li
                            className="flex flex-col space-y-2"
                            key={z}
                          >
                            {img?.data && (
                              <LazyLoadImage
                                alt={
                                  img?.data?.attributes?.caption ||
                                  img?.data?.attributes
                                    ?.alternativeText
                                }
                                effect="opacity"
                                className="rounded-md"
                                src={img?.data?.attributes?.url}
                              />
                            )}

                            {title && (
                              <span className="font-semibold text-gray-700 dark:text-white md:text-xl">
                                {z + 1}. {title}
                              </span>
                            )}

                            {content && (
                              <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                className={classNames(
                                  'strapi-editorial',
                                  'text-gray-700  dark:text-white',
                                  'text-base md:text-lg 2xl:text-xl',
                                )}
                              >
                                {content}
                              </ReactMarkdown>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </TimelineContent>
          </TimelineItem>
        );
      })}

      <TimelineItem>
        <TimelineSeparator>
          <span
            className={classNames(
              'rounded-md md:rounded-lg',
              'h-6 w-6 md:h-9 md:w-9',
              'text-xs text-black dark:text-white md:text-sm',
              'bg-slate-50 dark:bg-zinc-800',
              'hidden md:items-center md:justify-center md:flex',
              'border border-slate-300 dark:border-secondary',
            )}
          >
            <CheckIcon fontSize="small" />
          </span>
        </TimelineSeparator>
      </TimelineItem>
    </Timeline>
  );
}
