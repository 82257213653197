import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { validEmail } from '@utils';
import every from 'lodash/every';
import { CustomTextInput } from '@components';
import classNames from 'classnames';
import Button from '@mui/material/Button';

import { useAddPrivateToContactListMutation } from '@services';

type NewsletterProps = {
  className?: string;
  variant?: string;
  onSuccess?: () => void;
  onError?: () => void;
  setIsLoading?: (Boolean) => Boolean;
};

export default function NewsletterForm({
  className,
  onSuccess,
  onError,
  setIsLoading = null,
  variant = 'outlined',
}: NewsletterProps) {
  const [formValues, setFormValues] = useState({
    email: { value: '', valid: false },
  });
  const [allowSubmit, setAllowSubmit] = useState(false);

  const [
    addPrivateToList,
    { data, isSuccess, isLoading, isError },
  ] = useAddPrivateToContactListMutation();

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsLoading && setIsLoading(true);

      const { data } = await addPrivateToList({
        email: formValues?.email?.value,
      });

      if (data?.success && data?.email) {
        setIsLoading && setIsLoading(false);
        onSuccess && onSuccess();
        //router.push('/');
      }
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, 'valid'));
  }, [formValues]);

  return (
    <form
      className={classNames('relative space-y-2', className)}
      onSubmit={onSubmit}
    >
      <CustomTextInput
        required
        fullWidth
        type="text"
        reset={true}
        margin="dense"
        className="my-1 !text-black bg-white dark:bg-zinc-800 rounded-sm"
        name="email"
        label="Email"
        variant={variant}
        regex={validEmail}
        helperText="Caratteri speciali non consentiti"
        onSetValue={(value) =>
          setFormValues({
            ...formValues,
            email: value,
          })
        }
      />

      <Button
        type="submit"
        color="primary"
        onClick={onSubmit}
        disabled={!allowSubmit}
        className={classNames(
          'h-10 w-full rounded-none bg-primary text-white',
          'transition delay-150 ease-in-out',
          {
            'cursor-not-allowed opacity-50': !allowSubmit,
          },
        )}
      >
        Invia
      </Button>
    </form>
  );
}
