import { useEffect, useRef, useState } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as ScrollLink } from 'react-scroll';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { useIntersection } from 'react-use';
import { useMediaQueries } from '@hooks';

type Anchor = {
  val: { name: string; to: string };
  cond?: boolean;
  counter?: number;
  loading?: boolean;
};

type Anchors = {
  anchors: Anchor[];
  className: string;
  onOpenDrawer?: () => null;
  onOpenCtaLabel?: string;
};

export default function Anchors({
  anchors,
  onOpenDrawer = null,
  onOpenCtaLabel,
}: Anchors) {
  const { isMobile, isLargeScreen } = useMediaQueries();
  const intersectionRef = useRef(null);
  const [loading, setLoading] = useState(null);
  const [showCounter, setShowCounter] = useState(true);

  const intersection = useIntersection(intersectionRef, {
    root: null,
    threshold: 1,
    rootMargin: '0px',
  });


  const filteredAnchors = anchors.filter(({ cond }) => cond);

  useEffect(() => {
    const test = anchors?.filter(
      ({ loading }) => loading && loading,
    );
    setLoading(test?.length > 0);
  }, [anchors]);

  const AnchorsList = () => {
    return (
      <>
        {loading ? (
          <div className="flex justify-center w-full mx-auto">
            <CircularProgress
              size={18}
              className="text-black dark:text-white"
            />
          </div>
        ) : (
          anchors?.map(({ val, cond, counter = null }, i) => {
            return (
              cond && (
                <span
                  key={i}
                  className={classNames(
                    'anchor-' + val?.to,
                    'cursor-pointer p-1',
                    'min-w-max text-xs font-semibold uppercase md:text-base',
                  )}
                >
                  <ScrollLink
                    key={i}
                    delay={200}
                    spy={true}
                    isDynamic={true}
                    to={val?.to}
                    offset={-120}
                    duration={500}
                    smooth="easeInOutQuint"
                    ignoreCancelEvents={false}
                    spyThrottle={500}
                    className={
                      'anchor relative min-w-max ' + val?.to
                    }
                    onSetActive={(label) => {
                      if (
                        label === 'communications' &&
                        showCounter &&
                        counter &&
                        counter > 0
                      ) {
                        setShowCounter(false);
                      }

                      if (isMobile && label == val?.to) {
                        const toolbar = document.querySelector(
                          '.anchors-toolbar-fixed',
                        );

                        const active = toolbar?.querySelector(
                          '.anchor.' + val?.to,
                        );

                        active?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                          inline: 'center',
                        });
                      }
                    }}
                  >
                    <span
                      className={classNames(
                        'relative z-10',
                        'transition-all delay-0 duration-500 ease-in-out',
                        'flex w-full hover:text-primary',
                        'min-w-max text-sm',
                      )}
                    >
                      {val.name}

                      {showCounter && counter && counter > 0 && (
                        <code
                          className={classNames(
                            'absolute -right-2.5 -top-2 -z-10',
                            'flex items-baseline justify-center',
                            '-z-0 rounded-lg text-xxs font-thin italic md:text-xs',
                            'pointer-events-none text-blue-400 hover:text-white',
                          )}
                        >
                          {counter}
                        </code>
                      )}
                    </span>
                  </ScrollLink>
                </span>
              )
            );
          })
        )}
      </>
    );
  };

  if (anchors?.length > 0) {
    return (
      <>
        <div
          className={classNames(
            'anchors-toolbar-fixed',
            'fixed left-0 right-0',
            'flex h-16 w-full items-center space-x-4 md:h-[4rem] lg:h-[5rem]',
            'rounded-none border-0 border-transparent',
            'backdrop-blur-3xl',
            {
              'top-0 z-[99]': intersection?.intersectionRatio < 1,
              '-top-20 z-0': intersection?.intersectionRatio === 1,
              'overflow-x-auto px-10 md:justify-center':
                filteredAnchors?.length > 5,
              'justify-center': filteredAnchors?.length < 6,
            },
          )}
        >
          <ArrowBackRoundedIcon
            className="absolute hidden cursor-pointer left-5 md:left-14 lg:flex"
            onClick={() => window?.history?.back()}
          />

          <AnchorsList />

          {isLargeScreen && onOpenDrawer &&
            isFunction(onOpenDrawer) && (
              <span
                onClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  onOpenDrawer();

                  return true;
                }}
                className={classNames(
                  'hidden h-8 w-48 lg:flex',
                  'items-center justify-center',
                  'absolute right-12 !text-xs',
                  'cursor-pointer select-none rounded-sm',
                  'bg-primary font-medium uppercase text-white',
                )}
              >
                {onOpenCtaLabel}
              </span>
            )}
        </div>

        <div
          ref={intersectionRef}
          className={classNames(
            'flex h-20 w-full items-center space-x-4 ',
            'rounded-none border-0 border-transparent',
            'bg-slate-100 backdrop-blur-3xl dark:bg-zinc-800',

            {
              'overflow-x-auto px-10 md:justify-center':
                filteredAnchors?.length > 5,
              'justify-center': filteredAnchors?.length < 6,
            },
          )}
        >
          <AnchorsList />
        </div>
      </>
    );
  }

  return null;
}
