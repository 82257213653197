import { Splide, SplideSlide } from '@splidejs/react-splide';
import { iOS, toggleBlurLayout } from '@utils';
import { museumsSelector, setDrawerQuickBuyStatus } from '@slices';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  useGetStrapiCitiesQuery,
  useGetStrapiSitesQuery,
} from '@services';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { CompactProductList } from '@components';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { useMediaQueries } from '@hooks';

function DrawerQuickBuy() {
  // Redux hooks
  const { locale } = useRouter();
  const dispatch = useDispatch();
  const { drawerQuickBuyOpen } = useSelector(museumsSelector);
  const { isMobile } = useMediaQueries();
  // React hooks
  const [sitesState, setSitesState] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  // i18n
  const { t } = useTranslation('common');

  // Events
  const setStatusDrawer = (status) => {
    toggleBlurLayout(null, status);
    dispatch(setDrawerQuickBuyStatus({ open: status }));
  };

  const onOpenDrawer = () => setStatusDrawer(true);
  const onCloseDrawer = () => setStatusDrawer(false);
  const onResetCitiesFilter = () => setSelectedCities([]);

  const { data: cities, isSuccess: isCitiesSuccess } =
    useGetStrapiCitiesQuery(
      { locale },
      { skip: !drawerQuickBuyOpen },
    );

  const {
    data: sites,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetStrapiSitesQuery(
    { locale },
    { skip: !drawerQuickBuyOpen },
  );

  useEffect(() => {
    if (
      isSuccess &&
      sites?.data?.length > 0 &&
      sitesState?.length === 0
    ) {
      setSitesState(sites?.data);
    }
  }, [sites, isSuccess]);

  useEffect(() => {
    toggleBlurLayout(['blur-sm'], drawerQuickBuyOpen);
    return () => toggleBlurLayout(['blur-sm'], false);
  }, [drawerQuickBuyOpen]);

  useEffect(() => {
    if (isSuccess && sites?.data?.length > 0) {
      if (selectedCities?.length > 0) {
        const selected = sites.data.filter(({ attributes }) =>
          selectedCities.includes(
            attributes?.city?.data?.attributes?.name.trim(),
          ),
        );
        setSitesState(sortBy(selected, 'city'));
      } else setSitesState(sites?.data);
    }
  }, [selectedCities, sites, isSuccess]);

  return (
    <Drawer
      className="relative"
      elevation={3}
      // hideBackdrop={false}
      // disableDiscovery={iOS}
      // onOpen={onOpenDrawer}
      onClose={onCloseDrawer}
      open={drawerQuickBuyOpen}
      //disableBackdropTransition={!iOS}
      anchor={isMobile ? 'bottom' : 'right'}
      transitionDuration={{ enter: 200, exit: 500 }}
      sx={{
        '& .MuiDrawer-paper': {
          background: 'none',
          border: 'none',
        },
      }}
    >
      <div
        className={classNames(
          'h-screen w-screen md:h-screen md:w-[500px]',
          'bg-zinc-100 shadow-2xl dark:bg-zinc-800',
          'z-100 relative flex-col overflow-hidden',
        )}
      >
        <div className="relative flex items-center justify-center w-full h-10 md:h-14">
          <IconButton
            color="inherit"
            component="span"
            onClick={onCloseDrawer}
            className="absolute flex items-center justify-center w-10 h-10 text-xl cursor-pointer left-3 top-3"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="flex flex-col px-6 pb-0 space-y-2">
          <Divider className="font-semibold uppercase text-primary">
            <Chip
              variant="outlined"
              label={t('drawers.quick_buy.label')}
              className="px-6 w-fit rounded-xl text-primary"
            />
          </Divider>
        </div>

        <Splide
          className="flex items-center w-11/12 h-10 mx-auto my-4"
          options={{
            rewind: true,
            gap: '1rem',
            pagination: false,
            arrows: false,
            focus: 'center',
            autoWidth: true,
          }}
        >
          {isCitiesSuccess &&
            cities?.length > 0 &&
            sortBy(cities, 'attributes.name').map(
              ({ attributes }, i) => (
                <SplideSlide key={i}>
                  <Chip
                    variant="outlined"
                    label={attributes?.name}
                    onClick={() => {
                      if (
                        !selectedCities.includes(attributes?.name)
                      ) {
                        setSelectedCities([
                          ...selectedCities,
                          attributes?.name,
                        ]);
                      }
                    }}
                    onDelete={
                      selectedCities.includes(attributes?.name)
                        ? () => {
                            setSelectedCities(() =>
                              selectedCities.filter(
                                (city) => city !== attributes?.name,
                              ),
                            );
                          }
                        : null
                    }
                  />
                </SplideSlide>
              ),
            )}
        </Splide>

        {(isLoading || isFetching) && (
          <div className="flex items-center justify-center w-full p-10">
            <CircularProgress thickness={4} size={20} />
          </div>
        )}

        {isSuccess && sitesState?.length > 0 && (
          <CompactProductList
            list={sitesState}
            className="px-4 md:px-8"
            typeSegment="/musei-parchi-archeologici/"
          />
        )}

        {selectedCities?.length > 0 && (
          <Button
            onClick={onResetCitiesFilter}
            className={classNames(
              'rounded-none',
              'bg-primary text-white',
              'h-12 w-screen md:h-14 md:w-[500px]',
              'fixed bottom-0 right-0',
            )}
          >
            {t('buttons.clear_filters')}
          </Button>
        )}
      </div>
    </Drawer>
  );
}

export default DrawerQuickBuy;
