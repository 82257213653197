import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSetCardHoldersMutation } from '@services';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function CardOwner({
  id,
  name,
  surname,
  user,
  setAllowPayment,
}) {
  const { t } = useTranslation('common');
  const { isMobile, isTablet } = useMediaQueries();
  const [editMode, setEditMode] = useState(true);
  const [setCardHolder] = useSetCardHoldersMutation();
  const [enableSave, setEnableSave] = useState(true);
  const { locale } = useRouter();

  const [holder, setHolder] = useState({
    holder_first_name: name,
    holder_last_name: surname,
  });

  const onResetCardOwner = () => {
    try {
      setHolder({
        holder_first_name: '',
        holder_last_name: '',
      });
      setEditMode(!editMode);
    } catch (error) {
      console.error('onResetCardOwner', error);
    }
  };

  const onSaveCardOwner = async () => {
    try {
      await setCardHolder([{ ...holder, cart_product_id: id }]);
    } catch (error) {
      console.error('onSaveCardOwner', error);
    }
  };

  useEffect(() => {
    setEditMode(
      holder?.holder_first_name?.length > 0 &&
        holder?.holder_last_name?.length > 0,
    );

    setEnableSave(
      holder?.holder_first_name !== name ||
        holder?.holder_last_name !== surname,
    );

    //setAllowPayment(!name || !surname);
  }, [holder, name, surname]);

  return (
    <li id={id} className="flex flex-col md:pl-4">
      <div className="flex items-center pt-4 pb-4 space-x-1 text-sm md:pb-0">
        <span
          className={classNames('font-medium text-base', {
            'text-red-500 font-medium': !name && !surname,
          })}
        >
          {locale === 'it' ? 'Titolare Carta' : 'Card Holder'}:
        </span>

        {!name && !surname && (
          <PriorityHighIcon fontSize="small" color="error" />
        )}

        <span className="space-x-1 text-base font-bold uppercase">
          <span>{name}</span>
          <span>{surname}</span>
        </span>
      </div>

      <div
        className={classNames(
          'flex flex-col items-baseline',
          'space-y-4 pb-4',
          'md:flex-row md:space-x-4',
        )}
      >
        <TextField
          fullWidth={isMobile}
          required
          error={holder?.holder_first_name?.length === 0}
          value={holder?.holder_first_name}
          placeholder={holder?.holder_first_name}
          size="small"
          id="outlined-basic"
          label={locale === 'it' ? 'Nome' : 'Name'}
          variant="outlined"
          className="bg-slate-100 dark:bg-zinc-800"
          onChange={({ target }) => {
            setHolder({
              ...holder,
              holder_first_name: target.value,
            });
          }}
        />
        <TextField
          required
          size="small"
          fullWidth={isMobile}
          error={holder?.holder_last_name?.length === 0}
          id="outlined-basic"
          label={locale === 'it' ? 'Cognome' : 'Surname'}
          variant="outlined"
          className="bg-slate-100 dark:bg-zinc-800"
          value={holder?.holder_last_name}
          placeholder={holder?.holder_last_name}
          onChange={({ target }) => {
            setHolder({
              ...holder,
              holder_last_name: target.value,
            });
          }}
        />

        {/* Actions */}
        <div
          className={classNames(
            'flex w-full space-x-3',
            'md:justify-start',
          )}
        >
          {editMode && (
            <Button
              edge="end"
              color="success"
              fontSize="small"
              onClick={onSaveCardOwner}
              variant="contained"
              className={classNames(
                'space-x-2 w-full md:w-auto',
                'text-white bg-green-600',
                { 'opacity-50 pointer-events-none': !enableSave },
              )}
              endIcon={
                <DoneIcon
                  fontSize="small"
                  className="text-green-400"
                />
              }
            >
              Save
            </Button>
          )}
          <Button
            edge="end"
            fontSize="small"
            color="error"
            className="w-full text-white bg-red-500 md:w-auto"
            onClick={onResetCardOwner}
            endIcon={
              <ClearIcon
                fontSize="small"
                className="text-red-300"
              />
            }
          >
            Cancel
          </Button>
        </div>
      </div>
    </li>
  );
}
