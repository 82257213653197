import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { parseFormData } from "@utils";

const baseUrl = process.env.NEXT_PUBLIC_ADITUS + "/orders";

export const OrdersService = createApi({
  reducerPath: "Orders",
  tagTypes: ["Orders"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;

      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
      return headers;
    },
  }),
  onQueryStarted: () => {},
  endpoints: (builder) => ({
    getOrders: builder.query({
      keepUnusedDataFor: 0,
      query: () => "/history",
      providesTags: ["Orders", "Cart"],
      transformResponse: (res, meta, arg) => {
        return res?.data;
      },
    }),
    createOrder: builder.mutation({
      query: (data) => {
        console.log(data);
        if (data) {
          return {
            url: baseUrl,
            method: "POST",
            body: parseFormData(data),
          };
        }
      },
      invalidatesTags: ["Cart"],
      transformResponse: (res) => res,
    }),
  }),
});

export const { useCreateOrderMutation, useGetOrdersQuery } = OrdersService;

export default OrdersService;
