import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function FullScreenLoader() {
  return (
    <div className="relative h-screen w-screen bg-black">
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        <Box sx={{ display: "flex" }} className="fill-primary text-primary">
          <CircularProgress size={20} className="fill-primary text-primary" />
        </Box>
      </div>
    </div>
  );
}
