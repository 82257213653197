import Link from 'next/link';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { useMediaQueries } from '@hooks';

const links = [
  {
    label: {
      it: 'Musei e Parchi',
      en: 'Museums & Parks',
    },
    path: '/esperienze/musei-parchi-archeologici',
  },
  {
    label: {
      it: 'Mostre ed Eventi',
      en: 'Exhibitions & Events',
    },
    path: '/esperienze/mostre-eventi',
  },
  {
    label: {
      it: 'Audioguide e Visite Guidate',
      en: 'Audio Guides & Guided Visits',
    },
    path: '/esperienze/tour-visite-guidate',
  },
];

export default function QuickTypeLinks() {
  const { locale } = useRouter();
  const { isMobile } = useMediaQueries();

  return (
    <div
      className={classNames(
        'relative flex h-16 w-full items-center overflow-hidden bg-slate-100 text-center text-xs dark:bg-zinc-800 md:h-18 md:space-x-4 md:text-sm',
        { 'justify-center': !isMobile },
      )}
    >
      <div className="relative mx-auto grid w-full grid-cols-3 divide-x font-title lg:w-6/12">
        {links.map(({ label, path }, i) => {
          return (
            <Link href={path} passHref key={i}>
              <a className="flex w-auto cursor-pointer justify-center font-medium underline-offset-4 hover:underline">
                {label[locale]}
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
