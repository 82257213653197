import { useGetTopSalesQuery } from '@services';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

export default function RankingBadge({ slug, sticky = false }) {
  const [ranking, setRanking] = useState(null);
  const { data, isLoading, isFetching, isSuccess, isError } =
    useGetTopSalesQuery(null, {
      skip: !slug,
    });

  // i18n
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!ranking && isSuccess && data?.length > 0) {
      const getRanking = data
        .map((c, i) => ({ ...c, rank: i + 1 }))
        .filter((c) => c?.canonical_name === slug)[0];
      setRanking(getRanking);
    }
  }, [isSuccess]);

  if (sticky) {
    return (
      <div
        className={classNames(
          'text-white',
          'mx-auto flex flex-col items-center space-y-1',
          'absolute right-5 top-5 z-10 lg:right-16 lg:top-14',
          
        )}
      >
        {isLoading && <CircularProgress size={20} />}

        {ranking && (
          <>
            <span className="flex items-center space-x-3 text-sm md:flex-col md:space-y-1">
              <span className="relative text-xl font-bold font-title md:text-3xl">
                {ranking?.rank}
                <span className="absolute top-0 text-lg -right-2">
                  °
                </span>
              </span>

              <span className="text-xs uppercase md:w-full">
                {t('product.ranking.label')}
              </span>
            </span>
          </>
        )}
      </div>
    );
  }
  return (
    <div
      className={classNames(
        'mx-auto w-full space-y-1',
        'flex flex-col items-center',
        'absolute -top-14 left-0',
      )}
    >
      {ranking && (
        <>
          <span className="items-center justify-center hidden space-x-3 text-sm 3xl:flex">
            <span className="relative text-xl font-semibold font-title">
              {ranking?.rank}
              <span className="absolute top-0 text-sm -right-1">
                °
              </span>
            </span>
            <span className="flex text-sm uppercase">
              {t('product.ranking.label')}
            </span>
            
          </span>
        </>
      )}
    </div>
  );
}
