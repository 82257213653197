import { CustomTextInput, HandleFormResponse } from '@components';
import {
  hasLetterAndNumber,
  lettersAndSpace,
  onlyLetters,
  onlyNumber,
  validPassword,
  validTelephone,
} from '@utils';
import { useEffect, useState } from 'react';
import {
  useGetCountriesQuery,
  useRegisterAgencyMutation,
} from '@services';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Link from 'next/link';
import MenuItem from '@mui/material/MenuItem';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import every from 'lodash/every';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export default function RegistrationTourOperators({
  className,
  onSuccess,
  onError,
}) {
  const { push, locale } = useRouter();

  // i18n

  const { t } = useTranslation('common');

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [formValues, setFormValues] = useState({
    business_name: '',
    first_name: '',
    last_name: '',
    address: '',
    email: '',
    post_code: '',
    city: '',
    vat: '',
    iban: '',
    phone: '',
    unique_code: '',
    tax_code: '',
    password: '',
    password_confirmation: '',
    language_code: { value: locale, valid: true },
    country_code: { value: 'IT', valid: true },
    privacy: { value: 0, valid: false },
  });

  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();

  const [
    registerAgency,
    { data, isSuccess, isLoading, isFetching, isError },
  ] = useRegisterAgencyMutation();

  const onSubmit = async (formValues) => {
    try {
      if (formValues) {
        const res = await registerAgency(formValues);

        if (res?.data?.success) {
          push('/user/thank-you');
        }
      }
    } catch (error) {
      onError && onError();
      console.error(error);
    }
  };

  const onShowCaptcha = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setShowCaptcha(true);
    } catch (error) {
      console.error('onShowCaptcha', error);
    }
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, 'valid'));
  }, [formValues]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      language_code: { value: locale, valid: true },
    });
  }, [locale]);

  return (
    <div className="flex flex-col items-center justify-center">
      {showCaptcha && (
        <div
          className={classNames('', {
            hidden: (isSuccess && !data?.success) || isError,
          })}
        >
          <span className="block w-full font-medium text-center">
            Compila il reCAPTCHA
          </span>
          <ReCAPTCHA
            hl={locale}
            className="my-6"
            onChange={async (e) => {
              await onSubmit({
                ...formValues,
                'g-recaptcha-response': e,
                domain: window.location.hostname,
              });
            }}
            sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA}
          />
        </div>
      )}

      <HandleFormResponse
        data={data}
        condition={(isSuccess && !data?.success) || isError}
      />

      {!showCaptcha && !isSuccess && (
        <form
          className={['space-y-6', className].join(' ')}
          onSubmit={onSubmit}
        >
          <CustomTextInput
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="business_name"
            label={t('forms.tour_operator.business_name')}
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                business_name: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="first_name"
            label={t('forms.tour_operator.name_referent')}
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                first_name: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="last_name"
            label={t('forms.tour_operator.surname_referent')}
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                last_name: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="last_name"
            label={t('forms.tour_operator.legal_address')}
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                address: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="post_code"
            label={t('forms.post_code')}
            variant="outlined"
            regex={hasLetterAndNumber}
            maxLength={5}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                post_code: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="city"
            label={t('forms.city')}
            variant="outlined"
            regex={lettersAndSpace}
            maxLength={30}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                city: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            name="vat"
            reset={true}
            label={t('forms.vat')}
            maxLength={35}
            margin="dense"
            className="my-1"
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t('forms.validation.no_special_characters')}
            onSetValue={(value) =>
              setFormValues({ ...formValues, vat: value })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            name="tax_code"
            reset={true}
            label={t('forms.fiscal_code')}
            maxLength={35}
            margin="dense"
            className="my-1"
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                tax_code: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            name="iban"
            reset={true}
            label={t('forms.iban')}
            maxLength={35}
            margin="dense"
            className="my-1"
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                iban: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            name="unique_code"
            reset={true}
            label={t('forms.tour_operator.unique_code')}
            maxLength={7}
            margin="dense"
            className="my-1"
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                unique_code: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="text"
            name="phone"
            reset={true}
            label={t('forms.phone')}
            maxLength={40}
            margin="dense"
            className="my-1"
            variant="outlined"
            regex={validTelephone}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                phone: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            type="email"
            name="email"
            reset={true}
            label={t('forms.email')}
            maxLength={60}
            margin="dense"
            className="my-1"
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                email: value,
              })
            }
          />

          {isCountriesSuccess && countries?.length > 0 && (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t('forms.nation')}
              </InputLabel>
              <Select
                required
                size="small"
                value={formValues?.country_code?.value}
                label={t('forms.nation')}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    country_code: {
                      value: e.target.value,
                      valid: true,
                    },
                  })
                }
              >
                {countries.map(({ code, name }) => (
                  <MenuItem value={code}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            className="my-1"
            type="password"
            name="password"
            label={t('forms.password')}
            variant="outlined"
            regex={validPassword}
            // placeholder="Placeholder"
            autoComplete="new-password"
            helperText={t('forms.validations.password_rules')}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                password: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            className="my-1"
            type="password"
            name="password"
            label={t('forms.confirm_password')}
            autoComplete="new-password"
            variant="outlined"
            regex={validPassword}
            helperText={t('forms.validations.password_must_match')}
            customCondition={(x, y) => x === y}
            toCompare={formValues?.password}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                password_confirmation: value,
              })
            }
          />

          <FormControl className="px-2">
            <FormControlLabel
              label={
                <span className="text-xs md:text-sm">
                  {t('forms.accept_privacy')}
                  <a
                    className="mx-1 font-bold underline underline-offset-4"
                    href="/documents/M-GDPR-30-informativa-sito-internet.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy
                  </a>
                </span>
              }
              control={
                <Checkbox
                  checked={Boolean(formValues.privacy.value)}
                  onChange={(e) => {
                    const val = e.target.checked;
                    setFormValues({
                      ...formValues,
                      privacy: {
                        value: +val,
                        valid: val,
                      },
                    });
                  }}
                />
              }
            />
          </FormControl>

          <Button
            type="submit"
            color="primary"
            onClick={onShowCaptcha}
            disabled={!allowSubmit}
            className={classNames(
              'h-10 w-full rounded-none bg-primary text-white transition delay-150 ease-in-out',
              {
                'cursor-not-allowed opacity-50': !allowSubmit,
              },
            )}
          >
            {t('buttons.sign_up')}
          </Button>

          <span className="flex justify-center text-sm">
            {t('forms.already_registered')}
            <Link href="/user/login" passHref>
              <a className="mx-1 text-primary">
                {t('buttons.login')}
              </a>
            </Link>
          </span>
        </form>
      )}
    </div>
  );
}
