import Button from '@mui/material/Button';
import { FC } from 'react';
import { setDrawerQuickBuyStatus } from '@slices';
import { useDispatch } from 'react-redux';

interface QuickBuyButtonProps {
  className?: string;
  label?: string;
}

const QuickBuyButton: FC<QuickBuyButtonProps> = ({
  className,
  label,
}) => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(setDrawerQuickBuyStatus({ open: true }));

  return (
    <Button
      className={className}
      onClick={onClick}
      size="small"
      id="quick-buy-btn"
    >
      {label}
    </Button>
  );
};

export default QuickBuyButton;
