import { CustomTextInput, HandleFormResponse } from "@components";
import { ReactElement, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import every from "lodash/every";
import { useRecoverPwdMutation } from "@services";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { validEmail } from "@utils";

type RecoverFormProps = {
  className?: string;
  onSuccess?: () => void;
  onError?: () => void;
  LoginButton?: ReactElement;
};

export default function RecoverForm({ className, onSuccess, onError, LoginButton }: RecoverFormProps) {
  const { locale } = useRouter();
  // i18n
  const { t } = useTranslation("common");

  const [formValues, setFormValues] = useState({
    email: { value: "", valid: false },
    language_code: { value: locale, valid: true },
  });
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const [userRecover, { data, isLoading, isSuccess, isError }] = useRecoverPwdMutation();

  // Events
  const onSubmit = async (formValues) => {
    try {
      onSuccess && onSuccess();
      await userRecover(formValues);
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  const onShowCaptcha = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setShowCaptcha(true);
    } catch (error) {
      console.error("onShowCaptcha", error);
    }
  };

  const onChangeCaptcha = async (e) => {
    try {
      await onSubmit({
        ...formValues,
        "g-recaptcha-response": e,
      });
    } catch (error) {
      console.error("onSubmitCaptcha", error);
    }
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, "valid"));
  }, [formValues]);

  return (
    <>
      {showCaptcha && !isSuccess && (
        <ReCAPTCHA
          hl={locale}
          onChange={onChangeCaptcha}
          sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA}
          className="my-6 flex items-center justify-center"
        />
      )}

      {!showCaptcha && (
        <form className={["relative space-y-2", className].join(" ")}>
          <CustomTextInput
            className="my-1"
            fullWidth
            label="Email"
            margin="dense"
            name="email"
            regex={validEmail}
            required
            reset={true}
            type="text"
            variant="outlined"
            helperText="Caratteri speciali non consentiti"
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                email: value,
              })
            }
          />

          {isLoading ? (
            <LoadingButton
              loading
              className={classNames("bg-primary text-white", "h-10 w-full rounded-none", "transition-all delay-150 ease-in-out")}
            />
          ) : (
            <Button
              type="submit"
              color="primary"
              onClick={onShowCaptcha}
              disabled={!allowSubmit}
              className={classNames("bg-primary text-white", "h-10 w-full rounded-none", "transition-all delay-150 ease-in-out", {
                "cursor-not-allowed opacity-50": !allowSubmit,
              })}
            >
              {t("buttons.send")}
            </Button>
          )}

          {LoginButton && LoginButton}
        </form>
      )}

      <HandleFormResponse data={data} isError={isError} isSuccess={isSuccess} successMessage={t("forms.response.recover_success")} />
    </>
  );
}
