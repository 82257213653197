import Divider from '@mui/material/Divider';
import { Element } from 'react-scroll';
import classNames from 'classnames';

export default function Anchor({
  name,
  title,
  children,
  counter = null,
}) {
  const containerCls = classNames(
    'group',
    'relative w-full',
    'my-16 xl:my-20 3xl:my-24',
    'space-y-4 md:space-y-12 3xl:space-y-14',
  );

  if (name) {
    return (
      <Element
        name={name}
        duration={700}
        delay={900}
        className={containerCls}
      >
        <Divider
          className="uppercase text-primary"
          textAlign="left"
        >
          <h3
            className={classNames(
              'w-fit',
              'text-primary',
              'text-base md:text-xl',
              'font-title font-extrabold',
              'uppercase tracking-widest',
            )}
          >
            {title}
            {counter && counter > 0 ? (
              <span
                className={classNames(
                  'mx-1.5 opacity-50',
                  'text-base font-light',
                  'text-gray-700 dark:text-white',
                )}
              >
                ({counter})
              </span>
            ) : null}
          </h3>
        </Divider>
        {children}
      </Element>
    );
  }

  return (
    <div className={containerCls}>
      <Divider className="uppercase text-primary" textAlign="left">
        <h3
          className={classNames(
            'text-primary',
            'font-title font-bold',
            'text-base md:text-lg',
            'tracking-widest uppercase',
          )}
        >
          {title}
        </h3>
      </Divider>
      {children}
    </div>
  );
}
