import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ScienceIcon from '@mui/icons-material/Science';
import ExtensionIcon from '@mui/icons-material/Extension';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';

export default function SchoolActivityTypes({ types }) {
  if (types) {
    return (
      <div className="space-y-6">
        {types?.data?.map(({ attributes: a }, i) => {
          const toCheck = a?.slug?.toLowerCase().trim();
          const isLab = ['laboratorio', 'laboratory'];
          const isVisit = ['visita-tematica', 'thematic-visit'];
          const isTour = [
            'visita-guidata',
            'visita-guidata-combinata',
            'thematic-route',
            'percorso-tematico',
          ];
          const isPlay = ['visita-gioco'];

          return (
            <div
              key={i}
              className="flex flex-row items-center space-x-4 "
            >
              <div>
                {isLab.includes(toCheck) && <ScienceIcon />}
                {isVisit.includes(toCheck) && <HistoryEduIcon />}
                {isTour.includes(toCheck) && <EmojiFlagsIcon />}
                {isPlay.includes(toCheck) && <ExtensionIcon />}
              </div>
              <div className="text-base font-medium capitalize dark:text-white">
                {a?.name}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}
