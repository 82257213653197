import CheckIcon from "@mui/icons-material/Check";
import classNames from "classnames";
import { scroller } from "react-scroll";
import sortBy from "lodash/sortBy";

type Facility = {
  name: string;
  anchor?: boolean;
};

type OnSiteServicesProps = {
  facilities: Facility[];
};

export default function Facilities({ facilities = [] }: OnSiteServicesProps) {
  return facilities?.length > 0 ? (
    <ul className="text-gray-700 list-inside dark:text-white md:ml-10">
      {sortBy(facilities, "name").map(({ name }) => (
        <li className="flex items-center h-8 space-x-3 md:h-10" key={name}>
          <CheckIcon className="w-8 fill-green-600" fontSize="small" />
          <span className="text-sm uppercase">{name}</span>
        </li>
      ))}
    </ul>
  ) : null;
}
