import { getCleanedPaths } from '@utils';
import { RelatedProductsCard, Collapsible} from '@components';

export default function RelatedEvents({ list }) {
  if (list?.length > 0) {
    return (
      <Collapsible
        className="space-y-4 md:space-y-10"
        anchor="temporaries"
        hide={list?.length < 4}
      >
        {list.map((prod, i) => {
          const { name, city, slug = null, hero } = prod;
          const { cityPath, prodPath } = getCleanedPaths({
            slug,
            city: city?.data?.attributes?.slug,
            typeSegment: 'mostre-eventi/',
          });

          return (
            <RelatedProductsCard
              key={i}
              {...prod}
              slug={slug}
              cityLink={cityPath}
              cityName={city?.data?.attributes?.name}
              prodLink={prodPath}
              prodName={name}
              img={hero?.media?.data?.attributes}
            />
          );
        })}
      </Collapsible>
    );
  }

  return null;
}
