import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineSeparator from "@mui/lab/TimelineSeparator";

export default function Path({ list }) {
  return (
    <Timeline
      className="py-0"
      sx={{
        minHeight: 50,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem className="h-16">
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="grey" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className="flex flex-col">
          <span className="font-semibold">Inizio</span>
        </TimelineContent>
      </TimelineItem>
      {list.map(({ name, info }, i) => (
        <TimelineItem key={i}>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="flex flex-col">
            <span className="font-semibold">{name}</span>
            {info && <span className="text-sm font-light">{info}</span>}
          </TimelineContent>
        </TimelineItem>
      ))}
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="grey" />
        </TimelineSeparator>
        <TimelineContent className="flex flex-col">
          <span className="font-semibold">Fine</span>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
