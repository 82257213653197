import { Collapsible, RelatedProductsCard } from '@components';

export default function RelatedDidactic({ list, city }) {
  if (list?.length > 0) {
    return (
      <Collapsible anchor="school" hide={list?.length < 4}>
        {list.map(({ attributes }, i) => {
          return (
            <RelatedProductsCard
              key={i}
              {...attributes}
              img={attributes?.hero?.media?.data?.attributes}
              prodLink={`/didattica/${city}/${attributes?.slug}`}
              prodName={attributes?.name}
              abstract={attributes?.description}
            />
          );
        })}
      </Collapsible>
    );
  }

  return null;
}
