import { Fragment, useEffect, useState } from "react";
import { drawerMenuSelector, setDrawerMenuStatus } from "@slices";
import { logoutReducer, userSelector } from "@slices";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Facebook from "@mui/icons-material/Facebook";
import IconButton from "@mui/material/IconButton";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TikTok from "./../../../public/icons/tiktok.svg";
import Twitter from "@mui/icons-material/Twitter";
import YouTube from "@mui/icons-material/YouTube";
import data from "./drawer.data";
import { toggleBlurLayout } from "@utils";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

export default function DrawerMenu() {
  const router = useRouter();
  const dispatch = useDispatch();
  const isDrawerMenuOpen = useSelector(drawerMenuSelector);
  const [open, setOpen] = useState(true);

  const { t } = useTranslation("common");

  const user = useSelector(userSelector);

  useEffect(() => {
    toggleBlurLayout(null, isDrawerMenuOpen);
    return () => toggleBlurLayout(null, false);
  }, [isDrawerMenuOpen]);

  const handleClick = () => setOpen(!open);
  const onCloseDrawer = () => dispatch(setDrawerMenuStatus({ isOpen: false }));

  const onOpenLoginPage = () => {
    onCloseDrawer();
    router.push("/user/login");
  };

  const onOpenRegistrationPage = () => {
    onCloseDrawer();
    router.push("/user/registration");
  };

  return (
    <SwipeableDrawer
      elevation={0}
      anchor="right"
      variant="temporary"
      hideBackdrop={false}
      open={isDrawerMenuOpen}
      onClose={onCloseDrawer}
      onOpen={toggleBlurLayout}
      disableSwipeToOpen={false}
      ModalProps={{ keepMounted: true }}
      transitionDuration={{ enter: 200, exit: 300 }}
      sx={{
        "& .MuiDrawer-paper": {
          background: "none",
          border: "none",
        },
      }}
    >
      <div className="flex h-screen w-72 flex-col bg-zinc-100 dark:bg-black ">
        <div className="relative flex h-10 w-full items-center justify-end md:h-14">
          <IconButton
            color="inherit"
            component="span"
            onClick={onCloseDrawer}
            className="absolute left-3 top-3 flex h-10 w-10 cursor-pointer items-center justify-center text-xl"
          >
            <Close fontSize="small" />
          </IconButton>
        </div>

        <List className="mt-2 md:mt-4" dense={true} component="nav">
          {data.map(({ path, label, sublinks }, i) => {
            return sublinks && sublinks?.length > 0 ? (
              <Fragment key={i}>
                <ListItemButton onClick={handleClick}>
                  <ListItemText primary={label[router?.locale]} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {sublinks.map((voice, y) => (
                      <ListItemButton key={y}>
                        <span
                          className="flex text-xs"
                          onClick={() => {
                            dispatch(
                              setDrawerMenuStatus({
                                isOpen: false,
                              }),
                            );
                            router.push(voice.path);
                          }}
                        >
                          {voice.label[router?.locale]}
                        </span>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <ListItemButton key={i}>
                <ListItemText
                  primary={label[router?.locale]}
                  onClick={() => {
                    dispatch(setDrawerMenuStatus());
                    router.push(path);
                  }}
                />
              </ListItemButton>
            );
          })}
        </List>

        <div>
          <Divider className="mx-auto w-10/12 uppercase" textAlign="left">
            <span className="font-medium text-primary">Contacts</span>
          </Divider>
          <ul className="my-6 list-outside list-disc space-y-1 px-6">
            <li className="flex w-full items-center justify-between p-1 text-sm">
              <a className="underline underline-offset-4" href="mailto:info@aditusculture.com">
                info@aditusculture.com
              </a>
            </li>
            <li className="flex w-full items-center justify-between p-1 text-sm">Via Caboto, 35 10129 Torino</li>
          </ul>
        </div>

        <div>
          <Divider className="mx-auto w-10/12 uppercase" textAlign="left">
            <span className="font-medium text-primary">Social</span>
          </Divider>
        </div>

        <ul className="m-5 flex items-end justify-around space-y-2 text-sm">
          <li>
            <a href="https://www.instagram.com/aditusculture/" target="_blank" rel="noreferrer">
              <Instagram className="w-6" />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/AditusCulture/" target="_blank" rel="noreferrer">
              <Facebook classnames="w-6" />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCIYt7Vdup150pVhwTnyooEA" target="_blank" rel="noreferrer">
              <YouTube className="w-6" />
            </a>
          </li>
          <li>
            <a href="https://it.linkedin.com/company/aditus-culture" target="_blank" rel="noreferrer">
              <LinkedIn className="w-6" />
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@aditusculture?_t=8XBJ5nLRFRe&_r=1" target="_blank" rel="noreferrer">
              <TikTok className="h-5 w-6 fill-black dark:fill-white" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/aditusculture" target="_blank" rel="noreferrer">
              <Twitter className="w-6" />
            </a>
          </li>
        </ul>

        <div className="absolute bottom-8 left-0 right-0 flex flex-col items-center justify-evenly space-y-2 px-8">
          {!user?.logged ? (
            <>
              <Button
                size="small"
                onClick={onOpenRegistrationPage}
                className="mx-auto w-full rounded-md border bg-primary font-semibold uppercase text-white"
              >
                {t("buttons.sign_up")}
              </Button>
              <Button
                size="small"
                onClick={onOpenLoginPage}
                className="mx-auto w-full rounded-md border bg-primary font-semibold uppercase text-white"
              >
                {t("buttons.login")}
              </Button>
            </>
          ) : (
            <Button
              size="small"
              onClick={() => dispatch(logoutReducer())}
              className="mx-auto w-full rounded-md border bg-primary font-semibold uppercase text-white"
            >
              Logout
            </Button>
          )}
        </div>
      </div>
    </SwipeableDrawer>
  );
}
