import { useEffect, useState } from "react";

const useVisibility = () => {
  const [hidden, setHidden] = useState(null);
  const [visibility, setVisibility] = useState(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (typeof document.hidden !== "undefined") {
      setHidden("hidden");
      setVisibility("visibilitychange");
    } else if (typeof document.msHidden !== "undefined") {
      setHidden("msHidden");
      setVisibility("msvisibilitychange");
    } else if (typeof document.webkitHidden !== "undefined") {
      setHidden("webkitHidden");
      setVisibility("webkitvisibilitychange");
    }
  }, []);

  function handleVisibilityChange() {
    try {
      setVisible(document[hidden]);
    } catch (error) {
      console.error("handleVisibilityChange", error);
    }
  }

  useEffect(() => {
    document.addEventListener(visibility, handleVisibilityChange);
    return () => document.removeEventListener(visibility, handleVisibilityChange);
  }, [visibility]);

  return [isVisible];
};

export default useVisibility;
