import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classNames from 'classnames';


export default function Conclusion({ content }) {
  if (content && content?.length > 0) {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        className={classNames(
          'strapi-editorial',
          'leading-relaxed article-conclusion',
          'text-gray-700  dark:text-white',
          'text-base md:text-lg 2xl:text-xl',
          'px-2',
        )}
      >
        {content}
      </ReactMarkdown>
    );
  }
  return null;
}
