import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { parseFormData } from "@utils";

const baseUrl = process.env.NEXT_PUBLIC_ADITUS;

export const AuthService = createApi({
  reducerPath: "Auth",
  tagTypes: ["Auth", "Cart"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
      return headers;
    },
  }),
  onQueryStarted: () => {
    console.log("onQueryStarted");
  },
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/login`,
          method: "POST",
          body: parseFormData(data),
        };
      },
      invalidatesTags: ["Cart"],
      transformResponse: (res, meta, arg) => {
        return res;
      },
    }),
    logoutUser: builder.mutation({
      query: () => {
        return {
          url: `/auth/logout`,
          method: "POST",
        };
      },
      invalidatesTags: ["Cart"],
      transformResponse: (res, meta, arg) => {
        return res;
      },
    }),

    userActivation: builder.mutation({
      query: ({ token }) => {
        return {
          url: "/users/activate",
          method: "POST",
          body: parseFormData({ token }),
        };
      },
    }),
    // Password Recovery
    recoverPwd: builder.mutation({
      // invalidatesTags: ['Auth'],
      query: (data) => {
        return {
          url: `/users/password-recovery`,
          method: "POST",
          body: parseFormData(data),
        };
      },
      transformResponse: (res, meta, arg) => {
        return res;
      },
    }),
  }),
});

export const { useLoginUserMutation, useRecoverPwdMutation, useLogoutUserMutation, useUserActivationMutation } = AuthService;

export default AuthService;
