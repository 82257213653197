import { Header, LoginForm } from "@components";
import { useTranslation } from "next-i18next";

export default function AuthWall() {
  const { t } = useTranslation("common");

  return (
    <div className="relative flex flex-col items-center justify-center mt-10 mb-16">
      <Header
        showDescription={true}
        title={t("components.auth_wall.title")}
        label={t("components.auth_wall.label")}
        description={t("components.auth_wall.text")}
      />
      <LoginForm className="w-full p-4 md:w-3/5 lg:w-6/12" />
    </div>
  );
}
