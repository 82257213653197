import { Header, Section } from '@components';
import {
  useGetStrapiCitiesQuery,
  useGetStrapiItinerantFiltersTypesQuery,
  useGetStrapiSiteFiltersTypesQuery,
  useGetStrapiTempFiltersTypesQuery,
} from '@services';

import Chip from '@mui/material/Chip';
import sampleSize from 'lodash/sampleSize';
import sortBy from 'lodash/sortBy';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

export default function TagsCloud() {
  const { locale, push } = useRouter();
  const { t } = useTranslation('common');
  const { isLargeScreen } = useMediaQueries();

  const {
    data: cities,
    isSuccess: isCitiesSuccess,
    isLoading: isCitiesLoading,
  } = useGetStrapiCitiesQuery({ locale }, { skip: !isLargeScreen });

  const {
    data: siteTypes,
    isSuccess: isSiteTypesSuccess,
    isLoading: isSiteTypesLoading,
  } = useGetStrapiSiteFiltersTypesQuery(
    { locale },
    { skip: !isLargeScreen },
  );

  const {
    data: tempTypes,
    isSuccess: isTempTypesSuccess,
    isLoading: isTempTypesLoading,
  } = useGetStrapiTempFiltersTypesQuery(
    { locale },
    { skip: !isLargeScreen },
  );

  const {
    data: itinerantTypes,
    isSuccess: isItinerantTypesSuccess,
    isLoading: isItinerantTypesLoading,
  } = useGetStrapiItinerantFiltersTypesQuery(
    { locale },
    { skip: !isLargeScreen },
  );

  if (isLargeScreen) {
    return (
      <Section
        id="tags-cloud"
        isLoading={
          isCitiesLoading &&
          isSiteTypesLoading &&
          isTempTypesLoading &&
          isItinerantTypesLoading
        }
      >
        <Header title={t('sections.tagsCloud.title')} />
        <div className="flex flex-wrap justify-center w-full mx-auto lg:max-w-5xl">
          {isCitiesSuccess &&
            isSiteTypesSuccess &&
            isTempTypesSuccess &&
            isItinerantTypesSuccess &&
            sortBy(sampleSize(cities, 8), 'attributes.name')?.map(
              ({ attributes }, i) => {
                const { name: city, slug } = attributes;
                return sampleSize(
                  [...siteTypes, ...tempTypes, ...itinerantTypes],
                  5,
                )?.map(({ name }, y) => (
                  <Chip
                    key={y + i}
                    variant="outlined"
                    label={city + '  ' + name}
                    className="m-1 rounded-2xl md:m-1.5"
                    onClick={() => push('/esperienze/' + slug)}
                  />
                ));
              },
            )}
        </div>
      </Section>
    );
  }

  return null;
}
