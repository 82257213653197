import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { parseFormData } from "@utils";

const baseUrl = process.env.NEXT_PUBLIC_ADITUS + "/users";

export const UserService = createApi({
  reducerPath: "User",
  tagTypes: ["User"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
      return headers;
    },
  }),
  onQueryStarted: () => {},
  endpoints: (builder) => ({
    // GET USER DATA QUERY
    getUserData: builder.query({
      query: (id) => `/external-user/${id}`,
      transformResponse: (res, meta, arg) => {
        return res.data;
      },
    }),

    // STORE NEW USER
    registerUser: builder.mutation({
      query: (data) => {
        if (data) {
          return {
            url: baseUrl + "/external-user/",
            method: "POST",
            body: parseFormData(data),
          };
        }
      },
      transformResponse: (res) => res,
    }),
    resetPassword: builder.mutation({
      query: (data) => {
        if (data) {
          return {
            url: baseUrl + "/password-reset",
            method: "POST",
            body: parseFormData(data),
          };
        }
      },
      transformResponse: (res) => res,
    }),
    // UPDATE USER DATA
    updateUserData: builder.mutation({
      invalidatesTags: ["User"],
      query: ({ id, data }) => {
        return {
          url: `/external-user/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (res, meta, arg) => {
        return res;
      },
    }),
  }),
});

export const { useGetUserDataQuery, useUpdateUserDataMutation, useRegisterUserMutation, useResetPasswordMutation } = UserService;

export default UserService;
