import { CustomTextInput, HandleFormResponse } from '@components';
import {
  hasLetterAndNumber,
  lettersAndSpace,
  onlyLetters,
  onlyNumber,
  validPassword,
  validTelephone,
} from '@utils';
import { useEffect, useState } from 'react';
import {
  useGetCountriesQuery,
  useRegisterSchoolMutation,
} from '@services';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Link from 'next/link';
import MenuItem from '@mui/material/MenuItem';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import every from 'lodash/every';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export default function RegistrationSchool({
  className,
  onSuccess,
  onError,
}) {
  const { locale, push } = useRouter();

  // io18n
  const { t } = useTranslation('common');

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);

  const [formValues, setFormValues] = useState({
    school_name: { value: '', valid: true },
    address: '',
    email: '',
    post_code: null,
    city: '',
    first_name_principal: '',
    last_name_principal: '',
    first_name_referent: '',
    last_name_referent: '',
    school_phone: '',
    password: '',
    password_confirmation: '',
    language_code: { value: locale, valid: true },
    country_code: { value: 'IT', valid: true },
    cig: { value: '', valid: true },
    privacy: { value: 0, valid: false },
  });

  const [
    registerSchool,
    { data, isSuccess, isLoading, isFetching, isError },
  ] = useRegisterSchoolMutation();

  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();

  const onSubmit = async (formValues) => {
    try {
      if (formValues) {
        const res = await registerSchool(formValues);
        if (res?.data?.success) {
          push('/user/thank-you');
        }
      }
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, 'valid'));
  }, [formValues]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      language_code: { value: locale, valid: true },
    });
  }, [locale]);

  const onShowCaptcha = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setShowCaptcha(true);
    } catch (error) {
      console.error('onShowCaptcha', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {showCaptcha && (
        <div
          className={classNames('', {
            hidden: (isSuccess && !data?.success) || isError,
          })}
        >
          <span className="block w-full text-center font-medium">
            Compila il reCAPTCHA
          </span>
          <ReCAPTCHA
            hl={locale}
            className="my-6"
            onChange={async (e) => {
              await onSubmit({
                ...formValues,
                'g-recaptcha-response': e,
                domain: window.location.hostname,
              });
            }}
            sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA}
          />
        </div>
      )}

      <HandleFormResponse
        data={data}
        condition={(isSuccess && !data?.success) || isError}
      />

      {!showCaptcha && !isSuccess && (
        <>
          {/* <SchoolCode
            onEnableSubmit={({ school_name, address, post_code, city }) => {
              setFormValues({
                school_name: { value: school_name, valid: true },
                address: { value: address, valid: true },
                post_code: { value: post_code, valid: true },
                city: { value: city, valid: true },
                ...formValues,
              });
            }}
          /> */}

          <form className={['space-y-6', className].join(' ')}>
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="school_name"
              label={t('forms.school.school_name')}
              variant="outlined"
              defaultValue={formValues?.school_name?.value}
              regex={hasLetterAndNumber}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  school_name: value,
                })
              }
            />
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="lastname"
              label={t('forms.school.school_address')}
              maxLength={100}
              variant="outlined"
              defaultValue={formValues?.address?.value}
              regex={hasLetterAndNumber}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  address: value,
                })
              }
            />
            <CustomTextInput
              required
              fullWidth
              type="email"
              name="email"
              reset={true}
              label={t('forms.school.school_email')}
              maxLength={60}
              margin="dense"
              className="my-1"
              variant="outlined"
              regex={hasLetterAndNumber}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  email: value,
                })
              }
            />
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              defaultValue={formValues?.post_code?.value}
              name={t('forms.post_code')}
              label="CAP"
              variant="outlined"
              regex={onlyNumber}
              maxLength={5}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={({ value, valid }) => {
                setFormValues({
                  ...formValues,
                  post_code: {
                    value: Number(value),
                    valid,
                  },
                });
              }}
            />
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="city"
              label={t('forms.city')}
              variant="outlined"
              defaultValue={formValues?.city?.value}
              regex={lettersAndSpace}
              maxLength={30}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  city: value,
                })
              }
            />
            <CustomTextInput
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="cig"
              label="CIG"
              variant="outlined"
              regex={onlyLetters}
              maxLength={30}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  cig: value,
                })
              }
            />
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="first_name_principal"
              label={t('forms.school.principal_name')}
              variant="outlined"
              regex={hasLetterAndNumber}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  first_name_principal: value,
                })
              }
            />
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="last_name_principal"
              label={t('forms.school.principal_surname')}
              variant="outlined"
              regex={onlyLetters}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  last_name_principal: value,
                })
              }
            />
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="first_name_referent"
              label={t('forms.school.name_referent')}
              variant="outlined"
              regex={hasLetterAndNumber}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  first_name_referent: value,
                })
              }
            />
            <CustomTextInput
              required
              fullWidth
              type="text"
              reset={true}
              margin="dense"
              className="my-1"
              name="last_name_referent"
              label={t('forms.school.surname_referent')}
              variant="outlined"
              regex={onlyLetters}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  last_name_referent: value,
                })
              }
            />
            <span className="relative flex flex-col">
              <span className="text-xs font-semibold md:text-sm">
                * {t('forms.school.email_helper')}
              </span>
              <CustomTextInput
                required
                fullWidth
                type="email"
                name="email"
                reset={true}
                label={t('forms.school.email_referent')}
                maxLength={60}
                margin="dense"
                className="my-1"
                variant="outlined"
                regex={hasLetterAndNumber}
                helperText={t(
                  'forms.validations.no_special_characters',
                )}
                onSetValue={(value) =>
                  setFormValues({
                    ...formValues,
                    email: value,
                  })
                }
              />
            </span>

            <CustomTextInput
              required
              fullWidth
              type="text"
              name="school_phone"
              reset={true}
              label={t('forms.phone')}
              maxLength={40}
              margin="dense"
              className="my-1"
              variant="outlined"
              regex={validTelephone}
              helperText={t(
                'forms.validations.no_special_characters',
              )}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  school_phone: value,
                })
              }
            />

            {isCountriesSuccess && countries?.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t('forms.nation')}
                </InputLabel>
                <Select
                  required
                  size="small"
                  value={formValues?.country_code?.value}
                  label={t('forms.nation')}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      country_code: {
                        value: e.target.value,
                        valid: true,
                      },
                    })
                  }
                >
                  {countries.map(({ code, name }) => (
                    <MenuItem value={code}>{name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <CustomTextInput
              required
              fullWidth
              reset={true}
              margin="dense"
              className="my-1"
              type="password"
              name="password"
              label={t('forms.password')}
              variant="outlined"
              regex={validPassword}
              autoComplete="new-password"
              helperText={t('forms.validations.password_rules')}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  password: value,
                })
              }
            />
            <CustomTextInput
              required
              fullWidth
              reset={true}
              margin="dense"
              className="my-1"
              type="password"
              name="password"
              label={t('forms.confirm_password')}
              autoComplete="new-password"
              variant="outlined"
              regex={validPassword}
              helperText={t(
                'forms.validations.password_must_match',
              )}
              customCondition={(x, y) => x === y}
              toCompare={formValues?.password}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  password_confirmation: value,
                })
              }
            />
            <FormControl className="px-2">
              <FormControlLabel
                label={
                  <span className="text-xs md:text-sm">
                    {t('forms.accept_privacy')}
                    <a
                      className="mx-1 font-bold underline underline-offset-4"
                      href="/documents/M-GDPR-30-informativa-sito-internet.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                }
                control={
                  <Checkbox
                    checked={Boolean(formValues.privacy.value)}
                    onChange={(e) => {
                      const val = e.target.checked;
                      setFormValues({
                        ...formValues,
                        privacy: {
                          value: +val,
                          valid: val,
                        },
                      });
                    }}
                  />
                }
              />
            </FormControl>
            <Button
              type="submit"
              color="primary"
              onClick={onShowCaptcha}
              disabled={!allowSubmit}
              className={classNames(
                'h-10 w-full rounded-none bg-primary text-white transition delay-150 ease-in-out',
                {
                  'cursor-not-allowed opacity-50': !allowSubmit,
                },
              )}
            >
              {t('buttons.sign_up')}
            </Button>
            <span className="flex justify-center text-sm">
              {t('forms.already_registered')}
              <Link href="/user/login" passHref>
                <a className="mx-1 text-primary">
                  {t('buttons.login')}
                </a>
              </Link>
            </span>
          </form>
        </>
      )}
    </div>
  );
}
