export default [
  {
    rule: { it: '30 domande', en: '30 questions' },
    label: {
      it: 'Tratte dai nostri articoli',
      en: 'Taken from our articles',
    },
    emoji: '❓',
    icon: 'question',
    gradient: 'bg-red-50 dark:bg-red-900',
  },
  {
    rule: {
      it: '30 secondi per domanda',
      en: '30 seconds per question',
    },
    label: { it: 'Ti sfidiamo!', en: 'We dare you!' },
    emoji: '⏲️',
    icon: 'time',
    gradient: 'bg-blue-50 dark:bg-blue-900',
  },
  {
    rule: { it: 'Tantissima storia', en: 'Lots of history' },
    label: {
      it: 'Tutta la nostra offerta culturale!',
      en: 'All our cultural offer!',
    },
    emoji: '🏛️',
    icon: 'culture',
    gradient: 'bg-grey-50 dark:bg-grey-900',
  },
  /*   {
    rule: 'Troppe cose da sapere',
    label: 'Già',
    emoji: '🤯',
    gradient: 'bg-primary-50 dark:bg-primary-900',
  }, */
];
