import AccessTime from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import Delete from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Place from '@mui/icons-material/Place';
import classNames from 'classnames';
import dayjs from 'dayjs';
import dropRight from 'lodash/dropRight';
import kebabCase from 'lodash/kebabCase';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'next-i18next';
import CardOwner from './../CardOwner/CardOwner';
import Tooltip from '@mui/material/Tooltip';

dayjs.extend(localizedFormat);

export default function CartProduct({
  item,
  user,
  cart,
  loading,
  handleOpenDialog,
  setIdsToRemove,
  setAllowPayment,
}) {
  // n18i
  const { t } = useTranslation('common');

  return (
    <div className="relative mb-10 space-y-4 100vw">
      {/* Line Head Date  */}
      <Divider className="mx-auto font-medium" textAlign="left">
        <Chip
          variant="outlined"
          label={dayjs(item?.date).format('LL')}
          className="px-6 font-medium w-fit rounded-xl"
        />
      </Divider>

      <div
        className={classNames('relative h-full w-full', {
          'animate-pulse opacity-30 duration-75 ease-in-out':
            loading,
        })}
      >
        {item?.list.map(
          ({
            name,
            city,
            address,
            products,
            site_slug,
            in_concession,
            is_temporary,
            service,
            subtotal,
            ids,
            isCard,
          }) => {
            const cleanedCityName = kebabCase(city?.toLowerCase());
            return (
              <div
                key={name}
                className={classNames(
                  'relative flex flex-col',
                  'bg-slate-100 dark:bg-zinc-800',
                  'mb-4 space-y-2 p-4 lg:p-8',
                )}
              >
                {/* Delete Product Button */}
                <Button
                  size="small"
                  variant="text"
                  onClick={() => {
                    setIdsToRemove(ids);
                    handleOpenDialog();
                  }}
                  className={classNames(
                    'text-xs md:flex',
                    'absolute right-4 top-5 hidden',
                    'text-red-700 dark:text-red-600',
                  )}
                >
                  {t('buttons.delete')}
                </Button>

                <div className="mb-2">
                  {in_concession && site_slug && (
                    <Link
                      passHref
                      href={{
                        pathname: [
                          '',
                          'esperienze',
                          '[city]',
                          'musei-parchi-archeologici',
                          '[slug]',
                        ].join('/'),

                        query: {
                          city: cleanedCityName,
                          slug: site_slug,
                        },
                      }}
                    >
                      <a
                        className={classNames(
                          'flex ',
                          'md:max-w-md md:text-xl',
                          'font-title font-bold uppercase',
                          'underline-offset-4 hover:underline',
                        )}
                      >
                        {name}
                      </a>
                    </Link>
                  )}

                  {!in_concession &&
                    is_temporary &&
                    service?.slug && (
                      <Link
                        passHref
                        href={{
                          pathname:
                            '/esperienze/[city]/mostre-eventi/[slug]',
                          query: {
                            city: cleanedCityName,
                            slug: service?.slug,
                          },
                        }}
                      >
                        <a
                          className={classNames(
                            'flex ',
                            'uppercase md:text-xl',
                            'font-title font-bold',
                            'underline-offset-4 hover:underline',
                          )}
                        >
                          {name}
                        </a>
                      </Link>
                    )}

                  {!in_concession &&
                    is_temporary &&
                    !service?.slug && (
                      <span className="flex font-bold uppercase font-title md:text-xl">
                        {name}
                      </span>
                    )}
                </div>

                <List dense={true}>
                  <ListItem>
                    <ListItemIcon>
                      <Place fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText className="flex flex-col">
                      <Link
                        passHref
                        href={{
                          pathname: '/esperienze/[city]',
                          query: {
                            city: cleanedCityName,
                          },
                        }}
                      >
                        <a
                          className={classNames(
                            'tracking-wider dark:text-primary',
                            'font-title text-lg font-medium',
                            'underline-offset-4 hover:underline',
                          )}
                        >
                          {city}
                        </a>
                      </Link>
                      <span className="flex text-sm">
                        {address}
                      </span>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CalendarMonth fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      <span className="font-semibold">
                        {dayjs(item.date).format('LL')}
                      </span>
                    </ListItemText>
                  </ListItem>
                </List>

                {sortBy(products, 'slot').map(({ slot, slots }) => {
                  const isSingleProduct = products?.length === 1;

                  return (
                    <div
                      key={slot}
                      ref={(node) => {
                        // Workaround to set rules without ts error
                        if (node && isSingleProduct) {
                          node.style.cssText = `margin-top: -7px !important;`;
                        }
                      }}
                    >
                      <List
                        dense={true}
                        className={classNames({
                          'pt-0': isSingleProduct,
                        })}
                      >
                        <ListItem>
                          <ListItemIcon>
                            <AccessTime fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            <span className="font-semibold md:text-lg">
                              {slot.split(':')?.length > 2
                                ? dropRight(slot.split(':')).join(
                                    ':',
                                  )
                                : slot}
                            </span>
                          </ListItemText>
                        </ListItem>
                      </List>

                      <List dense={true} className="types-list">
                        {slots.map(({ type, types }) => {
                          const getLastItem =
                            types[types.length - 1];

                          //console.log('types', type, types);

                          const getProductCards = types?.filter(
                            ({ product, id }) => product?.isCard,
                          );

                          const typesTotal = types.reduce(
                            (acc, { product: { price_sale: $ } }) =>
                              acc + $,
                            0,
                          );

                          const documentRequired =
                            getLastItem?.product?.reductions
                              ?.length;

                          return (
                            <div key={type}>
                              <ListItem
                                key={type}
                                className="relative p-1"
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => {
                                      setIdsToRemove(
                                        getLastItem?.id,
                                      );
                                      handleOpenDialog();
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </IconButton>
                                }
                              >
                                <ListItemIcon className="relative">
                                  <span className="font-semibold">
                                    {types?.length}
                                  </span>
                                  <span className="absolute right-5">
                                    X
                                  </span>
                                </ListItemIcon>

                                <ListItemText
                                  primary={
                                    <Tooltip
                                      title={
                                        documentRequired && (
                                          <span className="text-xs">
                                            {t(
                                              'pages.cart.alert_reductions',
                                            )}
                                          </span>
                                        )
                                      }
                                    >
                                      <span className="font-medium dark:text-primary">
                                        {type}
                                        {documentRequired && (
                                          <span className="mx-2">
                                            *
                                          </span>
                                        )}
                                      </span>
                                    </Tooltip>
                                  }
                                  secondary={typesTotal + ' euro'}
                                />
                              </ListItem>

                              {user &&
                                user?.logged &&
                                getProductCards?.length > 0 && (
                                  <ul className="mb-6 space-y-4">
                                    {getProductCards?.map(
                                      (
                                        {
                                          product,
                                          holder_first_name,
                                          holder_last_name,
                                          hour_service,
                                          id,
                                        },
                                        index,
                                      ) => {
                                        return (
                                          <CardOwner
                                            key={index}
                                            id={id}
                                            setAllowPayment={
                                              setAllowPayment
                                            }
                                            index={index + 1}
                                            user={user}
                                            cart={cart}
                                            types={types}
                                            name={
                                              holder_first_name ||
                                              ''
                                            }
                                            surname={
                                              holder_last_name || ''
                                            }
                                          />
                                        );
                                      },
                                    )}
                                  </ul>
                                )}
                            </div>
                          );
                        })}
                      </List>
                    </div>
                  );
                })}

                <div
                  className={classNames(
                    'mt-4 h-16',
                    'text-xl font-bold',
                    'border-t-2 border-dotted',
                    'flex items-end justify-between',
                    'md:justify-end',
                  )}
                >
                  <Button
                    onClick={() => {
                      try {
                        setIdsToRemove(ids);
                        handleOpenDialog();
                      } catch (error) {
                        console.error(
                          'cartList.buttons.delete',
                          error,
                        );
                      }
                    }}
                    className="text-xs text-red-700 dark:text-red-600 md:hidden"
                  >
                    {t('buttons.delete')}
                  </Button>

                  <span>{subtotal} euro</span>
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
}
