import classNames from 'classnames';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { scroller } from 'react-scroll';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

export default function Collapsible({
  children,
  anchor = null,
  hide = false,
}) {
  const { isMobile } = useMediaQueries();
  const [expand, setExpand] = useState(hide);
  const { t } = useTranslation('common');

  const onClickBtn = () => {
    setExpand(!expand);

    if (anchor && !isMobile) {
      scroller?.scrollTo(anchor, {
        offset: -90,
      });
    }
  };

  return (
    <>
      <Collapse in={expand} collapsedSize={120}>
        <div className="space-y-5 md:space-y-10">{children}</div>
      </Collapse>

      {anchor && !hide && (
        <Button
          size="small"
          variant="contained"
          onClick={onClickBtn}
          className={classNames(
            'uppercase relative top-6',
            'btn-expand-paragraph',
            'flex items-center justify-center',
            'mx-auto w-full md:max-w-xs',
            'bg-primary text-white',
            {
              "open opacity-70": expand,
              close: !expand,
            },
          )}
        >
          {expand ? t('buttons.close') : t('buttons.read_more')}
        </Button>
      )}
    </>
  );
}
