import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'next-i18next';
import LanguageIcon from '@mui/icons-material/Language';

export default function BookingFoodDialog({
  open,
  title,
  setShowFoodDialog,
}) {
  const { t } = useTranslation('common');
  const onClose = () => setShowFoodDialog(false);

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="xs" open={open}>
      <DialogTitle className="text-base text-center text-gray-700 dark:text-white">
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p className="text-sm">{t('dialogs.food.text')}</p>

        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <EmailIcon className="fill-primary" />
            </ListItemIcon>
            <ListItemText
              secondary="Email"
              primary={
                <a
                  className="font-semibold underline underline-offset-2"
                  href="mailto:info@momentofood.it"
                >
                  info@momentofood.it
                </a>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LanguageIcon className="fill-primary" />
            </ListItemIcon>
            <ListItemText
              secondary="Website"
              primary={
                <a
                  className="font-semibold underline underline-offset-2"
                  target="_blank"
                  href="https://momentofood.it/"
                >
                  Momentofood.it
                </a>
              }
            />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}
