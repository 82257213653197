import Button from '@mui/material/Button';
import classNames from 'classnames';
import rules from './intro.data';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import SchoolIcon from '@mui/icons-material/School';
import { useRouter } from 'next/router';

export default function IntroQuiz({ onStart }) {

  const { locale } = useRouter();

  return (
    <div className="flex flex-col space-y-14">
      <ul className="ml-4 space-y-4 list-inside xl:space-y-8">
        {rules?.map(({ rule, emoji, label, gradient, icon }, i) => {
          return (
            <li
              key={i}
              className={classNames(
                'group',
                'flex items-center',
                'text-gray-600 dark:text-white',
                'space-x-6',
                'tracking-wider',
              )}
            >
              <span
                className={classNames(
                  'flex items-center justify-center',
                  'w-14 h-14 rounded-sm',
                  'bg-slate-100 dark:bg-zinc-800',
                  'relative overflow-hidden',
                )}
              >
                {icon && [
                  icon === 'question' && (
                    <QuestionMarkIcon
                      fontSize="medium"
                      key={icon}
                    />
                  ),
                  icon === 'time' && (
                    <AvTimerIcon fontSize="medium" key={icon} />
                  ),
                  icon === 'culture' && (
                    <SchoolIcon fontSize="medium" key={icon} />
                  ),
                ]}
              </span>
              <span className="flex flex-col max-w-sm italic">
                <h3 className="text-xl font-extrabold xl:text-2xl">
                  {rule[locale]}
                </h3>
                <span className="text-sm">{label[locale]}</span>
              </span>
            </li>
          );
        })}
      </ul>

      <Button
        fullWidth
        className="w-full max-w-xs mx-auto text-white rounded-sm bg-primary"
        onClick={onStart}
      >
        Start
      </Button>
    </div>
  );
}
