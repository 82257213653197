import { createSelector, createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {},
  //extraReducers: {},
});

export const {} = authSlice.actions;

export const userSelector = createSelector(
  ({ auth }) => auth,
  (auth) => auth,
);

export default authSlice.reducer;
