import { SiteGallery } from "@components";
import { useTranslation } from "next-i18next";

export default function EmptyCartMessage() {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-col items-center justify-center my-24 space-y-5">
      <h2 className="max-w-xs font-extrabold text-center uppercase text-md font-title md:text-xl">{t("pages.cart.empty")}</h2>

      <div className="w-full">
        <SiteGallery title={null} description={null} noPadding={true} label={t("components.gallery.see_also")} />
      </div>
    </div>
  );
}
