import { orange } from "@mui/material/colors";
import commonThemeValues from "./commons";

const black = "rgb(24 24 27)";

const MUI_DARK_THEME = {
  ...commonThemeValues,
  palette: {
    mode: "dark",
    primary: {
      main: "#faaf40",
      light: "#fab753",
      dark: "#e19d39",
      constrastText: "rgba(0, 0, 0, 0.87)",
    },

    secondary: {
      main: "#f9640e",
      contrastText: "#fbf9f9",
    },

    action: {
      selected: "#faaf40",
    },

    background: {
      default: black,
      paper: black,
    },
    common: { black, white: "#fff" },
  },
};

export default MUI_DARK_THEME;
