import { createSelector, createSlice } from "@reduxjs/toolkit";

import groupBy from "lodash/groupBy";

const museumsSlice = createSlice({
  name: "museums",
  initialState: {
    list: [],
    open: false,
    focus: null,
    drawerQuickBuyOpen: false,
  },
  reducers: {
    setDrawerMuseumStatusReducer(state, { payload }) {
      state.open = payload.open || false;
    },
    setMuseumsListReducer(state, { payload }) {
      state.list = [...payload.list];
    },
    setMuseumOnFocusReducer(state, { payload }) {
      state.focus = payload.focus || null;
    },
    onPrevMuseumReducer(state, { payload }) {
      const { list, focus } = state;
      const newItemOnFocus = list[focus?.id - 1 >= 0 ? focus?.id - 1 : list.length - 1];
      state.focus = newItemOnFocus;
    },
    onNextMuseumReducer(state) {
      const { list, focus } = state;
      const newItemOnFocus = state.list[focus.id + 1 < list.length ? focus?.id + 1 : 0];
      state.focus = newItemOnFocus;
    },
    suggestMuseums(state) {
      const { list, focus } = state;
      if (focus?.id) list.filter((m) => m?.id !== focus?.id);
    },

    setDrawerQuickBuyStatus(state, { payload }) {
      state.drawerQuickBuyOpen = payload.open || false;
    },
  },
});

export const {
  setMuseumsListReducer,
  setMuseumOnFocusReducer,
  setDrawerMuseumStatusReducer,
  onPrevMuseumReducer,
  onNextMuseumReducer,
  setDrawerQuickBuyStatus,
} = museumsSlice.actions;

export const museumsSelector = createSelector(
  ({ museums }) => museums,
  (museums) => museums,
);

export const suggestMuseums = createSelector(
  ({ museums }) => museums,
  ({ list, focus }) => (focus?.id !== null ? list.filter((m) => m?.id !== focus?.id) : [...list]),
);

export default museumsSlice.reducer;
