import Skeleton from "@mui/material/Skeleton";
import { useState } from "react";

import Image from "next/image";

export default function Brick({ src, alt, height }) {
  const [loader, setLoader] = useState(true);
  const onImageLoaded = () => setLoader(false);

  const className = "absolute top-0 bottom-0 left-0 right-0 object-cover w-full h-full rounded aspect-square " + height;

  return (
    <>
      {loader && <Skeleton animation="wave" variant="rectangular" className={className} />}

      <Image
        layout="fill"
        threshold={0}
        src={src}
        alt={alt}
        effect="opacity"
        onLoadingComplete={onImageLoaded}
        className={className}
      />
    </>
  );
}
