import dayjs from 'dayjs';
import classNames from 'classnames';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from 'next/link';

export default function Header({ className, link, expand, show }) {
  return (
    <div
      className={classNames(
        className,
        'w-full',
        'flex justify-between',
        'h-12 z-40',
        'px-4 md:px-6 xl:px-8',
        {
          hidden: expand,
        },
      )}
    >
      <span
        className={classNames(
          'text-xs',
          'opacity-95',
          'flex flex-col text-white',
        )}
      >
        {show?.name && (
          <Link
            passHref
            href={{
              pathname: '/esperienze/[city]/mostre-eventi/[slug]',
              query: {
                city: show?.city?.data?.attributes?.slug,
                slug: show?.slug,
              },
            }}
          >
            <a
              className={classNames(
                'flex text-current italic font-semibold',
                'text-base lg:text-lg leading-0 ',
                'transition-all duration-100 ease-in-out',
                'hover:underline underline-offset-2',
              )}
            >
              {show?.name}
            </a>
          </Link>
        )}
        <date className="text-sm font-light">
          {dayjs(show?.from).format('YYYY')}
        </date>
      </span>

      {link && (
        <a href={link} target="_blank">
          <OpenInNewIcon
            fontSize="small"
            className={classNames('text-white')}
          />
        </a>
      )}
    </div>
  );
}
