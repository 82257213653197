import { Header, Section, ArticleCard } from '@components';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import take from 'lodash/take';
import { useTranslation } from 'next-i18next';
import orderBy from 'lodash/orderBy';
import { useMediaQueries } from '@hooks';
import { useGetArticleCategoriesQuery } from '@services';
import trim from 'lodash/trim';
import Link from 'next/link';

export default function BlogPreview({
  articles,
  showTags = false,
}) {
  const { push, locale } = useRouter();
  const { t } = useTranslation('common');
  const { isMobile, lg } = useMediaQueries();
  const { data: categoriesData, isSuccess } =
    useGetArticleCategoriesQuery(
      { locale },
      { skip: isMobile || !showTags },
    );

  const orderedArticles = orderBy(
    articles,
    'attributes.publishedAt',
    ['desc'],
  );

  const getTrimmedTagName = (name) =>
    `#${trim(name).replace(/ /g, '')}`;

  const renderArticleCards = () => {
    const displayCount = isMobile ? 1 : lg ? 2 : 3;
    return take(orderedArticles, displayCount).map(
      ({ attributes }, i) => (
        <ArticleCard
          key={attributes.id || i}
          article={attributes}
          className="w-full h-100"
        />
      ),
    );
  };

  const renderTags = () =>
    categoriesData.map(({ attributes }) => {
      const tagName = getTrimmedTagName(attributes?.name);
      return (
        <Link
          key={attributes.slug}
          href={`/blog/tag/${attributes.slug}`}
          passHref
        >
          <span
            className={classNames(
              'm-1.5 px-2 text-lg opacity-70',
              'cursor-pointer hover:opacity-100',
              'transition-all duration-500 ease-in-out',
              'underline-offset-2 hover:underline',
            )}
          >
            {tagName}
          </span>
        </Link>
      );
    });

  if (!articles?.length) return null;

  return (
    <Section id="blog-preview">
      <Header
        title={t('sections.blog.title')}
        label={t('sections.blog.label')}
        onClick={() => push('/blog')}
      />

      <div
        className={classNames(
          'grid gap-3 select-none',
          'lg:grid-cols-2 xl:grid-cols-3',
        )}
      >
        {renderArticleCards()}
      </div>

      {isSuccess && categoriesData?.length > 0 && (
        <div className="flex justify-center pt-8">
          {renderTags()}
        </div>
      )}
    </Section>
  );
}
