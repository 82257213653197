import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { useGetStrapiCitiesQuery } from '@services';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

export default function SearchLocation({ className }) {
  const { locale, push } = useRouter();
  const [location, setLocation] = useState(null);
  const { t } = useTranslation('common');

  const {
    data: cities,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetStrapiCitiesQuery({ locale });

  const onHandleClick = () => {
    location && push(`/esperienze/${location}`);
  };

  return (
    <Autocomplete
      loading={isLoading || isFetching}
      loadingText="Loading..."
      freeSolo
      onChange={(value, currentValue) => {
        if (currentValue?.slug) setLocation(currentValue?.slug);
      }}
      options={
        isSuccess &&
        sortBy(cities, 'attributes.region')?.map(
          ({ attributes }) => ({
            label: attributes?.name,
            region: attributes?.region,
            slug: attributes?.slug,
          }),
        )
      }
      groupBy={(option) => option.region}
      fullWidth
      className="w-full max-w-lg"
      size="large"
      onClick={onHandleClick}
      onInputChange={(value, currentValue) => {
        setLocation(null);
      }}
      renderInput={(params) => (
        <Paper
          onSubmit={(e) => {
            e.preventDefault();
          }}
          component="form"
          ref={params.InputProps.ref}
          className="flex w-96 items-center px-0.5 py-1"
        >
          <InputBase
            ref={params.InputProps.ref}
            sx={{ ml: 1, flex: 1 }}
            inputProps={{
              'aria-label': 'search google maps',
            }}
            type="text"
            fullWidth
            className="w-full text-xl"
            {...params.inputProps}
            placeholder={t('generic.labels.where_you_go')}
          />
          <IconButton
            focused
            disabled={location === null}
            onClick={onHandleClick}
            type="submit"
            sx={{ p: '10px' }}
            aria-label="search"
          >
            <SearchIcon
              className={classNames({
                'fill-primary': location !== null,
              })}
            />
          </IconButton>
        </Paper>
      )}
    />
  );
}
