import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import dynamic from "next/dynamic";
import { showLoginDialogReducer } from "@slices";
import { userSelector } from "@slices";
import { useMediaQueries } from '@hooks';
import { useTranslation } from "next-i18next";

const LoginAndRecoverForm = dynamic(() => import("../../Forms/LoginAndRecover/LoginAndRecoverForm"));

export default function LoginDialog() {
  // Redux
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const {isMobile} = useMediaQueries();
  // i18s
  const { t } = useTranslation("common");
  // Events
  const handleCloseDialog = () => dispatch(showLoginDialogReducer(false));

  return (
    <Dialog
      fullWidth={true}
      fullScreen={isMobile}
      className="rounded-none"
      onClose={handleCloseDialog}
      open={user?.showLoginDialog || false}
    >
      <DialogTitle className="text-center text-primary">{t("dialogs.login.title")}</DialogTitle>
      <DialogContent>
        <LoginAndRecoverForm onSubmitLogin={handleCloseDialog} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>{t("dialogs.login.actions.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
