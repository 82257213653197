import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import truncate from 'lodash/truncate';
import ScienceIcon from '@mui/icons-material/Science';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SchoolIcon from '@mui/icons-material/School';
import { useMediaQueries } from '@hooks';
import classNames from 'classnames';


export default function SchoolActivitiesTypes({ types, grades }) {
  const { isLargeScreen, isMobile, isDesktop } = useMediaQueries();
  const isLaboratory = ['laboratorio', 'laboratory'];
  const isVisit = ['visita-tematica', 'tematic-visit'];
  const iconClasses = 'text-gray-700 dark:text-white';
  const labelClasses = 'hidden md:flex text-xs capitalize flex xl:hidden';

  if (types?.data?.length > 0 || grades?.data?.length > 0) {
    return (
      <div className="absolute flex space-x-2 divide-x-2 bottom-5">
        <Stack
          direction="row"
          className="items-center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={0.5}
        >
          {types?.data?.map(({ attributes: a }, i) => {
            const toCheck = a?.slug?.toLowerCase().trim();

            if (isLaboratory?.includes(toCheck)) {
              return (
                <Tooltip
                  title={a?.name.toUpperCase()}
                  key={i}
                  className="flex items-center space-x-2"
                >
                  {/* <ScienceIcon
                    className={iconClasses}
                    fontSize="small"
                  /> */}
                  <span className={labelClasses}>
                    {truncate(a?.name, {
                      length: 15,
                      separator: /,.:? +/,
                    })}
                  </span>
                </Tooltip>
              );
            }
            if (isVisit?.includes(toCheck)) {
              return (
                <Tooltip
                  title={a?.name.toUpperCase()}
                  key={i}
                  className="flex items-center space-x-2"
                >
                  <HistoryEduIcon
                    className={iconClasses}
                    fontSize="small"
                  />
                  <span className={labelClasses}>
                    {truncate(a?.name, {
                      length: 15,
                      separator: /,.:? +/,
                    })}
                  </span>
                </Tooltip>
              );
            }
          })}

          {isLargeScreen &&
            grades?.data?.map(({ attributes }, i) => {
              return (
                <div
                  className="items-center hidden space-x-2 text-xs 3xl:flex"
                  key={i}
                >
                  {/* <span>
                    <SchoolIcon
                      fontSize="small"
                      className={classNames(
                        iconClasses,
                        'hidden 3xl:flex',
                      )}
                    />
                  </span> */}
                  <span className="opacity-80">
                    {attributes?.name}
                  </span>
                </div>
              );
            })}
        </Stack>
      </div>
    );
  }

  return null;
}
