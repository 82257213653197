import Link from 'next/link';
import classNames from 'classnames';
import { getCleanedPaths } from '@utils';
import Abstract from './Sections/Abstract/Abstract';
import CompactCardContainer from './Sections/Container/CompactCardContainer';
import SchoolActivitiesTypes from './Sections/SchoolActivitiesTypes/SchoolActivitiesTypes';
import { useStrapiGetImgFormats, useMediaQueries } from '@hooks';
import CityLink from './Sections/Links/City';
import ProductLink from './Sections/Links/Product';
import truncate from 'lodash/truncate';

export default function SchoolCompactCard({ product, loading }) {
  const { slug, city, hero, abstract, types, site, grades } =
    product;

  const { isMobile } = useMediaQueries();

  const { small } = useStrapiGetImgFormats({
    img: hero?.media?.data?.attributes,
  });

  const { name } = site?.data?.attributes || {};

  const { cityPath, prodPath: sitePath } = getCleanedPaths({
    city: city?.data?.attributes?.slug,
    slug: site?.data?.attributes?.slug,
    typeSegment: '/musei-parchi-archeologici/',
  });


  return (
    <CompactCardContainer
      img={small?.url}
      loading={loading}
      type={types?.data[0]?.attributes?.name}
      prodPath={{
        pathname: '/didattica/[city]/[slug]',
        query: {
          city: product?.city?.data?.attributes?.slug,
          slug,
        },
      }}
    >
      <CityLink
        href={cityPath}
        name={product?.city?.data?.attributes?.name}
      />
      <div className="flex flex-col space-y-1.5">
        <ProductLink
          name={truncate(hero?.title, {
            length: isMobile ? 40 : 70,
            separator: /,.:? +/,
          })}
          href={{
            pathname: '/didattica/[city]/[slug]',
            query: {
              city: product?.city?.data?.attributes?.slug,
              slug,
            },
          }}
        />

        {site && !isMobile && (
          <Link href={sitePath} passHref>
            <a
              className={classNames(
                'text-xs font-light',
                'underline-offset-4 hover:underline',
                'z-50 cursor-pointer md:text-base font-medium',
              )}
            >
              {truncate(name, {
                length: isMobile ? 30 : 70,
                separator: /,.:? +/,
              })}
            </a>
          </Link>
        )}
      </div>
      <Abstract text={abstract} />
      <SchoolActivitiesTypes grades={grades} />
    </CompactCardContainer>
  );
}
