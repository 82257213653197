import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { userSelector } from '@slices';

export default function Actions({
  activeStep,
  submit,
  handleBack,
  onResetStep,
  onSkipCrossSelling,
  onBackToLastStep,
  onClickCart,
  onSaveCart,
  error,
  cart,
  isTourSuccess,
  tour,
  skipCrossSelling,
  isAddToCartLoading,
  type = null,
  tickets = null,
  isTicketsSuccess = null,
}) {
  const { logged } = useSelector(userSelector);
  const { t } = useTranslation('common');

  const hasToursToShow = isTourSuccess && tour?.length > 0;
  const isCartEmpty = cart?.length === 0;
  const hasNoStep = isNil(activeStep);
  const showSuggestProducts = !skipCrossSelling;

  if (activeStep > 0 && !submit) {
    return (
      <>
        <Button
          key="back"
          onClick={handleBack}
          className={classNames(
            'bg-zinc-100 dark:bg-zinc-800',
            'm-0 flex rounded-none p-0',
            'backdrop-blur-lg',
            {
              'w-full': activeStep < 2,
              'w-1/2': activeStep >= 2,
            },
          )}
        >
          {t('buttons.back')}
        </Button>

        <Button
          key="forward"
          onClick={onResetStep}
          disabled={isCartEmpty}
          className={classNames(
            'bg-primary text-white',
            'm-0 flex rounded-none p-0',
            {
              'disabled opacity-50': isCartEmpty,
              'w-1/2': activeStep === 2,
              hidden: activeStep !== 2,
            },
          )}
        >
          {t('buttons.next')}
        </Button>
      </>
    );
  }

  if (hasNoStep) {
    if (hasToursToShow && showSuggestProducts) {
      return (
        <Button
          key="skip"
          onClick={onSkipCrossSelling}
          disabled={!cart?.length}
          className={classNames(
            'bg-primary text-white',
            'm-0 flex w-full rounded-none p-0',
          )}
        >
          {t('buttons.next')}
        </Button>
      );
    }

    if (
      type === 'service' &&
      isTicketsSuccess &&
      tickets?.length > 0 &&
      showSuggestProducts
    ) {
      return (
        <Button
          key="skip"
          onClick={onSkipCrossSelling}
          disabled={!cart?.length}
          className={classNames(
            'bg-primary text-white',
            'm-0 flex w-full rounded-none p-0',
          )}
        >
          {t('buttons.next')}
        </Button>
      );
    }

    if (
      type === 'site' &&
      isTourSuccess &&
      tour?.length > 0 &&
      showSuggestProducts
    ) {
      return (
        <Button
          key="skip"
          onClick={onSkipCrossSelling}
          disabled={!cart?.length}
          className={classNames(
            'bg-primary text-white',
            'm-0 flex w-full rounded-none p-0',
          )}
        >
          {t('buttons.next')}
        </Button>
      );
    }

    if (
      type === 'site' &&
      isTourSuccess &&
      skipCrossSelling &&
      !submit
    ) {
      return (
        <>
          <Button
            key="back"
            onClick={onBackToLastStep}
            className={classNames(
              'm-0 flex h-full w-1/2 rounded-none p-0',
              'bg-zinc-100 text-primary dark:bg-zinc-800',
            )}
          >
            Reset
          </Button>
          <Button
            key="add"
            onClick={onSaveCart}
            disabled={logged && isAddToCartLoading}
            className={classNames(
              'bg-primary text-white',
              'm-0 flex h-full w-1/2 rounded-none p-0',
            )}
          >
            {logged && isAddToCartLoading ? (
              <CircularProgress
                size={20}
                thickness={4}
                className="text-white"
              />
            ) : (
              t('buttons.add_to_cart')
            )}
          </Button>
        </>
      );
    }

    if (
      type === 'service' &&
      isTicketsSuccess &&
      skipCrossSelling &&
      !submit
    ) {
      return (
        <>
          <Button
            key="back"
            onClick={onBackToLastStep}
            className={classNames(
              'm-0 flex h-full w-1/2 rounded-none p-0',
              'bg-zinc-100 text-primary dark:bg-zinc-800',
            )}
          >
            Reset
          </Button>
          <Button
            key="add"
            onClick={onSaveCart}
            disabled={logged && isAddToCartLoading}
            className={classNames(
              'bg-primary text-white',
              'm-0 flex h-full w-1/2 rounded-none p-0',
            )}
          >
            {logged && isAddToCartLoading ? (
              <CircularProgress
                size={20}
                thickness={4}
                className="text-white"
              />
            ) : (
              t('buttons.add_to_cart')
            )}
          </Button>
        </>
      );
    }

    if (submit && !error) {
      return (
        <Button
          key="go-cart"
          onClick={onClickCart}
          className={classNames(
            'bg-primary text-white',
            'z-50 m-0 flex h-full w-full rounded-none p-0',
          )}
        >
          {t('buttons.go_cart')}
        </Button>
      );
    }
  }

  return null;
}
