// import Button from '@mui/material/Button';
//import { useTranslation } from 'next-i18next';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Link from 'next/link';
import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import { useMediaQueries, useStrapiGetImgFormats } from '@hooks';


export default function RelatedProductsCard({
  address,
  abstract = null,
  cityName,
  cityLink,
  prodName,
  prodLink,
  img,
  type = null,
  types,
}) {
  const { isMobile } = useMediaQueries();
  const { small } = useStrapiGetImgFormats({ img });

  return (
    <div
      className={classNames(
        type,
        'w-full',
        'group related-products-card',
        'flex justify-between',
        'text-gray-700 dark:text-white',
      )}
    >
      <div
        className={classNames(
          'w-full',
          'flex flex-col md:flex-row',
          'space-y-4 md:space-y-0',
          'md:space-x-10',
        )}
      >
        <LazyLoadImage
          alt="img"
          src={small?.url}
          threshold={0}
          effect="opacity"
          className={classNames(
            'object-cover object-center',
            'md:w-32',
            'w-full h-32 aspect-square',
            'rounded-md saturate-75',
          )}
        />

        <div className="flex flex-col">
          <div className="flex flex-col">
            {cityName && cityLink && (
              <Link passHref href={cityLink}>
                <a
                  className={classNames(
                    'flex',
                    'text-sm md:text-base',
                    'text-secondary dark:text-primary',
                    'underline-offset-2 hover:underline',
                  )}
                >
                  {capitalize(cityName)}
                </a>
              </Link>
            )}

            {types?.data?.map(({ attributes }, y) => (
              <span className="italic capitalize" key={y}>
                {attributes?.name}
              </span>
            ))}

            <div className="mb-1.5">
              {prodLink && prodName && (
                <Link passHref href={prodLink?.toLowerCase()}>
                  <a
                    className={classNames(
                      'text-base md:text-lg font-semibold',
                      'underline-offset-4 hover:underline',
                    )}
                  >
                    {prodName}
                  </a>
                </Link>
              )}

              {!prodLink && prodName && (
                <span className="font-semibold md:text-xl">
                  {prodName}
                </span>
              )}

              {/* {address && (
                <span className="flex flex-row text-sm">
                  {address}
                </span>
              )} */}

              {!isMobile && abstract && (
                <p
                  className={classNames(
                    'flex max-w-md',
                    'text-sm md:text-base',
                  )}
                >
                  {truncate(abstract, {
                    length: 160,
                    separator: /,.:;? +/,
                  })}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
