export default [
  {
    name: 'MNEMOSINE ',
    job: { it: 'Divinità', en: 'Divinity' },
    range: { min: 0, max: 25 },
    img: 'quiz/mnemosine.jpg',
    emoji: '😵‍💫',
    quote: {
      it: 'Le cose da ricordare sono tante, perchè allora non chiedere aiuto alla dea della memoria stessa? Ti aiuterà a memorizzare tutto in un baleno!',
      en: '',
    },
  },
  {
    name: 'SOCRATE',
    job: { it: 'Filosofo', en: 'Philosopher' },
    range: { min: 26, max: 50 },
    img: 'quiz/socrate.jpg',
    emoji: '🤔',
    quote: {
      it: 'Sai di non sapere? Allora sei saggio come Socrate! E come per lui questa consapevolezza ti aiuta ad aumentare il desiderio di conoscenza. Continua a leggere il blog, la prossima volta le indovinerai tutte!',
      en: '',
    },
  },
  {
    name: 'ARCHIMEDE',
    job: {
      it: 'Matematico, fisico e inventore',
      en: 'Mathematician, physicist and inventor',
    },
    range: { min: 51, max: 75 },
    img: 'quiz/archimede.jpg',
    emoji: '👌',
    quote: {
      it: 'Eureka! Probabilmente quello che hai pensato mentre rispondevi alle domande, perché le sapevi quasi tutte!',
      en: '',
    },
  },
  {
    name: 'ATENA',
    job: 'Dea',
    job: { it: 'Dea', en: 'Goddess' },
    range: { min: 75, max: 100 },
    img: 'quiz/atena.jpg',
    emoji: '👏',
    quote: {
      it: 'Non c’è che dire, il tuo livello di conoscenza è insuperabile! Siamo certi che la Dea della saggezza non sia la tua patrona?',
      en: '',
    },
  },
];
