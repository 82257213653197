import classNames from 'classnames';

export default function Headline({ title, subtitle, abstract }) {
  return (
    <section
      className={classNames(
        'space-y-2 font-title',
        'text-gray-700 dark:text-white',
      )}
    >
      <header>
        {title && (
          <h2
            className={classNames(
              'uppercase md:text-4xl',
              'text-xl font-extrabold',
            )}
          >
            {title}
          </h2>
        )}

        {subtitle && (
          <h3 className={classNames('md:text-2xl', 'text-xl')}>
            {subtitle}
          </h3>
        )}
      </header>

      {abstract && (
        <p
          className={classNames(
            'pt-4 opacity-80',
            'text-base md:text-xl italic',
          )}
        >
          {abstract}
        </p>
      )}
    </section>
  );
}
