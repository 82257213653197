import classNames from 'classnames';
import shuffle from 'lodash/shuffle';
import useSound from 'use-sound';

export default function QuestionStep({
  question,
  options,
  onNext,
}) {
  const [play, { stop }] = useSound('audio/pop.wav');

  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center',
        'relative select-none lg:space-y-12',
        'h-full w-full max-w-2xl',
      )}
    >
      <h2 className="pb-8 space-x-2 text-lg font-bold lg:pb-0 lg:text-3xl">
        <span>Cosa s'intende per:</span>
        <span className="italic underline underline-offset-4 dark:text-primary xl:text-4xl">
          {question?.trim()}
        </span>
        <span>?</span>
      </h2>

      <div className="space-y-4">
        {shuffle(options)?.map(({ answer, id, correct }, i) => (
          <div
            key={i}
            className="flex items-center space-x-0 lg:space-x-4 group"
          >
            <span
              className={classNames(
                'w-5 shrink-0 font-title font-extrabold',
                'dark:group-hover:text-primary-500',
                'select-none transition-colors duration-300 ease-in-out',
              )}
            >
              {i + 1}
            </span>

            <button
              onClick={() => {
                stop();
                play();
                onNext({ id, correct });
              }}
              className={classNames(
                'inline-flex select-none',
                'w-full max-w-xl rounded-md text-start text-sm p-4 lg:text-lg',
                'transition-all duration-300 ease-in-out',
                'group-hover:bg-slate-200 dark:hover:bg-zinc-700',
              )}
            >
              {answer}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
