import classNames from 'classnames';
import Link from 'next/link';
import truncate from 'lodash/truncate';
import { useMediaQueries } from '@hooks';

export default function ProductLink({ href, name }) {
  const {  isMobile } = useMediaQueries();

  if (href && name?.length > 0) {
    return (
      <Link passHref href={href}>
        <a
          className={classNames(
            'md:text-base md:font-semibold',
            'text-gray-900 dark:text-white',
            'xl:max-w-xl 2xl:max-w-full cursor-pointer underline-offset-4',
            'hover:underline md:uppercase',
            'font-title text-sm',
          )}
        >
          {truncate(name, {
            length: isMobile ? 55 : 90,
            separator: /,.:? +/,
          })}
        </a>
      </Link>
    );
  }

  return null;
}
