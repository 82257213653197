import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';

export default function Includes({ includes }) {
  if (includes && includes?.list?.length > 0) {
    return (
      <div className="space-y-8">
        <span>{includes?.description}</span>
        <div className="space-y-6">
          {includes.list.map((l, i) => (
            <div className="flex flex-row space-x-4" key={i}>
              <div>
                <CheckIcon className="w-8 fill-green-600" />
              </div>
              <div>
                <span
                  className={classNames(
                    'text-sm font-semibold uppercase',
                    'font-title dark:text-white',
                  )}
                >
                  {l?.name}
                </span>
                {l?.text && (
                  <p className="max-w-xl text-sm lg:text-base">
                    {l.text}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
}
