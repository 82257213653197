import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { SearchLocation } from '@components';


export default function SearchButton() {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleClickOpen}>
          <SearchIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        maxWidth="sm"
        fullScreen={false}
        className="bg-transparent backdrop-blur-sm"
        aria-labelledby="responsive-dialog-title"
      >
        <SearchLocation />
      </Dialog>
    </>
  );
}
