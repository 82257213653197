import { LazyLoadImage } from 'react-lazy-load-image-component';
import Link from 'next/link';
import classNames from 'classnames';
import take from 'lodash/take';
import { useMediaQueries } from '@hooks';
import truncate from 'lodash/truncate';

export default function Articles({ articles }) {
  const { isMobile } = useMediaQueries();

  return take(articles, 3).map(({ attributes }, i) => {
    const { title, slug, img, abstract, article_categories } =
      attributes;

    const { formats, url } = img?.data?.attributes;
    const { small } = formats || {};

    return (
      <div
        key={i}
        className={classNames(
          'group',
          'flex justify-between',
          'w-full',
          'text-gray-700 dark:text-white',
        )}
      >
        <div
          className={classNames(
            'flex flex-col md:flex-row',
            'w-full',
            'space-y-4 md:space-x-10 md:space-y-0',
          )}
        >
          <LazyLoadImage
            alt="img"
            src={small?.url || url}
            threshold={0}
            effect="opacity"
            className={classNames(
              'object-cover object-center',
              'md:w-32',
              'w-full h-32 aspect-square',
              'rounded-md saturate-75',
            )}
          />

          <div className="flex flex-col">
            <div className="flex flex-col">
              <Link
                passHref
                href={
                  '/blog/tag/' +
                  article_categories?.data[0]?.attributes?.slug
                }
                className="space-y-1"
              >
                <a
                  className={classNames(
                    'flex',
                    'text-sm md:text-base text-secondary dark:text-primary',
                    'underline-offset-2 hover:underline',
                  )}
                >
                  {article_categories?.data[0]?.attributes?.name}
                </a>
              </Link>

              <div className="mb-4">
                <Link passHref href={'/blog/' + slug}>
                  <h2
                    className={classNames(
                      'text-gray-700',
                      'cursor-pointer underline-offset-4',
                      'hover:underline dark:text-white text-base md:text-lg',
                      'font-semibold underline-offset-4 hover:underline',
                    )}
                  >
                    {title}
                  </h2>
                </Link>

                {!isMobile && (
                  <p className="flex max-w-md text-sm md:text-base">
                    {truncate(abstract, {
                      length: 120,
                      separator: /,.:? +/,
                    })}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
}
