import dayjs from "dayjs";

export const shapeCartServerSide = ({ cart, date, hour }) => {
  try {
    if (cart?.length > 0 && date && hour) {
      return cart
        .map((c) => {
          if (c.qty > 1)
            return new Array(c.qty).fill({
              product_id: c.id,
              hour_service: c?.hour_service || hour,
              date_service: dayjs(date).format("YYYY-MM-DD"),
            });

          return {
            product_id: c.id,
            date_service: dayjs(date).format("YYYY-MM-DD"),
            hour_service: c?.hour_service || hour,
          };
        })
        .flat();
    }

    return [];
  } catch (error) {
    console.error("createCartForServer", error);
  }
};

export const shapeCartClientSide = ({ cart, date, hour, product }) => {
  try {
    if (cart?.length > 0 && date && hour) {
      return cart
        .map((c) => {
          if (c.qty > 1)
            return new Array(c.qty).fill({
              // New
              hour_service: c?.hour_service || hour,
              date_service: dayjs(date).format("YYYY-MM-DD"),
              product: {
                id: c.id,
                name: c.product_name,
                price_sale: c.product_price,
                document_required: c.document_required,
                site: product?.site,
                category: product?.category,
              },
            });

          return {
            // New
            hour_service: c?.hour_service || hour,
            date_service: dayjs(date).format("YYYY-MM-DD"),
            product: {
              id: c.id,
              name: c.product_name,
              price_sale: c.product_price,
              category: product?.category,
              document_required: c.document_required,
              site: product?.site,
            },
          };
        })
        .flat();
    }
    return [];
  } catch (error) {
    console.error("createCartForClient", error);
  }
};
