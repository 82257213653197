import { currentThemeSelector, setThemeReducer } from '@slices';
import { useDispatch, useSelector } from 'react-redux';
import useSound from 'use-sound';
import IconButton from '@mui/material/IconButton';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { useTranslation } from 'next-i18next';
import Tooltip from '@mui/material/Tooltip';

export default function ToggleThemeButton() {
  const dispatch = useDispatch();
  const theme = useSelector(currentThemeSelector);
  const isDark = theme === 'dark';
  const [play, { stop }] = useSound('audio/switch.wav');

  const setDarkTheme = () => {
    play();
    dispatch(setThemeReducer('dark'));
  };
  const setLightTheme = () => {
    play();
    dispatch(setThemeReducer('light'));
  };

  // i18s
  const { t } = useTranslation('common');

  return (
    <>
      <Tooltip
        title={
          isDark ? t('navbar.light_theme') : t('navbar.dark_theme')
        }
      >
        {isDark ? (
          <IconButton
            color="inherit"
            onClick={setLightTheme}
          >
            <LightbulbOutlinedIcon
              fontSize="small"
              className="fill-white"
            />
          </IconButton>
        ) : (
          <IconButton
            color="inherit"
  
            onClick={setDarkTheme}
          >
            <LightbulbIcon
              fontSize="small"
              className="fill-gray-700"
            />
          </IconButton>
        )}
      </Tooltip>
    </>
  );
}
