import { Header, Section, DestinationCard } from '@components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Link from "next/link";
import sortBy from 'lodash/sortBy';
import { useGetStrapiCitiesQuery } from '@services';
import { useRouter } from 'next/router';
import { useMediaQueries } from '@hooks';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

export default function Destinations({
  query = '',
  title = '',
  label = '',
  dense = false,
  className
}) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const { isMobile } = useMediaQueries();
  const { data, isSuccess, isLoading, isFetching } =
    useGetStrapiCitiesQuery({
      query,
      heroMedia: 'populate[2]=hero.media',
      locale,
    });

  return (
    <Section
      id="Destinations"
      isLoading={isLoading}
      dense={dense}
      className={className}
    >
      <Header
        title={title}
        label={label}
        description={null}
        isLoading={isLoading || isFetching}
      />

      {isMobile && isSuccess && data?.length > 0 && (
        <div className="relative flex justify-end w-full px-2 mx-auto mb-4 lg:w-11/12">
          <Link passHref href="/destinations">
            <a
              className={classNames(
                'cursor-pointer',
                'underline-offset-2 hover:underline md:right-10',
                'text-xs uppercase md:text-sm',
                'font-condensed font-medium',
                'text-gray-700 dark:text-white',
              )}
            >
              {t('components.gallery.see_all')}
            </a>
          </Link>
        </div>
      )}

      {isSuccess && data?.length > 0 && (
        <Splide
          className="relative w-full mx-auto"
          options={{
            gap: '0.7rem',
            type: 'slide',
            width: '100vw',
            autoplay: isMobile,
            pagination: true,
            lazyLoad: 'nearby',
            arrows: false,
            padding: isMobile ? 0 : 40,
            drag: isMobile ? 'slide' : 'free',

            perPage: isMobile ? 1 : 3,
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
            trimSpace: 'move',
            focus: 'center',
          }}
        >
          {sortBy(data, 'attributes.name').map(
            ({ attributes }, i) => {
              const { name, hero, slug } = attributes;
              const heroMedia = hero?.media?.data.attributes;

              return (
                <SplideSlide key={i}>
                  <DestinationCard
                    key={i}
                    wrapped={false}
                    name={name}
                    slug={slug}
                    img={heroMedia}
                  />
                </SplideSlide>
              );
            },
          )}
        </Splide>
      )}

      {!isMobile && isSuccess && data?.length > 0 && (
        <div className="relative w-full mx-auto lg:w-11/12">
          <Link passHref href="/destinations">
            <a
              className={classNames(
                'cursor pointer',
                'absolute bottom-0 right-0 top-3 md:right-10',
                'text-gray-700 dark:text-white',
                'underline-offset-2 hover:underline',
                'font-condensed font-medium',
                'text-xs uppercase md:text-sm',
              )}
            >
              {t('components.gallery.see_all')}
            </a>
          </Link>
        </div>
      )}
    </Section>
  );
}
