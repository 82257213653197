import classNames from 'classnames';

export default function Tag({
  tag,
  className,
  onClick = null,
}) {
  if (tag) {
    return (
      <div
        onClick={onClick}
        className={classNames(
          className,
          'z-20 px-5 py-1',
          'font-thin text-white',
          'absolute -right-2 -top-0',
          'text-xs',
          'rounded-bl-md backdrop-blur-xl',
          'bg-primary/95 dark:bg-primary-400/95',
          'text-center font-condensed uppercase tracking-widest',
          'group-hover:bg-primary-600',
          'transition-colors ease-in-out duration-500',
          {
            'cursor-pointer underline-offset-2 hover:underline':
              onClick !== null,
          },
        )}
      >
        {tag}
      </div>
    );
  }

  return null;
}
