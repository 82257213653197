import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import remarkGfm from 'remark-gfm';

export default function Intro({ content }) {
  if (content && content?.length > 0) {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        className={classNames(
          'py-4',
          'strapi-editorial',
          'text-gray-700 dark:text-white',
          'text-base md:text-xl',
        )}
      >
        {content}
      </ReactMarkdown>
    );
  }

  return null;
}
