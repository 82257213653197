import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useTranslation } from "next-i18next";

export default function AssistanceDialog({ open, setShowDialog }) {
  const onClose = () => setShowDialog(false);

  // i18s
  const { t } = useTranslation("common");

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="xs" open={open}>
      <DialogTitle className="text-center">
        {t('dialogs.assistance.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p className="text-sm">{t('dialogs.assistance.text')}</p>
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <LocalPhoneIcon className="fill-primary" />
            </ListItemIcon>
            <ListItemText
              secondary="Email"
              primary={
                <a
                  className="font-semibold underline underline-offset-2"
                  href="mailto:info@aditusculture.com"
                >
                  info@aditusculture.com
                </a>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon className="fill-primary" />
            </ListItemIcon>
            <ListItemText
              secondary={t('dialogs.assistance.phone')}
              primary={
                <a
                  className="font-semibold underline underline-offset-2"
                  href="tel:01119272866"
                >
                  01119272866
                </a>
              }
            />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}
