import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { parseFormData } from "@utils";

const baseUrl = process.env.NEXT_PUBLIC_ADITUS;

export const SchoolService = createApi({
  reducerPath: "School",
  tagTypes: ["School"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;

      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
      return headers;
    },
  }),
  onQueryStarted: () => {},
  endpoints: (builder) => ({
    // STORE NEW SCHOOL
    registerSchool: builder.mutation({
      invalidatesTags: ["School"],
      query: (data) => {
        if (data) {
          return {
            url: baseUrl + "/users/school",
            method: "POST",
            body: parseFormData(data),
          };
        }
      },
      transformResponse: (res) => res,
    }),
    // GET SCHOOL CODE
    getSchoolCode: builder.query({
      query: ({ code = "CSAA850069" }) => `schools/validate-code?code=${code}`,
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const { useRegisterSchoolMutation, useGetSchoolCodeQuery } = SchoolService;

export default SchoolService;
