import {
  Section,
  Header,
  EmptyGallery,
  ProductCard,
} from '@components';

import classNames from 'classnames';


export default function ProductsShowCase({
  label,
  title,
  description = null,
  products = [],
  type,
  segment,
  baseSegment,
}) {
  const gridProducts = classNames('grid lg:grid-cols-3 gap-2');

  if (products?.length > 0) {
    return (
      <Section>
        <Header
          label={label}
          title={title}
          description={description}
        />

        {products?.length > 3 ? (
          <EmptyGallery items={products} type={type} />
        ) : (
          <div className={gridProducts}>
            {products.map((p, i) => (
              <ProductCard
                key={i}
                segment={segment}
                product={p}
                baseSegment={baseSegment}
              />
            ))}
          </div>
        )}
      </Section>
    );
  }

  return null;
}
