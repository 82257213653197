import {
  AgencyService,
  AuthService,
  CartService,
  CountriesService,
  GenericService,
  OrdersService,
  PaymentService,
  SchoolService,
  ServicesService,
  SitesService,
  StrapiService,
  UserService,
  WeatherService,
  NewsletterService,
} from '@services';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import { autoRehydrate, useDispatch, useSelector } from "react-redux";

import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "./rootReducers";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "./storage";

//import storage from 'redux-persist/lib/storage';
// import storage from 'redux-persist/lib/storage/createWebStorage';

/* import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync'; */

const persistConfig = {
  storage,
  level: 3,
  key: "root",
  // timeout: 1000,
  whitelist: ["user", "theme", "cart", "sites"],
  blacklist: [
    "navigation",
    "museums",
    "user.drawerMenuOpen",
    "cart.showCountDown",
    /*  PERSIST,
    REHYDRATE, */
  ],
};

export const store = configureStore({
  devTools: true,
  reducer: persistReducer(persistConfig, rootReducers),
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PURGE,
          REGISTER,
          PERSIST,
        ],
      },
    }).concat([
      // createStateSyncMiddleware({ blacklist: [PERSIST, PURGE] }),
      SitesService.middleware,
      StrapiService.middleware,
      AuthService.middleware,
      UserService.middleware,
      CartService.middleware,
      PaymentService.middleware,
      ServicesService.middleware,
      GenericService.middleware,
      SchoolService.middleware,
      AgencyService.middleware,
      CountriesService.middleware,
      OrdersService.middleware,
      WeatherService.middleware,
      NewsletterService.middleware,
    ]);

    return middleware;
  },
});

setupListeners(store.dispatch);
// initMessageListener(store);

export const persistor = persistStore(store);
